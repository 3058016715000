import {
    Box,
    Button,
    Collapse,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import dayjs from "dayjs";
import { StyledTableCell } from "../../../Theme";
import { tofieds } from "../../../untils/shortcut";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Financial_OutstandingTable({
    headersso,
    headerinData,
    data,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    status,
    onCheck,
    onCancel,
}) {
    // console.log('data:', data)
    const [openRows, setOpenRows] = useState({});

    const toggleRowOpen = (rowIndex) => {
        setOpenRows((prev) => ({
            ...prev,
            [rowIndex]: !prev[rowIndex],
        }));
    };

    return (
        <>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {(headersso || []).map((column) => (
                                    <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        colSpan={column?.colSpan}
                                    >
                                        {column.label}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length > 0 ? (
                                data.map((item, index) => {
                                    const allEntries = [...item.debit, ...item.credit];
                                    const amountDebit = item.debit.reduce((acc, cur) => acc + cur.debit, null);
                                    // console.log('amountDebit:', amountDebit)
                                    const amountCredit = item.credit.reduce((acc, cur) => acc + cur.credit, null);
                                    // console.log('amountCredit:', amountCredit)

                                    const disableConfirmButton = amountDebit === null || amountCredit === null;

                                    return (
                                        <React.Fragment key={index}>
                                            {allEntries.length > 0 ? (
                                                allEntries.map((row, rowIndex) => (
                                                    <>
                                                        <TableRow key={rowIndex}>
                                                            {rowIndex === 0 && status === "5" && (
                                                                <StyledTableCell
                                                                    rowSpan={
                                                                        item.debit.length !== 0 && item.credit.length !== 0
                                                                            ? item.debit.length + item.credit.length
                                                                            : item.debit.length + item.credit.length + 1
                                                                    }
                                                                >
                                                                    <IconButton
                                                                        aria-label="expand row"
                                                                        size="small"
                                                                        onClick={() => toggleRowOpen(index)}
                                                                    >
                                                                        {openRows[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                    </IconButton>
                                                                </StyledTableCell>
                                                            )}

                                                            {rowIndex === 0 && (
                                                                <StyledTableCell
                                                                    align="center"
                                                                    rowSpan={
                                                                        item.debit.length !== 0 && item.credit.length !== 0
                                                                            ? item.debit.length + item.credit.length
                                                                            : item.debit.length + item.credit.length + 1
                                                                    }
                                                                >
                                                                    {row.ref_no || "ไม่มีรายละเอียด"}
                                                                </StyledTableCell>
                                                            )}
                                                            {rowIndex === 0 && (
                                                                <StyledTableCell
                                                                    align="center"
                                                                    rowSpan={
                                                                        item.debit.length !== 0 && item.credit.length !== 0
                                                                            ? item.debit.length + item.credit.length
                                                                            : item.debit.length + item.credit.length + 1
                                                                    }
                                                                    sx={{ borderRight: "1px solid #e0e0e0" }}
                                                                >
                                                                    {row.finance_date
                                                                        ? dayjs(row.finance_date).format("DD MMM YYYY")
                                                                        : "ไม่มีรายละเอียด"}
                                                                </StyledTableCell>
                                                            )}

                                                            <StyledTableCell align="left" colSpan={2}>
                                                                {row.account_name || "ไม่มีรายละเอียด"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left" sx={{ borderRight: "1px solid #e0e0e0" }}>
                                                                {row.ref_doc === "" ? "" : row.ref_doc}
                                                            </StyledTableCell>

                                                            <StyledTableCell align="right">{tofieds(row.debit) || "ไม่มีรายละเอียด"}</StyledTableCell>
                                                            <StyledTableCell align="right" sx={{ borderRight: "1px solid #e0e0e0" }}>
                                                                {tofieds(row.credit) || "ไม่มีรายละเอียด"}
                                                            </StyledTableCell>
                                                            {rowIndex === 0 && (
                                                                <StyledTableCell
                                                                    align="center"
                                                                    rowSpan={
                                                                        item.debit.length !== 0 && item.credit.length !== 0
                                                                            ? item.debit.length + item.credit.length
                                                                            : item.debit.length + item.credit.length + 1
                                                                    }
                                                                    sx={{ borderRight: "1px solid #e0e0e0" }}
                                                                >
                                                                    {row.detail || "ไม่มีรายละเอียด"}
                                                                </StyledTableCell>
                                                            )}

                                                            {rowIndex === 0 && status == 5 && (
                                                                <StyledTableCell
                                                                    align="center"
                                                                    rowSpan={
                                                                        item.debit.length !== 0 && item.credit.length !== 0
                                                                            ? item.debit.length + item.credit.length
                                                                            : item.debit.length + item.credit.length + 1
                                                                    }
                                                                >
                                                                    <Box display="flex" flexDirection="column" justifyContent="center" gap={1}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="success"
                                                                            onClick={() => onCheck(item, amountDebit, amountCredit)}
                                                                            disabled={disableConfirmButton}
                                                                        >
                                                                            เพิ่มข้อมูล
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="error"
                                                                            onClick={() => onCancel(item.alldata[0].credit, item.debit, item.credit)}
                                                                        >
                                                                            ยกเลิก
                                                                        </Button>
                                                                    </Box>
                                                                </StyledTableCell>
                                                            )}
                                                        </TableRow>

                                                        {rowIndex === 1 && item.debit.length !== 0 && item.credit.length !== 0 && (
                                                            <TableRow>
                                                                <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                                                    {(item.alldata || []).map((itemin) => (
                                                                        <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                                                                            <Box sx={{ margin: 1 }}>
                                                                                <Typography variant="h6" gutterBottom component="div">
                                                                                    ข้อมูลเพิ่มเติม:{" "}
                                                                                    <span style={{ fontSize: "14px" }}>
                                                                                        {itemin.credit.documentName ||
                                                                                            itemin.debit.documentName ||
                                                                                            ""}
                                                                                    </span>
                                                                                </Typography>
                                                                                <Table size="small" aria-label="details">
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            {(headerinData || []).map((column, i) => (
                                                                                                <StyledTableCell key={i}>
                                                                                                    <Typography fontWeight="bold" fontSize="14px">
                                                                                                        {column.label}
                                                                                                    </Typography>
                                                                                                </StyledTableCell>
                                                                                            ))}
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {[itemin.debit, itemin.credit].length > 0 ? (
                                                                                            [itemin.debit, itemin.credit].map((deduct, id) => (
                                                                                                <TableRow key={id}>
                                                                                                    {(headerinData || []).map((column) => (
                                                                                                        <StyledTableCell key={column.id}>
                                                                                                            {column.id === "debit" ||
                                                                                                            column.id === "credit"
                                                                                                                ? tofieds(deduct[column.id] || 0)
                                                                                                                : deduct[column.id] || ""}
                                                                                                        </StyledTableCell>
                                                                                                    ))}
                                                                                                </TableRow>
                                                                                            ))
                                                                                        ) : (
                                                                                            <TableRow>
                                                                                                <StyledTableCell colSpan={12} align="center">
                                                                                                    ไม่พบข้อมูล
                                                                                                </StyledTableCell>
                                                                                            </TableRow>
                                                                                        )}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </Box>
                                                                        </Collapse>
                                                                    ))}
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell align="center" colSpan={12}>
                                                        ไม่มีรายละเอียด
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={12} align="center">
                                        ไม่พบข้อมูล
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>
        </>
    );
}

export default Financial_OutstandingTable;
