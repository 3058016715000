import React from "react";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { StyledTableCell } from "../../../Theme";
import dayjs from "dayjs";
import { tofieds } from "../../../untils/shortcut";

function FinancialDeductionTable({
     headersso,
     status,
     sortedData,
     rowsPerPage,
     page,
     handleChangePage,
     handleChangeRowsPerPage,
     onAdd,
     onEdit,
     onCheck,
}) {
     const data = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

     return (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
               <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                         <TableHead>
                              <TableRow>
                                   {headersso.map((el, i) => (
                                        <StyledTableCell key={i + 1} align={el.align} style={{ minWidth: el.minWidth }}>
                                             {el.label}
                                        </StyledTableCell>
                                   ))}
                                   {/* {(headersso || []).map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    // colSpan={column?.colSpan}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))} */}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {data.length > 0 ? (
                                   data.map((item, index) => {
                                        const allEntries = [...item.debit, ...item.credit];
                                        const amountDebit = item.debit.reduce((acc, cur) => acc + cur.debit, null);
                                        // console.log('amountDebit:', amountDebit)
                                        const amountCredit = item.credit.reduce((acc, cur) => acc + cur.credit, null);
                                        // console.log('amountCredit:', amountCredit)

                                        const disableConfirmButton = amountDebit === null || amountCredit === null;
                                        const rowspan = status === "1" ? 2 : item.debit.length + item.credit.length;
                                        return (
                                             <React.Fragment key={index + 1}>
                                                  {/* {allEntries.length > 0 ? (
                                                       allEntries.map((row, rowIndex) => ( */}
                                                  <TableRow>
                                                       <StyledTableCell
                                                            // sx={status === "1" ? { borderRight: "1px solid #e0e0e0" } : {}}
                                                            align="center"
                                                            rowSpan={rowspan}
                                                       >
                                                            {allEntries[0].claim || "-"}
                                                       </StyledTableCell>
                                                       <StyledTableCell sx={{ borderRight: "1px solid #e0e0e0" }} align="center" rowSpan={rowspan}>
                                                            {allEntries[0].documentName || "-"}
                                                       </StyledTableCell>
                                                       {status === "2" && (
                                                            <StyledTableCell align="center" rowSpan={rowspan}>
                                                                 {allEntries[0].finance_date
                                                                      ? dayjs(allEntries[0].finance_date).format("DD MMM YYYY")
                                                                      : "ไม่มีรายละเอียด"}
                                                            </StyledTableCell>
                                                       )}
                                                       {status === "2" && (
                                                            <StyledTableCell
                                                                 sx={{ borderRight: "1px solid #e0e0e0" }}
                                                                 align="center"
                                                                 rowSpan={rowspan}
                                                            >
                                                                 {allEntries[0].ref_no || "ไม่มีรายละเอียด"}
                                                            </StyledTableCell>
                                                       )}
                                                       {/*============================================================================*/}
                                                       {/*============================================================================*/}
                                                       {item.debit[0] ? (
                                                            <>
                                                                 <StyledTableCell sx={{ borderRight: "1px solid #e0e0e0" }} align="left">
                                                                      {item.debit[0].account_no || "ไม่มีรายละเอียด"}
                                                                 </StyledTableCell>
                                                                 <StyledTableCell align="left">
                                                                      {item.debit[0].account_name || "ไม่มีรายละเอียด"}
                                                                 </StyledTableCell>
                                                                 <StyledTableCell align="left" sx={{ borderRight: "1px solid #e0e0e0" }}>
                                                                      {/* {item.debit[0].ref_doc === "" ? "" : item.debit[0].ref_doc} */}
                                                                 </StyledTableCell>
                                                                 <StyledTableCell align="right">
                                                                      {tofieds(item.debit[0].debit) || "ไม่มีรายละเอียด"}
                                                                 </StyledTableCell>
                                                                 <StyledTableCell align="right" sx={{ borderRight: "1px solid #e0e0e0" }}>
                                                                      {tofieds(item.debit[0].credit) || "ไม่มีรายละเอียด"}
                                                                 </StyledTableCell>
                                                            </>
                                                       ) : (
                                                            <TableCell sx={{ borderRight: "1px solid #e0e0e0" }} align="center" colSpan={5}>
                                                                 รอจับคู่{" "}
                                                                 <a
                                                                      onClick={() => onAdd(item)}
                                                                      style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                                                                 >
                                                                      เดบิตเงินฝาก
                                                                 </a>
                                                            </TableCell>
                                                       )}
                                                       {/*============================================================================*/}
                                                       {/*============================================================================*/}
                                                       <StyledTableCell align="center" rowSpan={rowspan} sx={{ borderRight: "1px solid #e0e0e0" }}>
                                                            {allEntries[0].detail || "ไม่มีรายละเอียด"}
                                                       </StyledTableCell>

                                                       {status == 1 && (
                                                            <StyledTableCell align="center" rowSpan={rowspan}>
                                                                 <Box display="flex" flexDirection="column" justifyContent="center" gap={1}>
                                                                      <Button
                                                                           variant="contained"
                                                                           color="success"
                                                                           onClick={() => onCheck(item, amountDebit, amountCredit)}
                                                                           disabled={disableConfirmButton}
                                                                      >
                                                                           ยืนยัน
                                                                      </Button>
                                                                 </Box>
                                                            </StyledTableCell>
                                                       )}
                                                  </TableRow>

                                                  {item.debit[1]
                                                       ? item.debit.slice(1, item.debit.length).map((el) => (
                                                              <TableRow>
                                                                   <StyledTableCell sx={{ borderRight: "1px solid #e0e0e0" }} align="left">
                                                                        {el.account_no || "ไม่มีรายละเอียด"}
                                                                   </StyledTableCell>
                                                                   <StyledTableCell align="left">
                                                                        {el.account_name || "ไม่มีรายละเอียด"}
                                                                   </StyledTableCell>
                                                                   <StyledTableCell align="left" sx={{ borderRight: "1px solid #e0e0e0" }}>
                                                                        {el.ref_doc === "" ? "" : el.ref_doc}
                                                                   </StyledTableCell>
                                                                   <StyledTableCell align="right">
                                                                        {tofieds(el.debit) || "ไม่มีรายละเอียด"}
                                                                   </StyledTableCell>
                                                                   <StyledTableCell align="right" sx={{ borderRight: "1px solid #e0e0e0" }}>
                                                                        {tofieds(el.credit) || "ไม่มีรายละเอียด"}
                                                                   </StyledTableCell>
                                                              </TableRow>
                                                         ))
                                                       : null}

                                                  {item.credit[0] ? (
                                                       item.credit.map((el) => (
                                                            <TableRow>
                                                                 <StyledTableCell sx={{ borderRight: "1px solid #e0e0e0" }} align="left">
                                                                      {el.account_no || "ไม่มีรายละเอียด"}
                                                                 </StyledTableCell>
                                                                 <StyledTableCell align="left">
                                                                      {el.account_name || "ไม่มีรายละเอียด"}
                                                                 </StyledTableCell>
                                                                 <StyledTableCell align="left" sx={{ borderRight: "1px solid #e0e0e0" }}>
                                                                      {el.ref_doc === "" ? "" : el.ref_doc}
                                                                 </StyledTableCell>
                                                                 <StyledTableCell align="right">
                                                                      {tofieds(el.debit) || "ไม่มีรายละเอียด"}
                                                                 </StyledTableCell>
                                                                 <StyledTableCell align="right" sx={{ borderRight: "1px solid #e0e0e0" }}>
                                                                      {tofieds(el.credit) || "ไม่มีรายละเอียด"}
                                                                 </StyledTableCell>
                                                            </TableRow>
                                                       ))
                                                  ) : (
                                                       <TableRow>
                                                            <TableCell sx={{ borderRight: "1px solid #e0e0e0" }} align="center" colSpan={5}>
                                                                 รอจับคู่ เครดิตลูกหนี้
                                                            </TableCell>
                                                       </TableRow>
                                                  )}

                                                  {/* ))
                                                  ) : (
                                                       <TableRow>
                                                            <TableCell align="center" colSpan={12}>
                                                                 ไม่มีรายละเอียด
                                                            </TableCell>
                                                       </TableRow>
                                                  )} */}

                                                  {/* {(item.debit.length === 0 || item.credit.length === 0) && (
                                                       <TableRow>
                                                            <TableCell sx={{ borderRight: "1px solid #e0e0e0" }} align="center" colSpan={5}>
                                                                 {item.debit.length === 0 && item.credit.length > 0
                                                                      ? "รอจับคู่เดบิตเงินฝาก"
                                                                      : "รอจับคู่เครดิตลูกหนี้"}
                                                            </TableCell>
                                                       </TableRow>
                                                  )} */}
                                             </React.Fragment>
                                        );
                                   })
                              ) : (
                                   <TableRow>
                                        <TableCell colSpan={12} align="center">
                                             ไม่พบข้อมูล
                                        </TableCell>
                                   </TableRow>
                              )}
                         </TableBody>
                    </Table>
                    <TablePagination
                         rowsPerPageOptions={[10, 25, 50, 100]}
                         component="div"
                         count={sortedData.length}
                         rowsPerPage={rowsPerPage}
                         page={page}
                         onPageChange={handleChangePage}
                         onRowsPerPageChange={handleChangeRowsPerPage}
                    />
               </TableContainer>
          </Paper>
     );
}

export default FinancialDeductionTable;
