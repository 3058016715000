import {
    Box,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React, { useMemo, useState } from "react";
import { StyledTableCell } from "../../../Theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function GridTable({
    dataNotPassClaim,
    header,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    lengthNotPass,
    getDataSub,
    setStatus,
    setDetail,
    dataSub,
}) {
    // console.log("dataSub:", dataSub);
    const [order, setOrder] = useState(0);
    const [orderBy, setOrderBy] = useState("count");
    const direction = ["desc", "asc"];

    const sortDataTemp = (a, b, direction) => {
        if (direction === "asc") {
            return a - b;
        }
        if (direction === "desc") {
            return b - a;
        }
        return 0;
    };

    const visibleRows = useMemo(() => {
        const sortResult = dataNotPassClaim.sort((a, b) => {
            return sortDataTemp(Number(a[orderBy]), Number(b[orderBy]), direction[order]);
        });

        return sortResult;
    }, [order, orderBy, dataNotPassClaim, page, rowsPerPage]);

    const handleRequestSort = (property) => {
        setOrderBy(property);
        setOrder((prev) => {
            if (prev + 1 > 1) {
                return 0;
            }
            return prev + 1;
        });
    };

    const [openRowIndex, setOpenRowIndex] = useState(null);

    const handleRowClick = (row) => {
        // console.log("Clicked row:", row.status_claim);

        if (openRowIndex === row) {
            setOpenRowIndex(null);
        } else {
            setOpenRowIndex(row);
            getDataSub();
        }

        setStatus(row.status_claim);
        setDetail(row.detail);
    };

    return (
        <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center" style={{ width: 1100 }}>
                                รายละเอียด
                            </StyledTableCell>
                            {header.map((headCell) => (
                                <StyledTableCell key={headCell.id} align={headCell.align} sortDirection={orderBy === headCell.id ? order : false}>
                                    <TableSortLabel
                                        style={{ color: "#fff" }}
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? direction[order] : "asc"}
                                        onClick={() => handleRequestSort(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {direction[order] === "desc" ? "sorted descending" : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleRows.map((row, index) => (
                            <React.Fragment key={index}>
                                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                                    <StyledTableCell align="left">{row.detail}</StyledTableCell>
                                    <StyledTableCell align="center">{row.count}</StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton aria-label="expand row" size="small" onClick={() => handleRowClick(row)}>
                                            {openRowIndex === row ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </StyledTableCell>
                                </TableRow>
                                {openRowIndex === row && (
                                    <TableBody>
                                        {dataSub.map((row, index) => (
                                            <React.Fragment key={index}>
                                                <TableRow>
                                                    <StyledTableCell align="left">{row.hcode}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.namepat}</StyledTableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {dataNotPassClaim.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    page={page}
                    count={lengthNotPass}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
