import { Button, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import React, { useState } from "react";
import { StyledTableCell } from "../../../Theme";

export default function FinancialAccountTbl({ header, dataAccount, handleClickOpenEdit }) {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {(header || []).map((column) => (
                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(dataAccount?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []).map((row, index) => (
                            <TableRow key={index}>
                                <StyledTableCell align="center">{row.claim || "-"}</StyledTableCell>
                                <StyledTableCell align="center">{row.ref_doc_credit || "-"}</StyledTableCell>
                                <StyledTableCell align="center">{row.ref_doc_debit || "-"}</StyledTableCell>
                                <StyledTableCell align="center">{row.account_number || "-"}</StyledTableCell>
                                <StyledTableCell align="center">{row.account_name || "-"}</StyledTableCell>
                                <StyledTableCell align="center">{row.account_no_credit || "-"}</StyledTableCell>
                                <StyledTableCell align="center">{row.account_no_debit || "-"}</StyledTableCell>
                                <StyledTableCell align="center">{row.account_name_credit || "-"}</StyledTableCell>
                                <StyledTableCell align="center">{row.account_name_debit || "-"}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button variant="text" onClick={() => handleClickOpenEdit(row)}>
                                        แก้ไข
                                    </Button>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={dataAccount.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            </TableContainer>
        </Paper>
    );
}
