import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import { ButtonTeal } from "../../../Theme";
import { CloseIcn } from "../../../untils/icons";

export default function DialogEdit({
    data,
    setData,
    open,
    handleClose,
    handleEdit,
}) {
    const [loading, setLoading] = useState(false);
    const formRef = useRef();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} className="bg-dialog">
            <Box className="bg-gradiant">
                <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={handleClose} />
                </DialogActions>
                <DialogTitle className="text-header">แก้ไข</DialogTitle>
                <DialogContent className="flex-center">
                    <form ref={formRef} className="flex-center">
                        <DialogContentText mt={2} className="container-bg" component={"span"}>
                            <Grid container columns={12} spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>เลือกสิทธิ</InputLabel>
                                        <Select name="claim" value={data.claim || ""} label="เลือกสิทธิ" required onChange={handleInputChange}>
                                            <MenuItem value="SSO">SSO</MenuItem>
                                            <MenuItem value="LGO">LGO</MenuItem>
                                            <MenuItem value="ECLAIM">ECLAIM</MenuItem>
                                            <MenuItem value="KTB">KTB</MenuItem>
                                            <MenuItem value="MOPH">MOPH</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="อ้างอิงเอกสารเครดิต"
                                        name="ref_doc_credit"
                                        variant="outlined"
                                        value={data.ref_doc_credit || ""}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="อ้างอิงเอกสารเดบิต"
                                        name="ref_doc_debit"
                                        variant="outlined"
                                        value={data.ref_doc_debit || ""}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="เลขบัญชี"
                                        name="account_number"
                                        variant="outlined"
                                        value={data.account_number || ""}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ชื่อบัญชี"
                                        name="account_name"
                                        variant="outlined"
                                        value={data.account_name || ""}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="เลขบัญชีเครดิต"
                                        name="account_no_credit"
                                        variant="outlined"
                                        value={data.account_no_credit || ""}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="เลขบัญชีเดบิต"
                                        name="account_no_debit"
                                        variant="outlined"
                                        value={data.account_no_debit || ""}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ชื่อบัญชีเครดิต"
                                        name="account_name_credit"
                                        variant="outlined"
                                        value={data.account_name_credit || ""}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ชื่อบัญชีเดบิต"
                                        name="account_name_debit"
                                        variant="outlined"
                                        value={data.account_name_debit || ""}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions className="flex-center">
                    <ButtonTeal disabled={loading} sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }} onClick={handleEdit}>
                        {loading ? "กำลังแก้ไข..." : "แก้ไขข้อมูล"}
                    </ButtonTeal>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
