import React, { useEffect, useState } from "react";

import { Container } from "@mui/material";

import dayjs from "dayjs";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { EXPORTEXCELK112_2, GETDATAK112_2, POST, POST_GIS, SELECTOR01, ip_gis } from "../../service";
import { getToken } from "../../untils/shortcut";
import K1122Card from "./K112.2/K1122Card";
import axios from "axios";

function K1122() {
    const profile = getToken(localStorage.getItem("profile"));
    const token = localStorage.getItem("uuid");
    const year_length = new Date().getFullYear() - 2024;
    const yearsData = Array.from({ length: year_length + 2 }, (_, index) => {
        return { value: 2024 + index };
    });
    const [data, setData] = useState([]);
    const [dataByQ, setDataByQ] = useState([]);
    const [headerByQ, setHeaderByQ] = useState({});
    const [total, setTotal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(dayjs().year());
    const [location, setLocation] = useState("");
    const [dataAmphur, setDataAmphur] = useState([]);
    const [dataTambon, setDataTambon] = useState([]);
    const [dataLocation, setDataLocation] = useState([]);
    const [searchData, setSearchData] = useState({ amphur: "", tambon: "", hcode: "", year: yearsData[0].value });

    const onSelect = async (name, value) => {
        let searchDataTemp = { ...searchData };
        if (name === "amphur") {
            searchDataTemp = { ...searchDataTemp, [name]: value, tambon: "" };
        } else if (name === "tambon") {
            searchDataTemp = { ...searchDataTemp, [name]: value };
        } else if (name === "year_quarter") {
            searchDataTemp = { ...searchDataTemp, [name]: value, amphur: "", tambon: "", hname: "" };
        } else {
            searchDataTemp = { ...searchDataTemp, [name]: value };
        }
        getDataSelect({ ...searchDataTemp });
        setSearchData((Prev) => ({ ...Prev, ...searchDataTemp }));
    };

    const getK112_2 = async (data = {}) => {
        try {
            setLoading(true);
            let res = await POST_GIS(GETDATAK112_2, {
                amphurname: data.amphur !== "ทั้งหมด" ? data.amphur : "",
                tambonname: data.tambon !== "ทั้งหมด" ? data.tambon : "",
                hcode: data.hcode !== "ทั้งหมด" ? data.hcode : "",
                year_quarter: data.year ?? year,
            });
            if (res.success) {
                setData(res.result.graph);
                setDataByQ(res.result.data);
                setHeaderByQ(res.result.header);
                setTotal(res.result.totalTable);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getDataSelect = async (data = {}) => {
        try {
            let res = await POST(SELECTOR01, {
                amphurname: data.amphur ?? "",
                tambonname: data.tambon ?? "",
                // hcode: data.hcode ?? "",
                // year_quarter: data.year ?? year,
            });
            if (res.success) {
                let obj = res.result;
                // console.log("obj:", obj);
                setDataAmphur(obj.amphur);
                setDataTambon(obj.tambon);
                setDataLocation(obj.hospital);
                // if (profile.role_id !== 1 && profile.role_id !== 2) {
                //     let dataTemp = { amphur: "", tambon: "", hospital: "" };
                //     if (obj.amphur) {
                //         dataTemp.amphur = obj.amphur[0];
                //     }
                //     if (obj.tambon) {
                //         dataTemp.tambon = obj.tambon[0];
                //     }
                //     if (obj.hospital) {
                //         setDataLocation(obj.hospital);
                //         dataTemp.hospital = obj.hospital[0];
                //     }
                //     setSearchData((Prev) => ({ ...Prev, ...dataTemp }));
                //     getK112_2({ ...data, ...dataTemp });
                // } else {
                getK112_2(data);
                // }
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const genExcel = async () => {
        setLoading(true);
        try {
            const res = await axios.post(
                ip_gis + EXPORTEXCELK112_2,
                {
                    amphurname: searchData.amphur !== "ทั้งหมด" ? searchData.amphur : "",
                    tambonname: searchData.tambon !== "ทั้งหมด" ? searchData.tambon : "",
                    hcode: searchData.hcode !== "ทั้งหมด" ? searchData.hcode : "",
                    year_quarter: searchData.year ?? year,
                },
                {
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token || "token",
                    },
                }
            );

            const excelBlob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const fileURL = URL.createObjectURL(excelBlob);
            const a = document.createElement("a");
            a.href = fileURL;
            a.download = "K112_2.xlsx";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(fileURL);
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error.response ? error.response.data : error.message}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDataSelect(searchData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <K1122Card
                profile={profile}
                data={data}
                dataByQ={dataByQ}
                headerByQ={headerByQ}
                total={total}
                yearsData={yearsData}
                year={searchData.year}
                amphur={searchData.amphur}
                tambon={searchData.tambon}
                hname={searchData.hname}
                dataAmphur={dataAmphur}
                dataTambon={dataTambon}
                dataLocation={dataLocation}
                searchData={searchData}
                setYear={setYear}
                setLocation={setLocation}
                onSearch={onSelect}
                genExcel={genExcel}
            />
        </Container>
    );
}

export default K1122;
