import React, { useEffect, useState } from "react";
import FinancialDeductionlist from "./component/Financial_DeductionList";
import { EDITAR, GET, GETAR, GETDATAAR, INFOAR, INSERTAR, POST, SUCCESSAR } from "../../service";
import Swal from "sweetalert2";

function Financial_Deduction() {
     // const [data, setData] = useState([
     //     {
     //         debit: [
     //             {
     //                 ref_no: "RV-6701xxx",
     //                 documentName: "eclaim_1", account_name_credit: "ขายขวดน้ำ", finance_date: "2024-01-16", account_name: "bank01", debit: 120, credit: 0, ref_doc: "", detail: "รอตรวจสอบ"
     //             }
     //         ],
     //         credit: [
     //             {
     //                 ref_no: "RV-6701xxx",
     //                 documentName: "eclaim_1",
     //                 finance_date: "2024-01-16", account_name_credit: "บัญชีเงินฝาก", account_name: "ลูกหนี้01", debit: 0, credit: 120, ref_doc: "AR-6701001", detail: "รอตรวจสอบ"
     //             }
     //         ]
     //     },
     //     {
     //         debit: [
     //             {
     //                 ref_no: "RV-6702xxx",
     //                 documentName: "eclaim_1", account_name_credit: "ขายขวดน้ำ", finance_date: "2024-01-16", account_name: "bank02", debit: 100, credit: 0, ref_doc: "", detail: "รอตรวจสอบ"
     //             }
     //         ],
     //         credit: [
     //             {
     //                 ref_no: "RV-6702xxx",
     //                 documentName: "eclaim_1",
     //                 finance_date: "2024-01-16", account_name_credit: "บัญชีเงินฝาก", account_name: "ลูกหนี้02", debit: 0, credit: 120, ref_doc: "AR-6701002", detail: "รอตรวจสอบ"
     //             }
     //         ]
     //     },
     //     {
     //         debit: [
     //             {
     //                 ref_no: "RV-6703xxx",
     //                 documentName: "eclaim_1", account_name_credit: "ขายขวดน้ำ", finance_date: "2024-01-16", account_name: "bank03", debit: 200, credit: 0, ref_doc: "", detail: "รอตรวจสอบ"
     //             },
     //             {
     //                 ref_no: "RV-6703xxx",
     //                 documentName: "eclaim_1", account_name_credit: "ขายขวดน้ำ", finance_date: "2024-01-16", account_name: "bank03", debit: 200, credit: 0, ref_doc: "", detail: "รอตรวจสอบ"
     //             }
     //         ],
     //         credit: [
     //             {
     //                 ref_no: "RV-6703xxx",
     //                 documentName: "eclaim_1",
     //                 finance_date: "2024-01-16", account_name_credit: "บัญชีเงินฝาก", account_name: "ลูกหนี้03", debit: 0, credit: 120, ref_doc: "AR-6701003", detail: "รอตรวจสอบ"
     //             },
     //             {
     //                 ref_no: "RV-6703xxx",
     //                 documentName: "eclaim_1",
     //                 finance_date: "2024-01-16", account_name_credit: "บัญชีเงินฝาก", account_name: "ลูกหนี้03", debit: 0, credit: 120, ref_doc: "AR-6701003", detail: "รอตรวจสอบ"
     //             }
     //         ]
     //     },
     //     {
     //         debit: [
     //             {
     //                 ref_no: "RV-6704xxx",
     //                 documentName: "eclaim_1", account_name_credit: "ขายขวดน้ำ", finance_date: "2024-01-16", account_name: "bank04", debit: 200, credit: 0, ref_doc: "", detail: "รอตรวจสอบ"
     //             }
     //         ],
     //         credit: [
     //         ]
     //     },
     //     {
     //         debit: [
     //         ],
     //         credit: [
     //             {
     //                 ref_no: "RV-6705xxx",
     //                 documentName: "eclaim_1",
     //                 finance_date: "2024-01-16", account_name_credit: "บัญชีเงินฝาก", account_name: "ลูกหนี้05", debit: 0, credit: 120, ref_doc: "AR-6701003", detail: "รอตรวจสอบ"
     //             },
     //             {
     //                 ref_no: "RV-6705xxx",
     //                 documentName: "eclaim_1",
     //                 finance_date: "2024-01-16", account_name_credit: "บัญชีเงินฝาก", account_name: "ลูกหนี้05", debit: 0, credit: 120, ref_doc: "AR-6701003", detail: "รอตรวจสอบ"
     //             },
     //             {
     //                 ref_no: "RV-6705xxx",
     //                 documentName: "eclaim_1",
     //                 finance_date: "2024-01-16", account_name_credit: "บัญชีเงินฝาก", account_name: "ลูกหนี้05", debit: 0, credit: 120, ref_doc: "AR-6701003", detail: "รอตรวจสอบ"
     //             }
     //         ]
     //     },
     // ])
     const [data, setData] = useState([]);
     const [loading, setLoading] = useState(false);
     const [status, setStatus] = useState("1");
     const [page, setPage] = useState(0);
     const [search, setSearch] = useState("");
     const [rowsPerPage, setRowsPerPage] = useState(10);
     const [openDialogAdd, setOpenDialogAdd] = useState(false);
     const [openDialogEdit, setOpenDialogEdit] = useState(false);
     const [newDataAdd, setNewDataAdd] = useState({});
     const [dataAdd, setDataAdd] = useState({});
     const [newDataEdit, setNewDataEdit] = useState([]);

     const getData = async () => {
          try {
               setLoading(true);
               const payload = {
                    ref_doc: search,
                    account_no: search,
                    account_name: search,
                    status: status,
               };

               let res;

               if (status === "1") {
                    res = await POST(GETDATAAR, payload);
               } else if (status === "2") {
                    res = await POST(GETAR, payload);
               }

               if (res && res.success) {
                    let responseData = res.data;

                    const combinedData = [];
                    const creditOnlyData = [];
                    const debitOnlyData = [];

                    responseData.forEach((item) => {
                         const DataCredit = item.credit && item.credit.length > 0;
                         const DataDebit = item.debit && item.debit.length > 0;

                         if (DataCredit && DataDebit) {
                              combinedData.push(item);
                         } else if (DataCredit) {
                              creditOnlyData.push(item);
                         } else if (DataDebit) {
                              debitOnlyData.push(item);
                         }
                    });

                    const filteredData = [...combinedData, ...creditOnlyData, ...debitOnlyData];
                    setData(filteredData);
                    setPage(0);
               } else {
                    Swal.fire({ icon: "warning", text: `${res.message}`, showConfirmButton: true });
               }
          } catch (error) {
               Swal.fire({ icon: "warning", text: `${error}`, confirmButtonText: "ตกลง" });
          } finally {
               setLoading(false);
          }
     };

     const handleSaveAdd = async () => {
          try {
               if (newDataAdd.credit === newDataAdd.debit) {
                    let status_id = 4;
                    const payload = {
                         debit: [
                              {
                                   ...dataAdd.credit[0],
                                   account_no: null,
                                   account_name: null,
                                   account_name_credit: null,
                                   credit: 0,
                                   debit: newDataAdd.debit,
                              },
                         ],
                         credit: dataAdd.credit,
                         status_id: status_id,
                         type: "add",
                    };
                    // console.log('payload:', payload)
                    // let res
                    const res = await POST(SUCCESSAR, payload);

                    if (res && res.success) {
                         setOpenDialogAdd(false);
                         Swal.fire({
                              icon: "success",
                              text: "ส่งข้อมูลสำเร็จ",
                              showConfirmButton: true,
                         });
                         getData();
                         setPage(0);
                    } else {
                         setOpenDialogAdd(false);
                         Swal.fire({
                              icon: "warning",
                              text: res.message || "เกิดข้อผิดพลาด",
                              showConfirmButton: true,
                         });
                    }
               } else if (newDataAdd.debit > newDataAdd.credit) {
                    setOpenDialogAdd(false);
                    Swal.fire({
                         title: "Debit มากกว่า Credit",
                         text: "กรณีได้รับเงินมากกว่าลูกหนี้ที่ตั้งไว้",
                         icon: "info",
                         confirmButtonText: "ตกลง",
                         showCancelButton: true,
                         cancelButtonText: "ยกเลิก",
                    }).then(async (result) => {
                         if (result.isConfirmed) {
                              let status_id = 4;
                              const credit = Math.abs(newDataAdd.debit - newDataAdd.credit);
                              //   console.log("first", dataAdd);
                              const datadebit = [
                                   {
                                        ...dataAdd.credit[0],
                                        account_no: null,
                                        account_name: null,
                                        account_name_credit: null,
                                        credit: 0,
                                        debit: newDataAdd.debit,
                                   },
                              ];
                              const dataedit = {
                                   account_no: dataAdd.credit[0].account_no,
                                   account_name: null,
                                   account_name_credit: null,
                                   claim: dataAdd.credit[0].claim,
                                   ref_doc: dataAdd.credit[0].ref_doc,
                                   ref_no: dataAdd.credit[0].ref_no,
                                   documentName: dataAdd.credit[0].documentName,
                                   file_name: dataAdd.credit[0].file_name,
                                   finance_date: dataAdd.credit[0].finance_date,
                                   hcode: dataAdd.credit[0].hcode,
                                   credit,
                                   debit: 0,
                              };

                              const payload = {
                                   debit: datadebit,
                                   credit: [dataAdd.credit[0], dataedit],
                                   status_id: status_id,
                                   type: "add_over",
                              };
                              //   console.log("payload:", payload);
                              const res = await POST(SUCCESSAR, payload);
                              if (res && res.success) {
                                   Swal.fire({
                                        icon: "success",
                                        text: "ส่งข้อมูลสำเร็จ",
                                        showConfirmButton: true,
                                   });
                                   getData();
                                   setPage(0);
                              } else {
                                   Swal.fire({
                                        icon: "warning",
                                        text: res.message || "เกิดข้อผิดพลาด",
                                        showConfirmButton: true,
                                   });
                              }
                         }
                    });
               } else if (newDataAdd.debit < newDataAdd.credit) {
                    setOpenDialogAdd(false);
                    Swal.fire({
                         title: "Debit น้อยกว่า Credit",
                         text: "คุณคาดว่าจะได้เงินหรือไม่?",
                         icon: "info",
                         cancelButtonText: "ยกเลิก",
                         confirmButtonText: "คาดว่าจะได้รับเงิน",
                         denyButtonText: "คาดว่าจะไม่ได้รับเงิน",
                         showCancelButton: true,
                         showDenyButton: true,
                    }).then(async (result) => {
                         if (result.isConfirmed) {
                              alert("กำลังพัฒนา");
                              //   // กรณีคิดว่าจะได้เงิน
                              //   let status_id = 5;
                              //   const abs1 = Math.abs(amountDebit - amountCredit);
                              //   const credit = Math.abs(amountCredit - abs1);
                              //   // console.log('credit:', credit)

                              //   const dataedit = {
                              //        account_name_credit: item.credit[0].account_name_credit,
                              //        ref_doc: item.credit[0].ref_doc,
                              //        documentName: item.debit[0].documentName,
                              //        account_name: item.credit[0].account_name_credit,
                              //        credit,
                              //   };
                              //   const payload = {
                              //        debit: dataDebits,
                              //        credit: [...dataCredits, dataedit],
                              //        status_id: status_id,
                              //   };
                              //   // console.log('payload:', payload)
                              //   // let res
                              //   const res = await POST(INFOAR, payload);

                              //   if (res && res.success) {
                              //        Swal.fire({
                              //             icon: "success",
                              //             text: "ส่งข้อมูลสำเร็จ",
                              //             showConfirmButton: true,
                              //        });
                              //        getData();
                              //        setPage(0);
                              //   } else {
                              //        Swal.fire({
                              //             icon: "warning",
                              //             text: res.message || "เกิดข้อผิดพลาด",
                              //             showConfirmButton: true,
                              //        });
                              //   }
                         } else if (result.isDenied) {
                              // กรณีคิดว่าไม่ได้เงิน
                              Swal.fire({
                                   icon: "info",
                                   text: "ข้อมูลนี้ยืนยันว่าจะไม่ได้รับเงิน",
                                   confirmButtonText: "ตกลง",
                                   cancelButtonText: "ยกเลิก",
                                   showConfirmButton: true,
                                   showCancelButton: true,
                              }).then(async (result) => {
                                   if (result.isConfirmed) {
                                        let status_id = 6;
                                        const debit = Math.abs(newDataAdd.credit - newDataAdd.debit);
                                        const datadebit = [
                                             {
                                                  ...dataAdd.credit[0],
                                                  account_no: null,
                                                  account_name: null,
                                                  account_name_credit: null,
                                                  credit: 0,
                                                  debit: newDataAdd.debit,
                                             },
                                        ];
                                        const dataedit = {
                                             account_no: dataAdd.credit[0].account_no,
                                             account_name: null,
                                             account_name_debit: null,
                                             claim: dataAdd.credit[0].claim,
                                             ref_doc: dataAdd.credit[0].ref_doc,
                                             ref_no: dataAdd.credit[0].ref_no,
                                             documentName: dataAdd.credit[0].documentName,
                                             file_name: dataAdd.credit[0].file_name,
                                             finance_date: dataAdd.credit[0].finance_date,
                                             hcode: dataAdd.credit[0].hcode,
                                             credit: 0,
                                             debit,
                                        };

                                        const payload = {
                                             debit: [datadebit[0], dataedit],
                                             credit: [dataAdd.credit[0]],
                                             status_id: status_id,
                                             type: "add_under",
                                        };
                                        // console.log("payload:", payload);
                                        const res = await POST(SUCCESSAR, payload);
                                        if (res && res.success) {
                                             Swal.fire({
                                                  icon: "success",
                                                  text: "ส่งข้อมูลสำเร็จ",
                                                  showConfirmButton: true,
                                             });
                                             getData();
                                             setPage(0);
                                        } else {
                                             Swal.fire({
                                                  icon: "warning",
                                                  text: res.message || "เกิดข้อผิดพลาด",
                                                  showConfirmButton: true,
                                             });
                                        }
                                   }
                              });
                         }
                    });
               } else {
                    alert("กำลังพัฒนา");
                    setOpenDialogAdd(false);
               }
          } catch (error) {}
          //   try {
          //        const updatedRow = [newDataAdd];

          //        const payload = {
          //             // status: status,
          //             data: updatedRow,
          //        };
          //        // console.log('payload:', payload)

          //        const res = await POST(INSERTAR, payload);

          //        if (res && res.success) {
          //             Swal.fire({ icon: "success", text: "เพิ่มข้อมูลสำเร็จ", showConfirmButton: true });

          //             getData();
          //             setPage(0);
          //        } else {
          //             Swal.fire({ icon: "warning", text: res.message || "เกิดข้อผิดพลาด", showConfirmButton: true });
          //        }
          //   } catch (error) {
          //        Swal.fire({ icon: "error", text: error.message || "เกิดข้อผิดพลาด", confirmButtonText: "ตกลง" });
          //   } finally {
          //        handleCloseDialogAdd();
          //   }
     };

     const handleSaveEdit = async () => {
          try {
               const updatedRow = [newDataEdit];

               const payload = {
                    // status: status,
                    data: updatedRow,
               };
               // console.log('payload:', payload)
               // let res
               const res = await POST(EDITAR, payload);

               if (res && res.success) {
                    Swal.fire({ icon: "success", text: "เพิ่มข้อมูลสำเร็จ", showConfirmButton: true });

                    getData();
                    setPage(0);
               } else {
                    Swal.fire({ icon: "warning", text: res.message || "เกิดข้อผิดพลาด", showConfirmButton: true });
               }
          } catch (error) {
               Swal.fire({ icon: "error", text: error.message || "เกิดข้อผิดพลาด", confirmButtonText: "ตกลง" });
          } finally {
               handleCloseDialogEdit();
          }
     };

     const handleCheck = async (item, amountDebit, amountCredit) => {
          const refdoc = item.credit[0]?.ref_doc;
          try {
               let dataDebits = [];
               item.debit?.map((debitrow) => {
                    const DataDebit = {
                         ref_no: debitrow?.ref_no,
                         ref_doc: refdoc,
                         finance_date: debitrow?.finance_date,
                         account_name: debitrow?.account_name,
                         credit: debitrow?.credit,
                         debit: debitrow?.debit,
                         detail: debitrow?.detail,
                         documentName: debitrow?.documentName,
                         hcode: debitrow?.hcode,
                         account_no: debitrow.account_no,
                    };
                    dataDebits.push(DataDebit);
               });

               let dataCredits = [];
               item.credit?.map((creditrow) => {
                    const DataCredit = {
                         account_name: creditrow?.account_name,
                         claim: creditrow?.claim,
                         credit: creditrow?.credit,
                         debit: creditrow?.debit,
                         ref_no: creditrow?.ref_no,
                         file_name: creditrow?.file_name,
                         ref_doc: refdoc,
                         finance_date: creditrow?.finance_date,
                         documentName: creditrow?.documentName,
                         hcode: creditrow?.hcode,
                         account_no: creditrow.account_no,
                    };
                    dataCredits.push(DataCredit);
               });

               // กรณี Debit และ Credit เท่ากัน
               if (amountDebit == amountCredit) {
                    Swal.fire({
                         icon: "info",
                         text: "จำนวนเงิน Credit และ Debit เท่ากัน กดยืนยันได้เลย",
                         showConfirmButton: true,
                         showCancelButton: true,
                         confirmButtonText: "ยืนยัน",
                         cancelButtonText: "ยกเลิก",
                    }).then(async (result) => {
                         if (result.isConfirmed) {
                              let status_id = 4;
                              const payload = {
                                   debit: dataDebits,
                                   credit: dataCredits,
                                   status_id: status_id,
                              };
                              // console.log('payload:', payload)
                              // let res
                              const res = await POST(SUCCESSAR, payload);

                              if (res && res.success) {
                                   Swal.fire({
                                        icon: "success",
                                        text: "ส่งข้อมูลสำเร็จ",
                                        showConfirmButton: true,
                                   });
                                   getData();
                                   setPage(0);
                              } else {
                                   Swal.fire({
                                        icon: "warning",
                                        text: res.message || "เกิดข้อผิดพลาด",
                                        showConfirmButton: true,
                                   });
                              }
                         }
                    });
               } else if (amountDebit < amountCredit) {
                    Swal.fire({
                         title: "Debit น้อยกว่า Credit",
                         text: "คุณคาดว่าจะได้เงินหรือไม่?",
                         icon: "info",
                         cancelButtonText: "ยกเลิก",
                         confirmButtonText: "คาดว่าจะได้รับเงิน",
                         denyButtonText: "คาดว่าจะไม่ได้รับเงิน",
                         showCancelButton: true,
                         showDenyButton: true,
                    }).then(async (result) => {
                         if (result.isConfirmed) {
                              alert("กำลังพัฒนา");
                              //   // กรณีคิดว่าจะได้เงิน
                              //   let status_id = 5;
                              //   const abs1 = Math.abs(amountDebit - amountCredit);
                              //   const credit = Math.abs(amountCredit - abs1);
                              //   // console.log('credit:', credit)

                              //   const dataedit = {
                              //        account_name_credit: item.credit[0].account_name_credit,
                              //        ref_doc: item.credit[0].ref_doc,
                              //        documentName: item.debit[0].documentName,
                              //        account_name: item.credit[0].account_name_credit,
                              //        credit,
                              //   };
                              //   const payload = {
                              //        debit: dataDebits,
                              //        credit: [...dataCredits, dataedit],
                              //        status_id: status_id,
                              //   };
                              //   // console.log('payload:', payload)
                              //   // let res
                              //   const res = await POST(INFOAR, payload);

                              //   if (res && res.success) {
                              //        Swal.fire({
                              //             icon: "success",
                              //             text: "ส่งข้อมูลสำเร็จ",
                              //             showConfirmButton: true,
                              //        });
                              //        getData();
                              //        setPage(0);
                              //   } else {
                              //        Swal.fire({
                              //             icon: "warning",
                              //             text: res.message || "เกิดข้อผิดพลาด",
                              //             showConfirmButton: true,
                              //        });
                              //   }
                         } else if (result.isDenied) {
                              // กรณีคิดว่าไม่ได้เงิน
                              Swal.fire({
                                   icon: "info",
                                   text: "ข้อมูลนี้ยืนยันว่าจะไม่ได้รับเงิน",
                                   confirmButtonText: "ตกลง",
                                   cancelButtonText: "ยกเลิก",
                                   showConfirmButton: true,
                                   showCancelButton: true,
                              }).then(async (result) => {
                                   if (result.isConfirmed) {
                                        let status_id = 6;
                                        const debit = Math.abs(amountDebit - amountCredit);
                                        const dataedit = {
                                             account_no: item.credit[0].account_no,
                                             account_name: null,
                                             account_name_debit: null,
                                             claim: item.credit[0].claim,
                                             ref_doc: item.credit[0].ref_doc,
                                             ref_no: item.debit[0].ref_no,
                                             documentName: item.debit[0].documentName,
                                             file_name: item.credit[0].file_name,
                                             finance_date: item.credit[0].finance_date,
                                             hcode: item.credit[0].hcode,
                                             credit: 0,
                                             debit,
                                        };

                                        const payload = {
                                             debit: [dataDebits[0], dataedit],
                                             credit: [...dataCredits],
                                             status_id: status_id,
                                             type: "under",
                                        };
                                        // console.log("payload:", payload);
                                        const res = await POST(SUCCESSAR, payload);
                                        if (res && res.success) {
                                             Swal.fire({
                                                  icon: "success",
                                                  text: "ส่งข้อมูลสำเร็จ",
                                                  showConfirmButton: true,
                                             });
                                             getData();
                                             setPage(0);
                                        } else {
                                             Swal.fire({
                                                  icon: "warning",
                                                  text: res.message || "เกิดข้อผิดพลาด",
                                                  showConfirmButton: true,
                                             });
                                        }
                                   }
                              });
                         }
                    });
               } else if (amountDebit > amountCredit) {
                    Swal.fire({
                         title: "Debit มากกว่า Credit",
                         text: "กรณีได้รับเงินมากกว่าลูกหนี้ที่ตั้งไว้",
                         icon: "info",
                         confirmButtonText: "ตกลง",
                         showCancelButton: true,
                         cancelButtonText: "ยกเลิก",
                    }).then(async (result) => {
                         if (result.isConfirmed) {
                              let status_id = 4;
                              const credit = Math.abs(amountDebit - amountCredit);

                              const dataedit = {
                                   account_no: item.credit[0].account_no,
                                   account_name: null,
                                   account_name_credit: null,
                                   claim: item.credit[0].claim,
                                   ref_doc: item.credit[0].ref_doc,
                                   ref_no: item.debit[0].ref_no,
                                   documentName: item.debit[0].documentName,
                                   file_name: item.credit[0].file_name,
                                   finance_date: item.credit[0].finance_date,
                                   hcode: item.credit[0].hcode,
                                   credit,
                                   debit: 0,
                              };

                              const payload = {
                                   debit: dataDebits,
                                   credit: [...dataCredits, dataedit],
                                   status_id: status_id,
                                   type: "over",
                              };
                              //   console.log("payload:", payload);
                              const res = await POST(SUCCESSAR, payload);
                              if (res && res.success) {
                                   Swal.fire({
                                        icon: "success",
                                        text: "ส่งข้อมูลสำเร็จ",
                                        showConfirmButton: true,
                                   });
                                   getData();
                                   setPage(0);
                              } else {
                                   Swal.fire({
                                        icon: "warning",
                                        text: res.message || "เกิดข้อผิดพลาด",
                                        showConfirmButton: true,
                                   });
                              }
                         }
                    });
               }
          } catch (error) {
               Swal.fire({
                    icon: "error",
                    text: error.message || "เกิดข้อผิดพลาด",
                    confirmButtonText: "ตกลง",
               });
          }
     };

     const handleOpenDialogAdd = (item) => {
          //   console.log("AL: item", item);
          // item.credit?.map((credit) => {
          setDataAdd(item);
          setNewDataAdd({
               //    documentName: item.credit[0]?.file_name || "",
               //    ref_doc: item.credit[0]?.ref_doc || "",
               //    rep: item.credit[0]?.documentName || "",
               //    account_name_credit: item.credit[0]?.account_name_credit || "",
               //    account_name: "",
               debit: item.credit[0].credit,
               credit: item.credit[0].credit,
               // });
          });
          setOpenDialogAdd(true);
     };

     const handleOpenDialogEdit = (item) => {
          // console.log('item:', item)
          // item.credit?.map((credit) => {
          setNewDataEdit({
               documentName: item.credit[0]?.file_name || "",
               ref_doc: item.credit[1]?.ref_doc || "",
               rep: item.credit[1]?.rep || "",
               created_at: item.credit[1]?.created_at || "",
               account_name: item.credit[0]?.account_name || "",
               debit: "",
               credit: "",
               // });
          });
          setOpenDialogEdit(true);
     };

     const handleCloseDialogAdd = () => {
          setOpenDialogAdd(false);
     };

     const handleCloseDialogEdit = () => {
          setOpenDialogEdit(false);
     };

     const handleInputChangeAdd = (e) => {
          const { name, value } = e.target;
          setNewDataAdd((prevData) => ({
               ...prevData,
               [name]: value,
          }));
     };

     const handleInputChangeEdit = (e) => {
          const { name, value } = e.target;
          setNewDataEdit((prevData) => ({
               ...prevData,
               [name]: value,
          }));
     };

     const handleChangePage = (event, newPage) => setPage(newPage);

     const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
     };

     useEffect(() => {
          getData();
          setPage(0);
     }, [status, search]);

     return (
          <>
               <FinancialDeductionlist
                    data={data}
                    status={status}
                    setStatus={setStatus}
                    page={page}
                    serch={search}
                    setSearch={setSearch}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    newDataAdd={newDataAdd}
                    newDataEdit={newDataEdit}
                    handleCheck={handleCheck}
                    openDialogAdd={openDialogAdd}
                    openDialogEdit={openDialogEdit}
                    handleOpenDialogAdd={handleOpenDialogAdd}
                    handleOpenDialogEdit={handleOpenDialogEdit}
                    handleCloseDialogAdd={handleCloseDialogAdd}
                    handleCloseDialogEdit={handleCloseDialogEdit}
                    handleSaveAdd={handleSaveAdd}
                    handleSaveEdit={handleSaveEdit}
                    handleInputChangeAdd={handleInputChangeAdd}
                    handleInputChangeEdit={handleInputChangeEdit}
               />
          </>
     );
}

export default Financial_Deduction;
