import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import { CloseIcn } from "../../../untils/icons";
import { ButtonTeal } from "../../../Theme";
import { POST, UPDATEDATAFINANCIAL, UPDATEDATAFINANCIALACCOUNT } from "../../../service";
import Swal from "sweetalert2";

export default function DialogAdd({ open, handleClose, getDataAccount }) {
    const [data, setData] = useState({
        claim: "",
        type: "",
        account_name: "",
        account_number: "",
        account_name_credit: "",
        account_name_debit: "",
        account_no_credit: "",
        account_no_debit: "",
        ref_doc_credit: "",
        ref_doc_debit: "",
    });
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [type, setType] = useState("");

    const formRef = useRef();

    const getUpdateFinancial = async () => {
        try {
            setLoading(true);

            const payload =
                type === "Debit"
                    ? {
                        claim: status,
                        account_name: data.account_name,
                        account_number: data.account_number,
                        account_name_debit: data.account_name_debit,
                        account_no_debit: data.account_no_debit,
                        ref_doc_debit: data.ref_doc_debit,
                    }
                    : {
                        claim: status,
                        account_name: data.account_name,
                        account_number: data.account_number,
                        account_name_credit: data.account_name_credit,
                        account_no_credit: data.account_no_credit,
                        ref_doc_credit: data.ref_doc_credit,
                    };

            const res = await POST(UPDATEDATAFINANCIALACCOUNT, payload);

            if (res && res.success) {
                setData({
                    claim: "",
                    type: "",
                    account_name: "",
                    account_number: "",
                    account_name_credit: "",
                    account_name_debit: "",
                    account_no_credit: "",
                    account_no_debit: "",
                    ref_doc_credit: "",
                    ref_doc_debit: "",
                });
                Swal.fire({
                    icon: "success",
                    text: "อัปเดตข้อมูลสำเร็จ",
                    showConfirmButton: true,
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                text: `เกิดข้อผิดพลาด: ${error.message}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            handleClose();
            getDataAccount();
            setLoading(false);
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} className="bg-dialog">
            {data && (
                <Box className="bg-gradiant">
                    <DialogActions className="flex-end">
                        <CloseIcn className="close-icn" onClick={handleClose} />
                    </DialogActions>
                    <DialogTitle className="text-header">เพิ่มเลขบัญชีข้อมูล</DialogTitle>
                    <DialogContent className="flex-center">
                        <form ref={formRef} className="flex-center">
                            <DialogContentText mt={2} className="container-bg" component={"span"}>
                                <Grid container columns={12} spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>เลือกสิทธิ</InputLabel>
                                            <Select
                                                name=""
                                                value={status}
                                                label="เลือกสิทธิ"
                                                // displayEmpty
                                                required
                                                onChange={(e) => setStatus(e.target.value)}
                                            >
                                                <MenuItem value="SSO">SSO</MenuItem>
                                                <MenuItem value="OP/PP">OP/PP</MenuItem>
                                                <MenuItem value="LGO">LGO</MenuItem>
                                                <MenuItem value="ECLAIM">ECLAIM</MenuItem>
                                                <MenuItem value="KTB">KTB</MenuItem>
                                                <MenuItem value="MOPH">MOPH</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>เลือกประเภท</InputLabel>
                                            <Select
                                                name=""
                                                value={type}
                                                label="เลือกประเภท"
                                                // displayEmpty
                                                required
                                                onChange={(e) => setType(e.target.value)}
                                            >
                                                <MenuItem value="Debit">Debit</MenuItem>
                                                <MenuItem value="Credit">Credit</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="เลขบัญชี"
                                            name="accountName"
                                            variant="outlined"
                                            value={data.account_number || ""}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    account_number: e.target.value,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="ชื่อบัญชี"
                                            name="accountName"
                                            variant="outlined"
                                            value={data.account_name || ""}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    account_name: e.target.value,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="ชื่อบัญชีคู่"
                                            name="accountName"
                                            variant="outlined"
                                            value={type === "Debit" ? data.account_name_debit : data.account_name_credit || ""}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    account_name_debit: type === "Debit" ? e.target.value : data.account_name_debit,
                                                    account_name_credit: type === "Credit" ? e.target.value : data.account_name_credit,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="เลขบัญชีคู่"
                                            name="accountNo"
                                            variant="outlined"
                                            value={type === "Debit" ? data.account_no_debit : data.account_no_credit || ""}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    account_no_debit: type === "Debit" ? e.target.value : data.account_no_debit,
                                                    account_no_credit: type === "Credit" ? e.target.value : data.account_no_credit,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="อ้างอิงเอกสาร"
                                            name="refDoc"
                                            variant="outlined"
                                            value={type === "Debit" ? data.ref_doc_debit : data.ref_doc_credit || ""}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    ref_doc_debit: type === "Debit" ? e.target.value : data.ref_doc_debit,
                                                    ref_doc_credit: type === "Credit" ? e.target.value : data.ref_doc_credit,
                                                })
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </form>
                    </DialogContent>
                    <DialogActions className="flex-center">
                        <ButtonTeal disabled={loading} sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }} onClick={() => getUpdateFinancial()}>
                            {loading ? "กำลังเพิ่ม..." : "เพิ่มข้อมูลเลขบัญชี"}
                        </ButtonTeal>
                    </DialogActions>
                </Box>
            )}
        </Dialog>
    );
}
