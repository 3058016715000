import React, { useEffect, useState } from "react";
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Grid,
    TableCell,
    Collapse,
    IconButton,
    Box,
    Checkbox,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { StyledTableCell } from "../../../Theme.jsx";

export default function GridTable({ header, ChronicDetail, data, selectChronic, chronic, setChronic }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openRows, setOpenRows] = useState({});

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChange = (event) => {
        const { value, checked } = event.target;
        const parsedValue = JSON.parse(value);

        setChronic((prev) => {
            if (checked) {
                return [...prev, parsedValue];
            } else {
                return prev.filter((item) => item.id !== parsedValue.id);
            }
        });
    };

    const toggleRowOpen = (index) => {
        setOpenRows((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    function formatDate(dateString) {
        if (!dateString) return "N/A";

        let year, month, day;

        if (dateString.length === 8) {
            year = dateString.substring(0, 4);
            month = dateString.substring(4, 6);
            day = dateString.substring(6, 8);
        } else if (dateString.includes("-") && dateString.split("-").length === 3) {
            [year, month, day] = dateString.split("-");
        } else if (dateString.includes("/") && dateString.split("/").length === 3) {
            [day, month, year] = dateString.split("/");
        } else {
            return "N/A";
        }

        const monthNamesThai = [
            "มกราคม",
            "กุมภาพันธ์",
            "มีนาคม",
            "เมษายน",
            "พฤษภาคม",
            "มิถุนายน",
            "กรกฎาคม",
            "สิงหาคม",
            "กันยายน",
            "ตุลาคม",
            "พฤศจิกายน",
            "ธันวาคม",
        ];

        const monthName = monthNamesThai[parseInt(month, 10) - 1];

        if (!monthName) return "N/A";

        return `${day} ${monthName} ${year}`;
    }

    useEffect(() => {
        setOpenRows({});
    }, [page]);

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Box sx={{ padding: 2, display: "flex", flexWrap: "wrap", gap: 2 }}>
                {selectChronic.length > 0 &&
                    selectChronic.map((e) => (
                        <Grid item xs={12} md={2} key={e.id}>
                            <Typography>
                                <Checkbox
                                    sx={{ padding: "2px 9px 9px 9px" }}
                                    defaultChecked={chronic.some((item) => item.id === e.id)}
                                    checked={chronic.some((item) => item.id === e.id)}
                                    value={JSON.stringify(e)}
                                    onChange={(event) => handleChange(event)}
                                    color="success"
                                />
                                <label>
                                    <img
                                        style={{ width: "20px", height: "20px" }}
                                        src={require(`../../../assets/images/location-pin (${e.id}).png`)}
                                        alt="ไม่มีรูป"
                                    />
                                    {e.name}
                                </label>
                            </Typography>
                        </Grid>
                    ))}
            </Box>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column, i) => (
                                <StyledTableCell key={i + 1} align={column.align} style={{ minWidth: column.minWidth }}>
                                    <Typography fontWeight="bold" fontSize="14px">
                                        {column.label}
                                    </Typography>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <React.Fragment key={index}>
                                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                                    <StyledTableCell>
                                        <IconButton aria-label="expand row" size="small" onClick={() => toggleRowOpen(index)}>
                                            {openRows[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell align="rigth">{row.hn ? row.hn : "N/A"}</StyledTableCell>
                                    <StyledTableCell align="left">{row.title ? row.title : "N/A"}</StyledTableCell>
                                    <StyledTableCell align="left">{row.chronic ? row.chronic : "N/A"}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                        <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                                            <Box sx={{ margin: 1 }}>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    ข้อมูลเข้ารับการตรวจ
                                                </Typography>
                                                <Table size="small" aria-label="details">
                                                    <TableHead>
                                                        <TableRow>
                                                            {ChronicDetail.map((column, i) => (
                                                                <StyledTableCell key={i}>
                                                                    <Typography fontWeight="bold" fontSize="14px">
                                                                        {column.label}
                                                                    </Typography>
                                                                </StyledTableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {row.data_array && row.data_array.length ? (
                                                            row.data_array.map((item, id) => (
                                                                <TableRow key={id}>
                                                                    <StyledTableCell align="left">ตรวจครั้งที่ {id + 1}</StyledTableCell>
                                                                    <StyledTableCell align="center">
                                                                        {item.HOSPCODE ? item.HOSPCODE : "N/A"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center">{item.PID ? item.PID : "N/A"}</StyledTableCell>
                                                                    <StyledTableCell align="center">{item.SEQ ? item.SEQ : "N/A"}</StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        {formatDate(item.DATE_SERV) ? formatDate(item.DATE_SERV) : "N/A"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center">
                                                                        {item.WEIGHT ? item.WEIGHT : "N/A"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center">
                                                                        {item.HEIGHT ? item.HEIGHT : "N/A"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center">{item.SBP ? item.SBP : "N/A"}</StyledTableCell>
                                                                    <StyledTableCell align="center">{item.DBP ? item.DBP : "N/A"}</StyledTableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <TableRow>
                                                                <StyledTableCell colSpan={ChronicDetail.length} align="center">
                                                                    ไม่มีข้อมูลการเข้าตรวจ
                                                                </StyledTableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 && (
                <Grid container justifyContent="center" my={3}>
                    <Typography>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            )}
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
            />
        </Paper>
    );
}
