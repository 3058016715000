import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { CHRONIC, DATAMAPCHRONIC, DATATABLECHRONIC, GET, POST_GIS, SELECTORCHRONIC } from "../../service";
import { getToken } from "../../untils/shortcut";
import GridCard from "./DashboardMapChronic/GridCard";

function DashboardMapChronic() {
    const profile = getToken(localStorage.getItem("profile"));
    const [loading, setLoading] = useState(false);
    const [hcode, setHcode] = useState("");
    const [adl_type, setAdlType] = useState("");
    const [chronic, setChronic] = useState([]);
    const [selectChronic, setSelectChronic] = useState([]);
    const [data, setData] = useState([]);
    const [dataTbl, setDataTbl] = useState([]);
    const [proLocationName, setProLocationName] = useState("ทั้งหมด");
    const [dataLocation, setDataLocation] = useState([]);
    const [subdistrictObj, setSubdistrictObj] = useState([]);
    const [select_adl_type, set_Select_adl_type] = useState("ทั้งหมด");
    const [selectView, setSelectView] = useState("แผนที่");
    const [obj, setObj] = useState([]);
    const totalChronic = obj.map((e) =>
        e.properties.dataChronic.reduce((acc, item) => {
            const chronicDm = item.count_chronic_dm ? item.count_chronic_dm : 0;
            const chronicHt = item.count_chronic_ht ? item.count_chronic_ht : 0;
            return acc + chronicDm + chronicHt;
        }, 0)
    );
    // console.log(totalChronic);

    const selectorChronic = async () => {
        try {
            setLoading(true);
            const res = await GET(SELECTORCHRONIC);
            let hospname = profile.location_code + profile.location_name;
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setAdlType(res.result.adl_type);
                    setSelectChronic(res.result.chronic);
                    setChronic(res.result.chronic);
                    setProLocationName(hospname);
                }
                setAdlType(res.result.adl_type);
                setSelectChronic(res.result.chronic);
                setChronic(res.result.chronic);
                setDataLocation(res.result.hospital);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "warning",
                text: error,
                icon: "warning",
                showConfirmButton: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const getDataTable = async () => {
        try {
            setLoading(true);
            const res = await POST_GIS(DATATABLECHRONIC, {
                hcode,
                adl_type: select_adl_type,
                chronic,
            });
            if (res.success) {
                setDataTbl(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "warning",
                text: error,
                icon: "warning",
                showConfirmButton: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const getMapDashboard = async () => {
        try {
            setLoading(true);
            const res = await POST_GIS(CHRONIC, {
                hcode,
                adl_type: select_adl_type,
                chronic,
            });
            if (res.success) {
                if (profile.role_id === 3 || profile.role_id === 4) {
                    setHcode(profile.location_code);
                    setObj(res.result.features);
                    setSubdistrictObj(res.result.features);
                }
                setObj(res.result.features);
                setSubdistrictObj(res.result.features);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "warning",
                text: error,
                icon: "warning",
                showConfirmButton: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const getDataMapZoom = async (e) => {
        // console.log("e:", e);
        try {
            const res = await POST_GIS(DATAMAPCHRONIC, {
                hcode,
                adl_type: select_adl_type,
                chronic,
                tamboncode: e[0],
                bounds: e[1],
            });
            if (res.success) {
                setData(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "warning",
                text: error,
                icon: "warning",
                showConfirmButton: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        selectorChronic();
    }, []);

    useEffect(() => {
        if (selectView === "ตาราง") {
            getDataTable();
        }
    }, [selectView, select_adl_type, selectChronic, chronic, hcode]);

    useEffect(() => {
        if (selectView === "แผนที่") {
            getMapDashboard();
        }
    }, [selectView, select_adl_type, selectChronic, chronic, hcode]);

    return (
        <GridCard
            profile={profile}
            data={data}
            dataTbl={dataTbl}
            proLocationName={proLocationName}
            dataLocation={dataLocation}
            setProLocationName={setProLocationName}
            selectChronic={selectChronic}
            setChronic={setChronic}
            chronic={chronic}
            hcode={hcode}
            adl_type={adl_type}
            setAdlType={setAdlType}
            setHcode={(e) => setHcode(e)}
            subdistrictObj={subdistrictObj}
            loading={loading}
            set_Select_adl_type={set_Select_adl_type}
            select_adl_type={select_adl_type}
            setSelectView={setSelectView}
            selectView={selectView}
            getDataMapZoom={(e) => getDataMapZoom(e)}
            setData={setData}
            totalChronic={totalChronic}
        />
    );
}

export default DashboardMapChronic;
