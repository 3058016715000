import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
    DELETEFINANCIAL,
    ECLAIM,
    GENEXCELSTATEMENT,
    GENPDFSTATEMENT,
    INANCIAL,
    KTB,
    LGO,
    MOPH,
    OPPP,
    POST,
    SSO,
    UPDATEDATAFINANCIAL,
    ip,
} from "../../service";
import FinancialList from "./components/FinancialList";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import axios from "axios";

function FinancialStatement() {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState("0");
    const [statuswait, setStatusWait] = useState([1]);
    const [cleared, setCleared] = useState(false);
    const [refDoc, setRefDoc] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [accountName, setAccountName] = useState("");
    const [credit, setCredit] = useState("");
    const [debit, setDebit] = useState("");
    const [detail, setDetail] = useState("");
    const [financeDate, setFinanceDate] = useState("");
    const [loading, setLoading] = useState(false);
    // const defaultDateStart = dayjs().subtract(3, 'month');
    // const defaultDateEnd = dayjs();
    const defaultDateStart = dayjs('2024-01-01').startOf('day');
    const defaultDateEnd = dayjs('2024-12-31').endOf('day');
    const [datestart, setDateStart] = useState(defaultDateStart);
    const [dateend, setDateEnd] = useState(defaultDateEnd);
    const [dateInput, setDateInput] = useState(dayjs());
    const [openEdit, setOpenEdit] = useState(false);
    const [search, setSearch] = useState("");
    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedRows, setSelectedRows] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [page, setPage] = useState(0);

    const token = localStorage.getItem("uuid");

    const combinedData = [
        ...(Array.isArray(data) ? data : []),
        ...(Array.isArray(data?.ssop) ? data.ssop : []),
        ...(Array.isArray(data?.oppp) ? data.oppp : []),
        ...(Array.isArray(data?.eclaim) ? data.eclaim : []),
        ...(Array.isArray(data?.lgo) ? data.lgo : []),
        ...(Array.isArray(data?.ktb) ? data.ktb : []),
        ...(Array.isArray(data?.moph) ? data.moph : [])
    ];

    const uniqueData = Array.from(
        new Set(combinedData.map((item) => JSON.stringify(item)))
    ).map((item) => JSON.parse(item));

    const sortedData = uniqueData.sort((a, b) => {
        const dateA = dayjs(a.finance_date || a.DataCredit.finance_date);
        const dateB = dayjs(b.finance_date || b.DataCredit.finance_date);
        return dateB - dateA;
    });

    const getData = async () => {
        try {
            setLoading(true);

            const payload = {
                keyword: search,
                datestart: datestart,
                dateend: dateend,
                status: statuswait,
            };

            // const payload = {
            //     datestart: datestart,
            //     dateend: dateend,
            //     status1: statuswait,
            //     ...(status === "2" && { status2: 4 }), 
            // };

            let res;

            if (status === "0") {
                res = await POST(INANCIAL, payload);
            } else if (status === "1") {
                res = await POST(SSO, payload);
            } else if (status === "2") {
                res = await POST(OPPP, payload);
            } else if (status === "3") {
                res = await POST(LGO, payload);
            } else if (status === "4") {
                res = await POST(ECLAIM, payload);
            } else if (status === "5") {
                res = await POST(KTB, payload);
            } else if (status === "6") {
                res = await POST(MOPH, payload);
            }

            if (res && res.success) {
                setData(res.data);
                setLoading(false);
                setPage(0);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const onConfirm = async (rowData) => {
        const dataToSend = rowData;

        try {
            const confirmation = await Swal.fire({
                title: "ตรวจสอบการยืนยัน",
                text: "คุณต้องการยืนยันหรือไม่?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                confirmButtonColor: "green",
                cancelButtonText: "ยกเลิก",
                reverseButtons: true,
            });
            setLoading(true);

            if (confirmation.isConfirmed) {
                const payload = { ...dataToSend, status: "2" };

                let res = await POST(UPDATEDATAFINANCIAL, payload);

                if (res?.success) {
                    await getData();
                    setSelectedCount(0);
                    setSelectedRows({});
                    setSelectAll(false);
                    setPage(0);
                    await Swal.fire({
                        icon: "success",
                        title: "สำเร็จ",
                        text: "การยืนยันสำเร็จ!",
                        confirmButtonText: "ตกลง",
                    });
                } else {
                    await getData();
                    setSelectedCount(0);
                    setSelectedRows({});
                    setSelectAll(false);
                    setPage(0);
                    await Swal.fire({
                        icon: "warning",
                        title: "เกิดข้อผิดพลาด",
                        text: res?.message || "ไม่สามารถยืนยันได้",
                        confirmButtonText: "ตกลง",
                    });
                }
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                text: error.message || "ไม่สามารถดำเนินการได้",
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const onCancel = async () => {
        if (selectedCount === 0) {
            Swal.fire({
                icon: "warning",
                title: "กรุณาเลือกแถวก่อน",
                text: "กรุณาเลือกแถวที่คุณต้องการยกเลิก",
                confirmButtonText: "ตกลง",
            });
            return;
        }

        try {
            const confirmation = await Swal.fire({
                title: "ยกเลิกการตรวจสอบ",
                text: "คุณต้องการยกเลิกข้อมูลหรือไม่?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                confirmButtonColor: "green",
                cancelButtonText: "ยกเลิก",
                reverseButtons: true,
            });

            setLoading(true);

            if (confirmation.isConfirmed) {
                const selectedData = Object.keys(selectedRows).filter((fileName) => selectedRows[fileName]);

                const payload = selectedData.map((fileName) => {
                    const rowData = sortedData.find(row => row.fileName === fileName || row.file_name === fileName || row.zipFileName === fileName);

                    if (!rowData) return null;

                    return {
                        ...rowData,
                        status: JSON.stringify(statuswait) === JSON.stringify([2, 4]) ? "3"
                            : JSON.stringify(statuswait) === JSON.stringify([3]) ? "1"
                                : rowData.status,
                    };
                }).filter((item) => item !== null);

                if (payload.length > 0) {
                    const res = await POST(DELETEFINANCIAL, { rows: payload });

                    if (res.success) {
                        await getData();
                        setSelectedCount(0);
                        setSelectedRows({});
                        setSelectAll(false);
                        setPage(0);
                        await Swal.fire({
                            icon: "success",
                            title: "ยกเลิกสำเร็จ",
                            text: "ลบข้อมูลเรียบร้อย",
                            confirmButtonText: "ตกลง",
                        });
                    } else {
                        await getData();
                        setSelectedCount(0);
                        setSelectedRows({});
                        setSelectAll(false);
                        setPage(0);
                        await Swal.fire({
                            icon: "warning",
                            text: res.message || "ไม่สามารถยกเลิกได้",
                            showConfirmButton: true,
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "warning",
                        title: "ไม่พบข้อมูลที่เลือก",
                        text: "ไม่มีข้อมูลที่สามารถยกเลิกได้",
                        confirmButtonText: "ตกลง",
                    });
                }
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                text: error.message || "ไม่สามารถยกเลิกได้",
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const genExcel = async () => {
        setLoading(true);
        try {
            const res = await axios.post(
                ip + GENEXCELSTATEMENT,
                { date_start: datestart, date_end: dateend, text: search },
                {
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token || "token",
                    },
                }
            );

            const excelBlob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const fileURL = URL.createObjectURL(excelBlob);
            const a = document.createElement("a");
            a.href = fileURL;
            a.download = "financial_statement_report.xlsx";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(fileURL);
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error.response ? error.response.data : error.message}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const genPDF = async () => {
        try {
            await axios
                .post(
                    ip + GENPDFSTATEMENT,
                    { date_start: datestart, date_end: dateend, text: search },
                    {
                        responseType: "blob",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: token ? token : "token",
                        },
                    }
                )
                .then(async (res) => {
                    const pdfBlob = new Blob([res.data], {
                        type: "application/pdf",
                    });
                    const fileURL = URL.createObjectURL(pdfBlob);
                    window.open(fileURL);
                });
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    useEffect(() => {
        getData();
        setSelectedCount(0);
        setSelectedRows({});
        setSelectAll(false);
        setPage(0);
    }, [datestart, dateend, status, statuswait, search]);

    return (
        <>
            <BackdropLoad loading={loading} />
            <FinancialList
                data={data}
                status={status}
                setStatus={setStatus}
                statuswait={statuswait}
                setStatusWait={setStatusWait}
                setCleared={setCleared}
                datestart={datestart}
                setDateStart={setDateStart}
                dateend={dateend}
                setDateEnd={setDateEnd}
                refDoc={refDoc}
                setRefDoc={setRefDoc}
                accountNo={accountNo}
                setAccountNo={setAccountNo}
                accountName={accountName}
                setAccountName={setAccountName}
                debit={debit}
                setDebit={setDebit}
                credit={credit}
                setCredit={setCredit}
                detail={detail}
                setDetail={setDetail}
                financeDate={financeDate}
                setFinanceDate={setFinanceDate}
                dateInput={dateInput}
                setDateInput={setDateInput}
                onConfirm={onConfirm}
                onCancel={onCancel}
                selectedCount={selectedCount}
                setSelectedCount={setSelectedCount}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                search={search}
                setSearch={setSearch}
                genExcel={genExcel}
                genPDF={genPDF}
                sortedData={sortedData}
                page={page}
                setPage={setPage}
            />
        </>
    );
}

export default FinancialStatement;
