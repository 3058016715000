import drug from "../assets/images/list_alt.png";
import stock from "../assets/images/Delivery-Outline.png";
import transaction from "../assets/images/edit_note.png";
import device from "../assets/images/router.png";
import hospital from "../assets/images/domain_add.png";
import location from "../assets/images/add_home_work.png";
import vendor from "../assets/images/medical_services.png";
// import history from "../assets/images/history.png"
import user from "../assets/images/manage_accounts.png";
import report from "../assets/images/mandat-Outline.png";
// import approve from "../assets/images/pending_actions.png"
import role from "../assets/images/admin_icon.png";
import verified from "../assets/images/verified_user.png";
import dashboard from "../assets/images/dashboard.png";
import news from "../assets/images/feed.png";
import token from "../assets/images/token.png";
import medication from "../assets/images/medication.png";
import statement from "../assets/images/payments.png";
import setting from "../assets/images/settings.png";
import report_dashboard from "../assets/images/assessment.png";
import person from "../assets/images/groups.png";
import dayjs from "dayjs";

export const headerTable = {
     stock_header: [
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "center",
          },
          {
               id: "drug_code",
               label: "รหัสเวชภัณฑ์",
               minWidth: 200,
               align: "left",
          },
          // {
          //     id: 'lot_no',
          //     label: 'Lot no.',
          //     minWidth: 100,
          //     align: 'center',
          // },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "left",
          },
          {
               id: "pack",
               label: "ขนาดบรรจุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "remain",
               label: "จำนวนคงเหลือ (หน่วย)",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ราคาต่อหน่วย (บาท)",
               minWidth: 100,
               align: "right",
          },
          // {
          //     id: 'date_in',
          //     label: 'วันที่เข้าระบบ',
          //     minWidth: 100,
          //     align: 'right',
          // },
          // {
          //     id: 'expire',
          //     label: 'วันหมดอายุ',
          //     minWidth: 100,
          //     align: 'right',
          // },
     ],
     requisition_header: [
          {
               id: "date",
               label: "วันที่ทำรายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "type",
               label: "เลขคำสั่ง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "file",
               label: "เอกสารที่เกี่ยวข้อง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียดรายการ",
               minWidth: 100,
               align: "center",
          },
     ],
     requisition_detail: [
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 100,
               align: "left",
          },
          {
               id: "lot_no",
               label: "Lot no.",
               minWidth: 100,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภทเวชภัณฑ์",
               minWidth: 100,
               align: "left",
          },
          {
               id: "unit",
               label: "หน่วยนับ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "expire",
               label: "วันหมดอายุ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "amount",
               label: "จำนวนเบิก",
               minWidth: 50,
               align: "right",
          },
     ],
     requisition_add_loca: [
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "left",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "left",
          },
          {
               id: "unit",
               label: "หน่วยนับ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "remain",
               label: "จำนวนที่แนะนำ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "amount",
               label: "จำนวนที่ต้องการเบิก",
               minWidth: 100,
               align: "center",
          },
          {
               id: "difference",
               label: "ผลต่าง",
               minWidth: 50,
               align: "right",
          },
          {
               id: "delete",
               label: "ลบ",
               minWidth: 50,
               align: "center",
          },
     ],
     requisition_add_hos: [
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "unit",
               label: "หน่วยนับ",
               minWidth: 50,
               align: "center",
          },
          {
               id: "remain",
               label: "จำนวนเวชภัณฑ์ในคงคลัง",
               minWidth: 50,
               align: "center",
          },
          {
               id: "amount",
               label: "จำนวนที่ขอเบิก",
               minWidth: 50,
               align: "center",
          },
          {
               id: "remain",
               label: "จำนวนที่สามารถให้เบิกได้",
               minWidth: 100,
               align: "center",
          },
     ],
     user_header: [
          {
               id: "date_created",
               label: " วันที่สร้าง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "username",
               label: "ชื่อผู้ใช้งาน",
               minWidth: 100,
               align: "left",
          },
          {
               id: "role",
               label: "บทบาท",
               minWidth: 100,
               align: "left",
          },
          {
               id: "phone",
               label: "หมายเลขโทรศัพท์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "location",
               label: "สถานพยาบาล",
               minWidth: 100,
               align: "left",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "edit",
               label: "แก้ไข",
               minWidth: 100,
               align: "center",
          },
          {
               id: "delete",
               label: "ลบ",
               minWidth: 100,
               align: "center",
          },
     ],
     role_header: [
          // {
          //     id: 'date_created',
          //     label: ' วันที่สร้าง',
          //     minWidth: 100,
          //     align: 'center',
          // },
          {
               id: "role",
               label: "บทบาท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "screen_permission",
               label: "หน้าที่จะแสดง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "edit",
               label: "แก้ไข",
               minWidth: 100,
               align: "center",
          },
          {
               id: "delete",
               label: "ลบ",
               minWidth: 100,
               align: "center",
          },
     ],
     drug_header: [
          {
               id: "drug_name",
               label: " ชื่อเวชภัณฑ์ (EN)",
               minWidth: 150,
               align: "left",
          },
          {
               id: "drug_name_thai",
               label: "ชื่อเวชภัณฑ์ (TH)",
               minWidth: 150,
               align: "left",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "left",
          },
          {
               id: "pack",
               label: "ขนาดบรรจุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ราคาซื้อ (บาท)",
               minWidth: 100,
               align: "right",
          },
          {
               id: "sell",
               label: "ราคาจำหน่าย (บาท)",
               minWidth: 100,
               align: "right",
          },
          {
               id: "unit",
               label: "หน่วยนับ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "vendor_name",
               label: "บริษัทยา",
               minWidth: 100,
               align: "left",
          },
          {
               id: "vendor_country",
               label: "ประเทศผู้ผลิตยา",
               minWidth: 100,
               align: "left",
          },
          {
               id: "dispensing_unit",
               label: "หน่วยการจ่ายยา",
               minWidth: 100,
               align: "left",
          },
          {
               id: "tpu_id",
               label: "TPUID",
               minWidth: 100,
               align: "left",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "left",
          },
          // {
          //     id: 'edit',
          //     label: 'แก้ไข',
          //     minWidth: 50,
          //     align: 'center',
          // },
          // {
          //     id: 'delete',
          //     label: 'ลบ',
          //     minWidth: 50,
          //     align: 'center',
          // },
     ],
     history_header: [
          {
               id: "date_requisition",
               label: "วันที่ขอทำรายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "date_recive_order",
               label: "วันที่รับรายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "date_recive_drug",
               label: "วันที่รับเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "order",
               label: "เลขคำสั่ง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียดรายการ",
               minWidth: 100,
               align: "center",
          },
     ],
     dashboard_header: [
          {
               id: "INSCL",
               label: "INSCL",
               minWidth: 100,
               align: "left",
          },
          {
               id: "TYPE",
               label: "TYPE",
               minWidth: 100,
               align: "left",
          },
          {
               id: "DETAIL",
               label: "DETAIL",
               minWidth: 100,
               align: "left",
          },
          {
               id: "bar",
               label: "",
               minWidth: 100,
               align: "center",
          },
     ],
     dashboardAll43_header: [
          {
               id: "all",
               label: "ระดับ ALL",
               minWidth: 100,
               align: "left",
          },
          {
               id: "CC",
               label: "ค่าเฉลี่ยของ ค่าเฉลี่ย CC",
               minWidth: 100,
               align: "right",
          },
          {
               id: "KPI",
               label: "ค่าเฉลี่ยของ ค่าเฉลี่ย KPI",
               minWidth: 100,
               align: "right",
          },
          {
               id: "KPI_CC",
               label: "ค่าเฉลี่ยของ ค่าเฉลี่ย KPI CC",
               minWidth: 100,
               align: "right",
          },
     ],
     history_detail: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "pack",
               label: "ขนาดบรรจุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "remain",
               label: "จำนวนคงเหลือ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "unit",
               label: "หน่วย",
               minWidth: 100,
               align: "center",
          },
          {
               id: "lot_no",
               label: "Lot no.",
               minWidth: 100,
               align: "center",
          },
          {
               id: "origin",
               label: "ต้นทาง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "destination",
               label: "ปลายทาง",
               minWidth: 100,
               align: "center",
          },
     ],
     device: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "username",
               label: "ชื่อผู้ใช้งาน",
               minWidth: 200,
               align: "center",
          },
          {
               id: "location_name",
               label: "ชื่อสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "phone",
               label: "หมายเลขโทรศัพท์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "mac_address",
               label: "รหัสเครื่อง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "action",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "Delete",
               label: "ลบ",
               minWidth: 100,
               align: "center",
          },
     ],
     approved: [
          {
               id: "date",
               label: "วันที่ทำรายการ",
               minWidth: 50,
               align: "center",
          },
          {
               id: "order",
               label: "เลขคำสั่ง",
               minWidth: 200,
               align: "center",
          },
          {
               id: "remain",
               label: "จำนวนเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียดรายการ",
               minWidth: 100,
               align: "center",
          },
          // {
          //     id: 'action',
          //     label: 'การดำเนินการ',
          //     minWidth: 100,
          //     align: 'center',
          // },
     ],
     approved_detail: [
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 100,
               align: "left",
          },
          {
               id: "pack",
               label: "ขนาดเวชภัณฑ์",
               minWidth: 100,
               align: "left",
          },
          {
               id: "type",
               label: "ประเภทเวชภัณฑ์",
               minWidth: 100,
               align: "left",
          },
          {
               id: "amount",
               label: "จำนวนเบิก",
               minWidth: 50,
               align: "right",
          },
          {
               id: "unit",
               label: "หน่วยนับ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "lot_no",
               label: "Lot No.",
               minWidth: 100,
               align: "center",
          },
          {
               id: "amount_confirm",
               label: "จำนวนที่เบิกได้",
               minWidth: 50,
               align: "center",
          },
          {
               id: "expire",
               label: "วันหมดอายุ",
               minWidth: 100,
               align: "center",
          },
     ],
     hospital: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "hoscode",
               label: "รหัสสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "hospital_name",
               label: "ชื่อสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
     ],
     hospital_status: [
          // {
          //     id: 'number',
          //     label: 'ลำดับที่',
          //     minWidth: 50,
          //     align: 'center',
          // },
          // {
          //     id: 'hoscode',
          //     label: 'รหัสสถานพยาบาล',
          //     minWidth: 100,
          //     align: 'center',
          // },
          // {
          //   id: "hcode",
          //   label: "ชื่อสถานพยาบาล",
          //   minWidth: 100,
          //   align: "left",
          // },
          {
               id: "dt_update",
               label: "วันที่ดึงข้อมูล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะดึงข้อมูลล่าสุด(ครั้ง)",
               minWidth: 100,
               align: "center",
          },
          // {
          //     id: "previous_status",
          //     label: "สถานะย้อนหลัง(7 วัน)",
          //     minWidth: 100,
          //     align: "center",
          // },
     ],
     hospitalStatus_prev: [
          {
               id: "1",
               label: "วันที่ 7",
               minwidth: 100,
               align: "center",
          },
          {
               id: "2",
               label: "วันที่ 6",
               minwidth: 100,
               align: "center",
          },
          {
               id: "3",
               label: "วันที่ 5",
               minwidth: 100,
               align: "center",
          },
          {
               id: "4",
               label: "วันที่ 4",
               minwidth: 100,
               align: "center",
          },
          {
               id: "5",
               label: "วันที่ 3",
               minwidth: 100,
               align: "center",
          },
          {
               id: "6",
               label: "วันที่ 2",
               minwidth: 100,
               align: "center",
          },
          {
               id: "7",
               label: "วันที่ 1",
               minwidth: 100,
               align: "center",
          },
     ],
     location: [
          // {
          //     id: 'number',
          //     label: 'ลำดับที่',
          //     minWidth: 50,
          //     align: 'center',
          // },
          {
               id: "loca_code",
               label: "รหัสสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "location_name",
               label: "ชื่อสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "option",
               label: "รูปแบบการเคลม",
               minWidth: 100,
               align: "center",
          },
          {
               id: "action",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     vendor: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "vendor_name",
               label: "ชื่อบริษัท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "vendor_location",
               label: "สถานที่ตั้ง",
               minWidth: 200,
               align: "center",
          },
     ],
     dashboard_chart: [
          {
               id: "location",
               label: "สถานพยาบาล",
               minWidth: 150,
               align: "left",
          },
          {
               id: "tambon",
               label: "ตำบล",
               minWidth: 100,
               align: "left",
          },
          {
               id: "money",
               label: "เงินโอนเข้าบัญชี",
               minWidth: 100,
               align: "right",
          },
     ],
     report_drug: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "drug_name",
               label: " ชื่อเวชภัณฑ์",
               minWidth: 150,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "amount",
               label: "จำนวน",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียด",
               minWidth: 100,
               align: "center",
          },
     ],
     report_drug_detail: [
          {
               id: "location_name",
               label: "ชื่อสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "amount",
               label: "จำนวน",
               minWidth: 100,
               align: "center",
          },
          {
               id: "date",
               label: " วันที่ขอทำรายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "date_recive",
               label: " วันที่รับเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
     ],
     report_location: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "location_name",
               label: " ชื่อสถานพยาบาล",
               minWidth: 150,
               align: "center",
          },
          {
               id: "amount",
               label: "จำนวนเลขคำสั่ง",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียด",
               minWidth: 100,
               align: "center",
          },
     ],
     report_location_detail: [
          {
               id: "order",
               label: "เลขคำสั่ง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "amount",
               label: " จำนวน",
               minWidth: 50,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่ขอทำรายการ",
               minWidth: 100,
               align: "center",
          },

          {
               id: "date_recive",
               label: "วันที่รับเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียด",
               minWidth: 50,
               align: "center",
          },
     ],
     report_location_order: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "pack",
               label: "ขนาดบรรจุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "remain",
               label: "จำนวนคงเหลือ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "unit",
               label: "หน่วย",
               minWidth: 100,
               align: "center",
          },
          {
               id: "lot_no",
               label: "Lot no.",
               minWidth: 100,
               align: "center",
          },
     ],
     report_approve: [
          {
               id: "location_name",
               label: " ชื่อสถานพยาบาล",
               minWidth: 150,
               align: "center",
          },
          {
               id: "order",
               label: "เลขคำสั่ง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่ขอทำรายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียด",
               minWidth: 100,
               align: "center",
          },
     ],
     report_approve_detail: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "pack",
               label: "ขนาดบรรจุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "remain",
               label: "จำนวนคงเหลือ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "unit",
               label: "หน่วย",
               minWidth: 100,
               align: "center",
          },
          {
               id: "lot_no",
               label: "Lot no.",
               minWidth: 100,
               align: "center",
          },
     ],
     claim: [
          // {
          //     id: 'CID',
          //     label: 'CID',
          //     minWidth: 100,
          //     align: 'center',
          // },
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          // {
          //     id: "TITLE",
          //     label: "คำนำหน้าชื่อ",
          //     minWidth: 100,
          //     align: "left",
          // },
          {
               id: "NAMEPAT",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "DETAIL",
               label: "อาการวินิจฉัย",
               minWidth: 350,
               align: "center",
          },
          {
               id: "medicine",
               label: "รหัสยา (ราคา)",
               minWidth: 150,
               align: "left",
          },
          // {
          //      id: "PERMITNO",
          //      label: "ตรวจสอบเลขยืนยันตัวตน",
          //      minWidth: 180,
          //      align: "left",
          // },
          {
               id: "preaudit",
               label: "Pre Audit",
               minWidth: 150,
               align: "left",
          },
          {
               id: "DATEDX",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "status_claim",
               label: "สถานะการส่ง",
               minWidth: 150,
               align: "center",
          },

          // {
          //     id: 'lastname',
          //     label: 'นามสกุล',
          //     minWidth: 200,
          //     align: 'left',
          // },
          {
               id: "DOB",
               label: "อายุ (ปี)",
               minWidth: 80,
               align: "right",
          },
          // {
          //      id: "HCODE",
          //      label: "HCODE",
          //      minWidth: 150,
          //      align: "center",
          // },
          // {
          //     id: 'DIAG',
          //     label: 'รหัสวินิจฉัยโรค (ICD)',
          //     minWidth: 150,
          //     align: 'center',
          // },
          // {
          //     id: 'odx_name',
          //     label: 'ชนิดของโรค',
          //     minWidth: 200,
          //     align: 'left',
          // },
          {
               id: "SEQ",
               label: "รหัสการบริการ",
               minWidth: 150,
               align: "center",
          },
          // {
          //     id: 'LABTEST',
          //     label: 'ชื่อแลป',
          //     minWidth: 100,
          //     align: 'right',
          // },
          // {
          //     id: 'LABRESULT',
          //     label: 'ผลแลป',
          //     minWidth: 100,
          //     align: 'right',
          // },
          // {
          //     id: 'service',
          //     label: 'ค่าบริการ',
          //     minWidth: 100,
          //     align: 'right',
          // },
          //   {
          //        id: "detail",
          //        label: "รายละเอียด",
          //        minWidth: 100,
          //        align: "center",
          //   },
     ],
     claim_not: [
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          {
               id: "NAMEPAT",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "DETAIL",
               label: "อาการวินิจฉัย",
               minWidth: 350,
               align: "center",
          },
          {
               id: "medicine",
               label: "รหัสยา (ราคา)",
               minWidth: 150,
               align: "left",
          },
          {
               id: "preaudit",
               label: "Pre Audit",
               minWidth: 150,
               align: "left",
          },
          {
               id: "massage",
               label: "สปสช. ตอบกลับ",
               minWidth: 150,
               align: "left",
          },
          {
               id: "DATEDX",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          // {
          //      id: "status_claim",
          //      label: "สถานะการส่ง",
          //      minWidth: 150,
          //      align: "center",
          // },
          {
               id: "DOB",
               label: "อายุ (ปี)",
               minWidth: 80,
               align: "right",
          },
          {
               id: "SEQ",
               label: "รหัสการบริการ",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_all: [
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          {
               id: "NAMEPAT",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "DETAIL",
               label: "อาการวินิจฉัย",
               minWidth: 350,
               align: "center",
          },
          {
               id: "medicine",
               label: "รหัสยา (ราคา)",
               minWidth: 150,
               align: "left",
          },
          {
               id: "preaudit",
               label: "Pre Audit",
               minWidth: 150,
               align: "left",
          },
          {
               id: "massage",
               label: "สปสช. ตอบกลับ",
               minWidth: 150,
               align: "left",
          },
          {
               id: "DATEDX",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "status_claim",
               label: "สถานะการส่ง",
               minWidth: 150,
               align: "center",
          },
          {
               id: "DOB",
               label: "อายุ (ปี)",
               minWidth: 80,
               align: "right",
          },
          {
               id: "SEQ",
               label: "รหัสการบริการ",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_vaccine: [
          {
               id: "HN",
               label: "HN",
               minWidth: 150,
               align: "left",
          },
          {
               id: "name",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "birth",
               label: "อายุ (ปี)",
               minWidth: 80,
               align: "right",
          },
          {
               id: "status_c",
               label: "สถานะการเคลม",
               minWidth: 150,
               align: "center",
          },
          {
               id: "vaccinecode",
               label: "รหัสวัคซีน",
               minWidth: 150,
               align: "center",
          },
          // {
          //      id: "code_claim",
          //      label: "Code Claim",
          //      minWidth: 200,
          //      align: "center",
          // },
          {
               id: "code_claim",
               label: "ชื่อวัคซีน",
               minWidth: 150,
               align: "center",
          },
          // {
          //      id: "DATEDX",
          //      label: "วันที่รับบริการ",
          //      minWidth: 150,
          //      align: "center",
          // },
          {
               id: "dateepi",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "datevacineexpire",
               label: "Vacine expire",
               minWidth: 150,
               align: "center",
          },
          {
               id: "visitno",
               label: "ลำดับเข้ารับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "lotno",
               label: "Lot No.",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_vaccine_ktb: [
          {
               id: "HN",
               label: "HN",
               minWidth: 150,
               align: "left",
          },
          {
               id: "name",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "birth",
               label: "อายุ (ปี)",
               minWidth: 80,
               align: "right",
          },
          {
               id: "status_c",
               label: "สถานะการเคลม",
               minWidth: 150,
               align: "center",
          },
          {
               id: "vaccinecode",
               label: "รหัสวัคซีน",
               minWidth: 150,
               align: "center",
          },
          {
               id: "code_claim",
               label: "ชื่อวัคซีน",
               minWidth: 150,
               align: "center",
          },
          {
               id: "dateepi",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "datevacineexpire",
               label: "Vacine expire",
               minWidth: 150,
               align: "center",
          },
          {
               id: "visitno",
               label: "ลำดับเข้ารับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "lotno",
               label: "Lot No.",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_dmht: [
          {
               id: "HN",
               label: "HN",
               minWidth: 150,
               align: "left",
          },
          {
               id: "name",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "birth",
               label: "อายุ (ปี)",
               minWidth: 80,
               align: "right",
          },
          {
               id: "status_c",
               label: "สถานะการเคลม",
               minWidth: 150,
               align: "center",
          },
          {
               id: "DATEDX",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "HbA1C",
               label: "HbA1C",
               minWidth: 150,
               align: "center",
          },
          {
               id: "Creatinine",
               label: "Creatinine (Cr)",
               minWidth: 150,
               align: "center",
          },
          {
               id: "Potassium",
               label: "Potassium (K)",
               minWidth: 150,
               align: "center",
          },
          {
               id: "SEQ",
               label: "SEQ",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_history: [
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          {
               id: "TITLE",
               label: "คำนำหน้าชื่อ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "NAMEPAT",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "center",
          },
          {
               id: "DOB",
               label: "อายุ (ปี)",
               minWidth: 100,
               align: "right",
          },
          {
               id: "DATEDX",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "SEQ",
               label: "รหัสการบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียด",
               minWidth: 100,
               align: "center",
          },
     ],
     claim_detail: [
          {
               id: "type",
               label: "ประเภท",
               minWidth: 50,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่",
               minWidth: 100,
               align: "center",
          },
          {
               id: "CODE",
               label: "รหัส",
               minWidth: 100,
               align: "center",
          },
          {
               id: "adp_type_name",
               label: "รายการ",
               minWidth: 100,
               align: "center",
          },
          //   {
          //        id: "ICD10",
          //        label: "รหัสวินิจฉัยโรค (ICD 10)",
          //        minWidth: 150,
          //        align: "center",
          //   },
          //   {
          //        id: "DIAG",
          //        label: "รหัสวินิจฉัยโรค (ICD 9 CM)",
          //        minWidth: 150,
          //        align: "center",
          //   },
          //   {
          //        id: "odx_name",
          //        label: "ชนิดของโรค",
          //        minWidth: 200,
          //        align: "left",
          //   },
          //   {
          //        id: "LABTEST",
          //        label: "ชื่อแลป",
          //        minWidth: 100,
          //        align: "right",
          //   },
          //   {
          //        id: "LABRESULT",
          //        label: "ผลแลป",
          //        minWidth: 100,
          //        align: "right",
          //   },
          {
               id: "QTY",
               label: "จำนวน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ค่าบริการ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "dt_update",
               label: "วันที่ดึงข้อมูล",
               minWidth: 150,
               align: "center",
          },
          //   {
          //        id: "rights",
          //        label: "การใช้สิทธิ",
          //        minWidth: 150,
          //        align: "left",
          //   },
          // {
          //      id: "preaudit",
          //      label: "Pre Audit",
          //      minWidth: 150,
          //      align: "left",
          // },
          // {
          //      id: "status_claim",
          //      label: "สถานะ",
          //      minWidth: 150,
          //      align: "center",
          // },
     ],
     claim13_detail: [
          {
               id: "type",
               label: "ประเภท",
               minWidth: 50,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่",
               minWidth: 100,
               align: "center",
          },
          {
               id: "CODE",
               label: "รหัส",
               minWidth: 100,
               align: "center",
          },
          {
               id: "adp_type_name",
               label: "รายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "QTY",
               label: "จำนวน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ค่าบริการ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "dt_update",
               label: "วันที่ดึงข้อมูล",
               minWidth: 150,
               align: "center",
          },
     ],
     claimOPPP_detail: [
          {
               id: "type",
               label: "ประเภท",
               minWidth: 50,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่",
               minWidth: 100,
               align: "center",
          },
          {
               id: "CODE",
               label: "รหัส",
               minWidth: 100,
               align: "center",
          },
          {
               id: "adp_type_name",
               label: "รายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "QTY",
               label: "จำนวน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ค่าบริการ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "dt_update",
               label: "วันที่ดึงข้อมูล",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_sso: [
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "left",
          },
          {
               id: "NAMEPAT",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "sso_reply",
               label: "ประกันสังคมตอบกลับ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "date",
               label: "วันที่",
               minWidth: 100,
               align: "center",
          },
          // {
          //   id: "DATEDX",
          //   label: "วันที่รับบริการ",
          //   minWidth: 150,
          //   align: "center",
          // },
          {
               id: "status_claim",
               label: "สถานะการส่ง",
               minWidth: 150,
               align: "center",
          },
          // {
          //   id: "age",
          //   label: "อายุ (ปี)",
          //   minWidth: 80,
          //   align: "right",
          // },
          {
               id: "invno",
               label: "รหัสการบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "claimAmt",
               label: "ยอดเคลม",
               minWidth: 80,
               align: "center",
          },
     ],
     claim_close: [
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          {
               id: "NAMEPAT",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "DETAIL",
               label: "อาการวินิจฉัย",
               minWidth: 350,
               align: "center",
          },
          {
               id: "medicine",
               label: "รหัสยา (ราคา)",
               minWidth: 150,
               align: "left",
          },
          {
               id: "massage",
               label: "สปสช. ตอบกลับ",
               minWidth: 150,
               align: "left",
          },
          {
               id: "DATEDX",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "status_claim",
               label: "สถานะการส่งเคลม",
               minWidth: 150,
               align: "center",
          },
          {
               id: "SEQ",
               label: "รหัสการบริการ",
               minWidth: 150,
               align: "center",
          },
     ],
     headerOdx: [
          {
               id: "date",
               label: "วันที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "diag",
               label: "DIAG",
               minWidth: 50,
               align: "center",
          },
          {
               id: "dxtype",
               label: "DX TYPE",
               minWidth: 20,
               align: "center",
          },
          {
               id: "drdx",
               label: "DRDX",
               minWidth: 100,
               align: "center",
          },
          {
               id: "dt_update",
               label: "วันที่ดึงข้อมูล",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_detail_vaccine: [
          {
               id: "date",
               label: "วันที่",
               minWidth: 100,
               align: "center",
          },
          {
               id: "CODE",
               label: "CODE",
               minWidth: 100,
               align: "center",
          },
          {
               id: "adp_type_name",
               label: "ประเภทการเคลม",
               minWidth: 100,
               align: "center",
          },
          {
               id: "QTY",
               label: "จำนวน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ค่าบริการ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "preaudit",
               label: "Pre Audit",
               minWidth: 150,
               align: "left",
          },
          {
               id: "status_claim",
               label: "สถานะ",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_detail_history: [
          {
               id: "date",
               label: "วันที่",
               minWidth: 100,
               align: "center",
          },
          {
               id: "CODE",
               label: "CODE",
               minWidth: 100,
               align: "center",
          },
          {
               id: "adp_type_name",
               label: "ประเภทการเคลม",
               minWidth: 100,
               align: "center",
          },
          {
               id: "QTY",
               label: "จำนวน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ค่าบริการ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "preaudit",
               label: "Pre Audit",
               minWidth: 150,
               align: "left",
          },
          {
               id: "status_claim",
               label: "สถานะ",
               minWidth: 150,
               align: "center",
          },
     ],
     news_header: [
          {
               id: "news_list",
               label: "รายการข่าวสาร",
               minWidth: 200,
               align: "center",
          },
          {
               id: "news_date",
               label: "วันที่",
               minWidth: 100,
               align: "center",
          },
          {
               id: "img",
               label: "รูปภาพ",
               minWidth: 100,
               align: "center",
          },
     ],
     statement_header: [
          {
               id: "date",
               label: "วันที่โอน",
               minWidth: 100,
               align: "center",
          },
          {
               id: "filename",
               label: "รายการ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "sub",
               label: "กองทุน",
               minWidth: 100,
               align: "left",
          },
          {
               id: "amount",
               label: "จำนวนเงิน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "delay",
               label: "รอโอนเงิน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "bookbank",
               label: "เงินโอนเข้าบัญชี",
               minWidth: 100,
               align: "right",
          },
          {
               id: "pdf",
               label: "",
               minWidth: 100,
               align: "center",
          },
     ],
     statement_detail_header: [
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "left",
          },
          {
               id: "namepat",
               label: "ชื่อ-สกุล",
               minWidth: 100,
               align: "left",
          },
          {
               id: "date",
               label: "วันที่",
               minWidth: 100,
               align: "left",
          },
          {
               id: "detail",
               label: "อาการวินิจฉัย",
               minWidth: 150,
               align: "left",
          },
          {
               id: "medicine",
               label: "รหัสยา (ราคา)",
               minWidth: 100,
               align: "left",
          },
          {
               id: "request",
               label: "ขอเบิก",
               minWidth: 100,
               align: "right",
          },
          {
               id: "reponse",
               label: "ยอดชดเชยทั้งสิ้น",
               minWidth: 100,
               align: "right",
          },
     ],
     herb_tbl: [
          {
               id: "code",
               label: "รหัสเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 150,
               align: "left",
          },
          {
               id: "edit",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     pregnant_tbl: [
          {
               id: "code",
               label: "รหัสเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 150,
               align: "left",
          },
          {
               id: "edit",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     contraceptive_pill_tbl: [
          {
               id: "code",
               label: "รหัสเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 150,
               align: "left",
          },
          {
               id: "edit",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     manage_claim: [
          {
               id: "type_claim",
               label: "ประเภทการเคลม",
               minWidth: 100,
               align: "left",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "no",
               label: "ลำดับ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "edit",
               label: "แก้ไข",
               minWidth: 100,
               align: "center",
          },
          {
               id: "action",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     manage_url: [
          {
               id: "name",
               label: "ชื่อหน้า",
               minWidth: 100,
               align: "left",
          },
          {
               id: "url",
               label: "url",
               minWidth: 100,
               align: "left",
          },
          {
               id: "type",
               label: "ประเภทของหน้า",
               minWidth: 100,
               align: "center",
          },
          {
               id: "edit",
               label: "แก้ไข",
               minWidth: 150,
               align: "center",
          },
     ],
     person_header: [
          {
               id: "fname",
               label: "ชื่อ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "lname",
               label: "นามสกุล",
               minWidth: 100,
               align: "left",
          },
          {
               id: "person_id",
               label: "เลขบัตรประจำตัวประชาชน",
               minWidth: 100,
               align: "center",
          },
          {
               id: "bday",
               label: "วันเกิด",
               minWidth: 100,
               align: "center",
          },
          {
               id: "age",
               label: "อายุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "sex",
               label: "เพศ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "Rights",
               label: "ตรวจสอบสิทธิ",
               minWidth: 100,
               align: "center",
          },
     ],
     person_header2: [
          {
               id: "fname",
               label: "ชื่อ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "lname",
               label: "นามสกุล",
               minWidth: 100,
               align: "left",
          },
          {
               id: "person_id",
               label: "เลขบัตรประจำตัวประชาชน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "insc",
               label: "สิทธิ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "bday",
               label: "วันเกิด",
               minWidth: 100,
               align: "right",
          },
          {
               id: "age",
               label: "อายุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "sex",
               label: "เพศ",
               minWidth: 100,
               align: "right",
          },
     ],
     INSCL_header: [
          {
               id: "fname",
               label: "ชื่อ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "lname",
               label: "นามสกุล",
               minWidth: 100,
               align: "left",
          },
          {
               id: "id",
               label: "หมายเลขบัตร",
               minWidth: 100,
               align: "center",
          },
          {
               id: "bday",
               label: "วันเกิด",
               minWidth: 100,
               align: "center",
          },
          {
               id: "age",
               label: "อายุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "hname",
               label: "โรงพยาบาลหลัก",
               minWidth: 150,
               align: "lefe",
          },
          {
               id: "sex",
               label: "เพศ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "province",
               label: "จังหวัด",
               minWidth: 150,
               align: "lefe",
          },
     ],
     Hypertension_header: [
          // {
          //     id: "hcode",
          //     label: "hcode",
          //     minWidth: 100,
          //     align: "center",
          // },
          {
               id: "hname",
               label: "ชื่อรพ.สต.",
               minWidth: 100,
               align: "left",
          },
          {
               id: "target",
               label: "เป้าหมาย",
               minWidth: 100,
               align: "center",
          },
          {
               id: "screening",
               label: "คัดกรอง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "percent",
               label: "ร้อยละ",
               minWidth: 100,
               align: "center",
          },
     ],
     Diabetes_header: [
          // {
          //     id: "hcode",
          //     label: "hcode",
          //     minWidth: 100,
          //     align: "center",
          // },
          {
               id: "hname",
               label: "ชื่อรพ.สต.",
               minWidth: 100,
               align: "center",
          },
          {
               id: "target",
               label: "เป้าหมาย",
               minWidth: 100,
               align: "center",
          },
          {
               id: "screening",
               label: "คัดกรอง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "percent",
               label: "ร้อยละ",
               minWidth: 100,
               align: "center",
          },
     ],
     K101_TablePregnant: [
          {
               id: "hname",
               label: "ชื่อสถานพยาบาล (รพ.สต.)",
               minWidth: 100,
               align: "left",
          },
          {
               id: "pregnant_pass",
               label: "คนท้องผ่านเกณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "pregnant_all",
               label: "คนท้องทั้งหมด",
               minWidth: 100,
               align: "center",
          },
          {
               id: "percent",
               label: "ร้อยละ",
               minWidth: 100,
               align: "center",
          },
     ],
     K101_TablePregnantTarget: [
          {
               id: "hname",
               label: "ชื่อสถานพยาบาล (รพ.สต.)",
               minWidth: 100,
               align: "left",
          },
          {
               id: "target_value",
               label: "ค่าเป้าหมาย",
               minWidth: 100,
               align: "center",
          },
          {
               id: "pregnant_pass",
               label: "คนท้องผ่านเกณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "percent",
               label: "ร้อยละ",
               minWidth: 100,
               align: "center",
          },
     ],
     Dashboard_NotPass_Claim: [
          {
               id: "count",
               label: "จำนวน",
               minWidth: 300,
               align: "center",
          },
     ],
     Dashboard_Pass_Claim: [
          {
               id: "count",
               label: "จำนวน",
               minWidth: 300,
               align: "center",
          },
     ],
     Debtor_header: [
          {
               id: "account name",
               label: "ชื่อบัญชี",
               minWidth: 100,
               align: "center",
          },
          {
               id: "account number",
               label: "รหัสบัญชี",
               minWidth: 100,
               align: "center",
          },
          {
               id: "category",
               label: "หมวด",
               minWidth: 100,
               align: "center",
          },
          {
               id: "category name",
               label: "ชื่อหมวด",
               minWidth: 100,
               align: "center",
          },
          {
               id: "debit",
               label: "เครดิต",
               minWidth: 100,
               align: "center",
          },
          {
               id: "credit",
               label: "เดบิต",
               minWidth: 100,
               align: "center",
          },
     ],
     Claim_close: [
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          {
               id: "NAMEPAT",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "DETAIL",
               label: "อาการวินิจฉัย",
               minWidth: 350,
               align: "center",
          },
          {
               id: "medicine",
               label: "รหัสยา (ราคา)",
               minWidth: 150,
               align: "left",
          },
          {
               id: "preaudit",
               label: "Pre Audit",
               minWidth: 150,
               align: "left",
          },
          {
               id: "massage",
               label: "สปสช. ตอบกลับ",
               minWidth: 150,
               align: "left",
          },
          {
               id: "DATEDX",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "status_claim",
               label: "สถานะการส่ง",
               minWidth: 150,
               align: "center",
          },
          {
               id: "DOB",
               label: "อายุ (ปี)",
               minWidth: 80,
               align: "right",
          },
          {
               id: "SEQ",
               label: "รหัสการบริการ",
               minWidth: 150,
               align: "center",
          },
     ],
     FinancialStatementheader1: [
          {
               id: "",
               label: "",
               minWidth: 10,
               align: "center",
          },
          {
               id: "claim",
               label: "สิทธิ",
               minWidth: 50,
               align: "center",
          },
          {
               id: "accountNo",
               label: "งวด/เลขที่เบิกจ่าย",
               minWidth: 200,
               align: "center",
          },
          // {
          //     id: "date",
          //     label: "วันที่",
          //     minWidth: 150,
          //     align: "center",
          // },
          // {
          //     id: "ref_doc",
          //     label: "เลขที่เอกสาร",
          //     minWidth: 200,
          //     align: "center",
          // },
          {
               id: "accountNo",
               label: "รหัสบัญชี",
               minWidth: 200,
               align: "center",
          },
          {
               id: "accountNumber",
               label: "ชื่อบัญชี",
               minWidth: 200,
               align: "center",
          },
          {
               id: "debit",
               label: "เดบิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "credit",
               label: "เครดิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "หมายเหตุ",
               minWidth: 200,
               align: "center",
          },
          {
               id: "edit",
               label: "ดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     FinancialStatementheader2: [
          {
               id: "",
               label: "",
               minWidth: 10,
               align: "center",
          },
          {
               id: "claim",
               label: "สิทธิ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "accountNo",
               label: "งวด/เลขที่เบิกจ่าย",
               minWidth: 200,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่",
               minWidth: 150,
               align: "center",
          },
          {
               id: "accountNo",
               label: "เลขที่เอกสาร",
               minWidth: 200,
               align: "center",
          },
          {
               id: "ref_doc",
               label: "รหัสบัญชี",
               minWidth: 200,
               align: "center",
          },
          {
               id: "accountNumber",
               label: "ชื่อบัญชี",
               minWidth: 200,
               align: "center",
          },
          {
               id: "debit",
               label: "เดบิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "credit",
               label: "เครดิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "หมายเหตุ",
               minWidth: 200,
               align: "center",
          },
     ],
     FinancialStatementheader3: [
          {
               id: "",
               label: "",
               minWidth: 10,
               align: "center",
          },
          {
               id: "claim",
               label: "สิทธิ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "accountNo",
               label: "งวด/เลขที่เบิกจ่าย",
               minWidth: 200,
               align: "center",
          },
          // {
          //     id: "date",
          //     label: "วันที่",
          //     minWidth: 150,
          //     align: "center",
          // },
          // {
          //     id: "accountNo",
          //     label: "เลขที่เอกสาร",
          //     minWidth: 200,
          //     align: "center",
          // },
          {
               id: "ref_doc",
               label: "รหัสบัญชี",
               minWidth: 200,
               align: "center",
          },
          {
               id: "accountNumber",
               label: "ชื่อบัญชี",
               minWidth: 200,
               align: "center",
          },
          {
               id: "debit",
               label: "เดบิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "credit",
               label: "เครดิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "หมายเหตุ",
               minWidth: 200,
               align: "center",
          },
     ],
     FinancialStatementSSO: [
          {
               id: "pid",
               label: "เลขบัตรประชาชน",
               minWidth: 100,
               align: "center",
          },
          {
               id: "invno",
               label: "Invno",
               minWidth: 50,
               align: "center",
          },
          {
               id: "hospmain",
               label: "HospMain",
               minWidth: 50,
               align: "center",
          },
          {
               id: "bp",
               label: "BP",
               minWidth: 50,
               align: "center",
          },
          {
               id: "stat",
               label: "Stat",
               minWidth: 50,
               align: "center",
          },
          {
               id: "station_code",
               label: "Station Code",
               minWidth: 50,
               align: "center",
          },
          {
               id: "check_code",
               label: "Check Code",
               minWidth: 100,
               align: "center",
          },
          {
               id: "amount",
               label: "Amount",
               minWidth: 50,
               align: "center",
          },
          {
               id: "claim_amount",
               label: "Claim Amount",
               minWidth: 50,
               align: "center",
          },
     ],
     FinancialStatementLGO: [
          {
               id: "pid",
               label: "เลขบัตรประชาชน",
               minWidth: 100,
               align: "center",
          },
          {
               id: "net_cpst_med_expenses",
               label: "Case PP",
               minWidth: 100,
               align: "center",
          },
          {
               id: "hcode",
               label: "Hcode",
               minWidth: 100,
               align: "center",
          },
          {
               id: "hn",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          {
               id: "inscl",
               label: "Inscl",
               minWidth: 100,
               align: "center",
          },
          {
               id: "namepat",
               label: "Name",
               minWidth: 100,
               align: "center",
          },
          {
               id: "rep",
               label: "Rep",
               minWidth: 100,
               align: "center",
          },
     ],
     FinancialStatementECLAIM: [
          {
               id: "pid",
               label: "เลขบัตรประชาชน",
               minWidth: 100,
               align: "center",
          },
          {
               id: "dateopd",
               label: "วันที่",
               minWidth: 100,
               align: "center",
               format: (value) => (value ? dayjs(value).format("DD MMM YYYY") : "ไม่มีรายละเอียด"),
          },
          {
               id: "hcode",
               label: "Hcode",
               minWidth: 100,
               align: "center",
          },
          {
               id: "hmain",
               label: "HMain",
               minWidth: 100,
               align: "center",
          },
          {
               id: "hn",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          {
               id: "inscl",
               label: "Inscl",
               minWidth: 100,
               align: "center",
          },
          {
               id: "namepat",
               label: "Name",
               minWidth: 100,
               align: "center",
          },
          {
               id: "patient_type",
               label: "Patient Type",
               minWidth: 100,
               align: "center",
          },
          {
               id: "pp",
               label: "PP",
               minWidth: 100,
               align: "center",
          },
          {
               id: "rep",
               label: "Rep",
               minWidth: 50,
               align: "center",
          },
          {
               id: "tran_id",
               label: "Tran Id",
               minWidth: 100,
               align: "center",
          },
     ],
     FinancialStatementKTB: [
          {
               id: "pid",
               label: "เลขบัตรประชาชน",
               minWidth: 50,
               align: "center",
          },
          {
               id: "dateopd",
               label: "วันที่",
               minWidth: 100,
               align: "center",
               format: (value) => (value ? dayjs(value).format("DD MMM YYYY") : "ไม่มีรายละเอียด"),
          },
          {
               id: "hname",
               label: "HName",
               minWidth: 100,
               align: "center",
          },
          {
               id: "namepat",
               label: "ชื่อ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "risky",
               label: "ชื่อโรค",
               minWidth: 100,
               align: "center",
          },
     ],
     FinancialStatementMOPH: [
          {
               id: "transaction_uid",
               label: "เลขธุรกรรม",
               minWidth: 50,
               align: "center",
          },
          {
               id: "hn",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          {
               id: "fname",
               label: "ชื่อ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "lname",
               label: "นามสกุล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "vaccinecode",
               label: "Vaccine Code",
               minWidth: 100,
               align: "center",
          },
          {
               id: "code_claim",
               label: "Code Claim",
               minWidth: 100,
               align: "center",
          },
          {
               id: "lotno",
               label: "เลขที่การผลิต",
               minWidth: 100,
               align: "center",
          },
     ],
     FinancialDeductionheader1: [
          {
               id: "claim",
               label: "สิทธิ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "accountNo",
               label: "งวด/เลขที่เบิกจ่าย",
               minWidth: 150,
               align: "center",
          },
          {
               id: "account_no",
               label: "รหัสบัญชี",
               minWidth: 200,
               align: "left",
          },
          {
               id: "account_name",
               label: "ชื่อบัญชี",
               minWidth: 200,
               align: "left",
               colSpan: 2,
          },
          {
               id: "ref_doc",
               label: "",
               minWidth: 150,
               align: "center",
          },
          {
               id: "debit",
               label: "เดบิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "credit",
               label: "เครดิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "หมายเหตุ",
               minWidth: 200,
               align: "center",
          },
          {
               id: "edit",
               label: "ดำเนินการ",
               minWidth: 200,
               align: "center",
          },
     ],
     FinancialDeductionheader2: [
          {
               id: "claim",
               label: "สิทธิ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "accountNo",
               label: "งวด/เลขที่เบิกจ่าย",
               minWidth: 150,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่",
               minWidth: 125,
               align: "center",
          },
          {
               id: "ref_doc",
               label: "เลขที่เอกสาร",
               minWidth: 150,
               align: "center",
          },
          {
               id: "account_no",
               label: "รหัสบัญชี",
               minWidth: 200,
               align: "center",
          },
          {
               id: "account_name",
               label: "ชื่อบัญชี",
               minWidth: 200,
               align: "center",
               colSpan: 2,
          },
          {
               id: "ref_doc",
               label: "",
               minWidth: 150,
               align: "center",
          },
          {
               id: "debit",
               label: "เดบิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "credit",
               label: "เครดิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "หมายเหตุ",
               minWidth: 200,
               align: "center",
          },
     ],
     FinancialOutstandingheader1: [
          {
               id: "",
               label: "",
               minWidth: 50,
               align: "center",
          },
          {
               id: "accountNo",
               label: "เลขบัญชี",
               minWidth: 200,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่",
               minWidth: 150,
               align: "center",
          },
          {
               id: "accountNumber",
               label: "ชื่อบัญชี",
               minWidth: 200,
               align: "center",
               colSpan: 2,
          },
          {
               id: "ref_doc",
               label: "เอกสารอ้างอิง",
               minWidth: 200,
               align: "center",
          },
          {
               id: "debit",
               label: "เดบิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "credit",
               label: "เครดิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "หมายเหตุ",
               minWidth: 200,
               align: "center",
          },
          {
               id: "edit",
               label: "ดำเนินการ",
               minWidth: 200,
               align: "center",
          },
     ],
     FinancialOutstandingheader2: [
          // {
          //     id: "",
          //     label: "",
          //     minWidth: 50,
          //     align: "center",
          // },
          {
               id: "accountNo",
               label: "เลขบัญชี",
               minWidth: 200,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่",
               minWidth: 150,
               align: "center",
          },
          {
               id: "accountNumber",
               label: "ชื่อบัญชี",
               minWidth: 200,
               align: "center",
               colSpan: 2,
          },
          {
               id: "ref_doc",
               label: "เอกสารอ้างอิง",
               minWidth: 200,
               align: "center",
          },
          {
               id: "debit",
               label: "เดบิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "credit",
               label: "เครดิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "หมายเหตุ",
               minWidth: 200,
               align: "center",
          },
     ],
     FinancialOutstandingheaderinData: [
          {
               id: "ref_no",
               label: "เลขบัญชี",
               minWidth: 200,
               align: "center",
          },
          {
               id: "finance_date",
               label: "วันที่",
               minWidth: 150,
               align: "center",
          },
          {
               id: "account_name",
               label: "ชื่อบัญชี",
               minWidth: 200,
               align: "center",
          },
          {
               id: "ref_doc",
               label: "เอกสารอ้างอิง",
               minWidth: 200,
               align: "center",
          },
          {
               id: "debit",
               label: "เดบิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "credit",
               label: "เครดิต",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "หมายเหตุ",
               minWidth: 200,
               align: "center",
          },
     ],
     FinancialSum: [
          {
               id: "account name",
               label: "ชื่อบัญชี",
               minWidth: 100,
               align: "center",
          },
          {
               id: "account number",
               label: "รหัสบัญชี",
               minWidth: 100,
               align: "center",
          },
          {
               id: "category",
               label: "หมวด",
               minWidth: 100,
               align: "center",
          },
          {
               id: "category name",
               label: "ชื่อหมวด",
               minWidth: 100,
               align: "center",
          },
     ],
     FinancialSum_db_cb: [
          {
               id: "money in",
               label: "ยอดยกมา",
               minWidth: 100,
               align: "center",
          },
          {
               id: "list of month",
               label: "รายการระหว่างเดือน",
               minWidth: 100,
               align: "center",
          },
          {
               id: "money out",
               label: "ยอดยกไป",
               minWidth: 100,
               align: "center",
          },
     ],
     ManageType: [
          {
               id: "screen_name",
               label: "ชื่อประเภทของหน้า",
               minWidth: 100,
               align: "center",
          },
          {
               id: "type",
               label: "เลขประเภทของหน้า",
               minWidth: 50,
               align: "center",
          },
          {
               id: "edit",
               label: "",
               minWidth: 50,
               align: "right",
          },
     ],
     Chronic: [
          {
               id: "",
               label: "",
               align: "left",
          },
          {
               id: "HN",
               label: "HN",
               minWidth: 150,
               align: "left",
          },
          {
               id: "title",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "th_name",
               label: "โรค",
               minWidth: 80,
               align: "left",
          },
     ],
     ChronicDetail: [
          {
               id: "id",
               label: "ตรวจครั้งที่",
               align: "left",
          },
          {
               id: "HOSPCODE",
               label: "รหัสสถานพยาบาล",
               minWidth: 150,
               align: "center",
          },
          {
               id: "PID",
               label: "รหัสผู้ป่วย",
               minWidth: 200,
               align: "center",
          },
          {
               id: "SEQ",
               label: "ลำดับข้อมูล",
               minWidth: 80,
               align: "center",
          },
          {
               id: "DATE_SERV",
               label: "วันที่ให้บริการ",
               minWidth: 150,
               align: "left",
          },
          {
               id: "WEIGHT",
               label: "น้ำหนัก",
               minWidth: 150,
               align: "center",
          },
          {
               id: "HEIGHT",
               label: "ส่วนสูง",
               minWidth: 150,
               align: "center",
          },
          {
               id: "SBP",
               label: "ความดันโลหิตตัวบน",
               minWidth: 150,
               align: "right",
          },
          {
               id: "DBP",
               label: "ความดันโลหิตตัวล่าง",
               minWidth: 150,
               align: "right",
          },
     ],
     MonitorInputData: [
          {
               id: "hname",
               label: "ชื่อสถานพยาบาล",
               minWidth: 150,
               align: "left",
          },
          {
               id: "data_in",
               label: "จำนวนข้อมูลขาเข้า",
               minWidth: 100,
               align: "center",
          },
          {
               id: "data_out",
               label: "จำนวนข้อมูลขาออก",
               minWidth: 100,
               align: "center",
          },
          {
               id: "table",
               label: "ตาราง",
               minWidth: 100,
               align: "center",
          },
     ],
     Monitor2: [
          {
               id: "hname",
               label: "ชื่อสถานพยาบาล",
               minWidth: 150,
               align: "left",
          },
          {
               id: "data_in",
               label: "จำนวนข้อมูลขาเข้า",
               minWidth: 100,
               align: "center",
          },
          {
               id: "data_out",
               label: "จำนวนข้อมูลขาออก",
               minWidth: 100,
               align: "center",
          },
          {
               id: "table",
               label: "ตาราง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
     ],
     MonitorHDC: [
          {
               id: "hname",
               label: "ชื่อสถานพยาบาล",
               minWidth: 150,
               align: "left",
          },
          {
               id: "data_in",
               label: "จำนวนข้อมูลขาเข้า",
               minWidth: 100,
               align: "center",
          },
          {
               id: "data_out",
               label: "จำนวนข้อมูลขาออก",
               minWidth: 100,
               align: "center",
          },
          {
               id: "table",
               label: "ตาราง",
               minWidth: 100,
               align: "center",
          },
     ],
     DrugCatalog: [
          {
               id: "drug",
               label: "ชื่อยา",
               minWidth: 200,
               align: "center",
          },
          {
               id: "drug_code_his",
               label: "รหัสยา his",
               minWidth: 150,
               align: "center",
          },
          {
               id: "drug_code_24",
               label: "รหัสยา 24 หลัก",
               minWidth: 100,
               align: "center",
          },
          {
               id: "unit",
               label: "หน่วยบรรจุ",
               minWidth: 100,
               align: "center",
          },
     ],
     DrugCatalog_admin: [
          {
               id: "hname",
               label: "ชื่อ รพสต.",
               minWidth: 200,
               align: "center",
          },
          {
               id: "drug",
               label: "ชื่อยา",
               minWidth: 100,
               align: "center",
          },
          {
               id: "drug_code_his",
               label: "รหัสยา his",
               minWidth: 150,
               align: "center",
          },
          {
               id: "drug_code_24",
               label: "รหัสยา 24 หลัก",
               minWidth: 150,
               align: "center",
          },
          {
               id: "unit",
               label: "หน่วยบรรจุ",
               minWidth: 100,
               align: "center",
          },
     ],
     FinancialAccount: [
          {
               id: "rights",
               label: "สิทธิ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "ref_doc_credit",
               label: "อ้างอิงเอกสารเครดิต",
               minWidth: 100,
               align: "center",
          },
          {
               id: "ref_doc_debit",
               label: "อ้างอิงเอกสารเดบิต",
               minWidth: 100,
               align: "center",
          },
          {
               id: "account_name",
               label: "เลขบัญชี",
               minWidth: 100,
               align: "center",
          },
          {
               id: "account_number",
               label: "ชื่อบัญชี",
               minWidth: 100,
               align: "center",
          },
          {
               id: "account_no_credit",
               label: "เลขที่บัญชีเครดิต",
               minWidth: 100,
               align: "center",
          },
          {
               id: "account_no_debit",
               label: "เลขที่บัญชีเดบิต",
               minWidth: 100,
               align: "center",
          },
          {
               id: "account_name_credit",
               label: "ชื่อบัญชีเครดิต",
               minWidth: 100,
               align: "center",
          },
          {
               id: "account_name_debit",
               label: "ชื่อบัญชีเดบิต",
               minWidth: 100,
               align: "center",
          },
          {
               id: "edit",
               label: "ดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
};

export const textFiled = {
     register: [
          {
               name: "name",
               label: "ชื่อ :",
               placeholder: "ชื่อ",
               type: "text",
          },
          {
               name: "lastname",
               label: "นามสกุล :",
               placeholder: "นามสกุล",
               type: "text",
          },
          {
               name: "list_lacation",
               label: "โรงพยาบาลส่งเสริมสุขภาพตำบล :",
               type: "select",
               data_select: "lacation",
          },
          {
               name: "id_card",
               label: "เลขบัตรประจำตัวประชาชน :",
               placeholder: "x xxxx xxxxx xx x",
               type: "text",
          },
          {
               name: "role_id",
               label: "บทบาท :",
               type: "select",
               // data_select: "list_lacation",
          },
          {
               name: "hospital_id",
               label: "โรงพยาบาลศูนย์ :",
               type: "select",
               data_select: "hospital",
          },
          {
               name: "phone",
               label: "หมายเลขโทรศัพท์ :",
               placeholder: "หมายเลขโทรศัพท์",
               type: "text",
          },
          {
               name: "email",
               label: "E-mail :",
               placeholder: "example@email.com",
               type: "text",
          },
          {
               name: "birth_date",
               label: "วัน/เดือน/ปี เกิด :",
               // placeholder: "",
               type: "date",
          },
          {
               name: "password",
               label: "รหัสผ่าน :",
               placeholder: "รหัสผ่าน",
               type: "text",
          },
          {
               name: "confirm_password",
               label: "ยืนยันรหัสผ่าน :",
               placeholder: "ยืนยันรหัสผ่าน",
               type: "text",
          },
     ],
     pages_check: [
          {
               name: "stock",
               label: "เวชภัณฑ์คงคลัง",
          },
          {
               name: "transaction",
               label: "เบิกเวชภัณฑ์",
          },
          {
               name: "drug",
               label: "รายการเวชภัณฑ์",
          },
          {
               name: "hospital",
               label: "โรงพยาบาลศูนย์",
          },
          {
               name: "location",
               label: "โรงพยาบาลส่วนตำบล",
          },
          {
               name: "้history",
               label: "ประวัติการเบิก - จ่าย เวชภัณฑ์",
          },
          {
               name: "user",
               label: "จัดการผู้ใช้งาน",
          },
          {
               name: "vender",
               label: "รายการบริษัทยา",
          },
          {
               name: "device",
               label: "อุปกรณ์",
          },
     ],
     drug: [
          {
               name: "list_type",
               label: "ประเภทเวชภัณฑ์ :",
               type: "select",
               data_select: "list_type",
          },
          {
               name: "drug_code",
               label: "หมายเลขเวชภัณฑ์ :",
               placeholder: "หมายเลขเวชภัณฑ์",
               type: "text",
          },
          {
               name: "drug_name",
               label: "ชื่อเวชภัณฑ์ (EN) :",
               placeholder: "ชื่อภาษาอังกฤษ",
               type: "text",
          },
          {
               name: "drug_name_thai",
               label: "ชื่อเวชภัณฑ์ (TH) :",
               placeholder: "ชื่อภาษาไทย",
               type: "text",
          },
          {
               name: "cost",
               label: "ราคาซื้อ :",
               placeholder: "ราคาซื้อ (บาท)",
               type: "text",
          },
          {
               name: "sell",
               label: "ราคาจำหน่าย :",
               placeholder: "ราคาจำหน่าย (บาท)",
               type: "text",
          },
          {
               name: "unit",
               label: "หน่วยนับ :",
               placeholder: "หน่วยนับ",
               type: "text",
          },
          {
               name: "list_vendor",
               label: "เลือกบริษัทยา",
               type: "select",
               data_select: "list_vendor",
          },
     ],
     banner_history: [
          {
               label: "วันที่ทำรายการ",
               detail: "วว/ดด/ปปปป 00:00:00",
          },
          {
               label: "วันที่รับรายการ",
               detail: "วว/ดด/ปปปป 00:00:00",
          },
          {
               label: "วันที่รับเวชภัณฑ์",
               detail: "วว/ดด/ปปปป 00:00:00",
          },
     ],
     banner_order: [
          {
               label: "วันที่ทำรายการ",
               detail: "วว/ดด/ปปปป 00:00:00",
          },
          {
               label: "วันที่รับเวชภัณฑ์",
               detail: "วว/ดด/ปปปป 00:00:00",
          },
     ],
     location: [
          {
               name: "list_location",
               label: "",
               type: "select",
               data_select: "list_lacation",
          },
     ],
};

export const menuSideBar = {
     menu_all: [
          {
               sub: false,
               expand: false,
               type: 0,
               path_name: "จัดการผู้ใช้งาน",
               url: "/User",
               icon: user,
          },
          {
               sub: false,
               expand: false,
               type: 0,
               path_name: "จัดการสิทธิ์สำหรับผู้ใช้งาน",
               url: "/Role",
               icon: role,
          },
          {
               sub: false,
               expand: false,
               type: 0,
               path_name: "อุปกรณ์",
               url: "/Device",
               icon: device,
          },
          {
               sub: false,
               expand: false,
               type: 0,
               path_name: "โรงพยาบาลศูนย์",
               url: "/Hospital",
               icon: hospital,
          },
          {
               sub: false,
               expand: false,
               type: 0,
               path_name: "โรงพยาบาลส่วนตำบล",
               url: "/Location",
               icon: location,
          },
          {
               sub: false,
               expand: false,
               type: 0,
               path_name: "สถานะการดึงข้อมูล",
               url: "/HospitalStatus",
               icon: hospital,
          },
          {
               sub: false,
               expand: false,
               type: 0,
               path_name: "ข่าวสาร",
               url: "/News",
               icon: news,
          },
          {
               sub: false,
               expand: false,
               type: 0,
               path_name: "จัดการประเภทเคลม",
               url: "/ManageClaim",
               icon: setting,
          },
          {
               sub: false,
               expand: false,
               type: 0,
               path_name: "จัดการ url:",
               url: "/Manageurl:",
               icon: setting,
          },
          {
               sub: false,
               expand: false,
               type: 0,
               path_name: "ตั้งค่า OTP",
               url: "/SettingsOtp",
               icon: setting,
          },
          {
               type: 1,
               sub: false,
               expand: false,
               path_name: "เวชภัณฑ์คงคลัง",
               url: "/Stock",
               icon: stock,
          },
          {
               type: 1,
               sub: false,
               expand: false,
               path_name: "รายการเวชภัณฑ์",
               url: "/Drug",
               icon: drug,
          },
          {
               type: 1,
               sub: false,
               expand: false,
               path_name: "เบิกเวชภัณฑ์โรงพยาบาลส่วนตำบล",
               url: "/RequisitionLocation",
               icon: transaction,
          },
          {
               type: 1,
               sub: false,
               expand: false,
               path_name: "บริษัทยา",
               url: "/Vendor",
               icon: vendor,
          },
          {
               type: 1,
               sub: false,
               expand: false,
               path_name: "สรุปเวชภัณฑ์ (แยกตามเวชภัณฑ์)",
               url: "/ReportDrug",
               icon: report,
          },
          {
               type: 1,
               sub: false,
               expand: false,
               path_name: "สรุปรายการขอเบิกจ่ายเวชภัณฑ์",
               url: "/ReportApprove",
               icon: report,
          },
          {
               type: 3,
               path_name: "Dashboard รพ. สต.",
               url: "/DashboardLocation",
               icon: dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "Dashboard อบจ.",
               url: "/DashboardPAO",
               icon: dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "Dashboard รวม",
               url: "/Dashboard",
               icon: dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "Dashboard รวม 43 แฟ้ม",
               url: "/DashboardAll43",
               icon: dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "รายงานการเงิน",
               url: "/Statement",
               icon: statement,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "กราฟการเงิน",
               url: "/ChartStatement",
               icon: dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "Dashboard OPD",
               url: "/DashboardOPD",
               icon: dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "Dashboard การเงิน",
               url: "/DashboardStatement",
               icon: dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "Dashboard การเงิน",
               url: "/DashboardStatement2",
               icon: dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "รายงานผู้เสียชีวิต",
               url: "/ReportDeath",
               icon: report_dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "รายงานการวินิจฉัยโรค",
               url: "/ReportDiagnose",
               icon: report_dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "รายงานการเงินวัคซีน Moph Claim",
               url: "/DashboardStatementVaccine",
               icon: dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "รายงานการเงินวัคซีน Krungthai Digital",
               url: "/DashboardStatementDK",
               icon: dashboard,
               sub: false,
               expand: false,
          },
          {
               type: 3,
               path_name: "ตัวชี้วัด",
               url: "/Dashboard",
               icon: dashboard,
               sub: true,
               expand: false,
               submenu: [
                    {
                         path_name: "K103",
                         url: "/Dashboard/K103",
                    },
                    {
                         path_name: "K111 Hypertension",
                         url: "/Dashboard/K111Hypertension",
                    },
                    {
                         path_name: "K111 Diabetes",
                         url: "/Dashboard/K111Diabetes",
                    },
                    {
                         path_name: "K101",
                         url: "/Dashboard/K101",
                    },
                    {
                         path_name: "K112.2",
                         url: "/Dashboard/K1122",
                    },
                    {
                         path_name: "K113.2",
                         url: "/Dashboard/K1132",
                    },
               ],
          },
          {
               type: 3,
               path_name: "แผนที่ GIS",
               url: "/Map",
               icon: dashboard,
               sub: true,
               expand: false,
               submenu: [
                    {
                         path_name: "แผนที่ประชากร",
                         url: "/Map/MapPopulation",
                    },
                    {
                         path_name: "แผนที่ประชากร (ดาวเทียม)",
                         url: "/Map/MapPopulationSatellite",
                    },
                    {
                         path_name: "แผนที่โรคระบาด",
                         url: "/Map/MapEpidemic",
                    },
                    {
                         path_name: "แผนที่โรคระบาด (ดาวเทียม)",
                         url: "/Map/MapEpidemicSatellite",
                    },
                    {
                         path_name: "แผนที่แสดงโรคเรื้อรัง",
                         url: "/Map/Chronic",
                    },
                    {
                         path_name: "แผนที่แสดงโรคเรื้อรัง (ดาวเทียม)",
                         url: "/Map/ChronicSatellite",
                    },
               ],
          },
          {
               type: 3,
               path_name: "รายงานการส่งเคลมการตรวจสอบจาก สปสช.",
               url: "/UcHsub",
               icon: dashboard,
               sub: true,
               expand: false,
               submenu: [
                    {
                         path_name: "K103",
                         url: "/UcHsub/DashboardNotPassClaim",
                    },
               ],
          },
          // {
          //     type: 2,
          //     sub: false,
          //     expand: false,
          //     path_name: "การส่งเคลม 16 แฟ้ม",
          //     url: "/Claim16",
          //     icon: verified,
          // },
          // {
          //     type: 2,
          //     sub: false,
          //     expand: false,
          //     path_name: "การส่งเคลม 43 แฟ้ม",
          //     url: "/Claim43",
          //     icon: verified,
          // },
          // {
          //     type: 2,
          //     sub: false,
          //     expand: false,
          //     path_name: "การส่งเคลมวัคซีน",
          //     url: "/ClaimVaccine",
          //     icon: verified,
          // },
          // {
          //     type: 2,
          //     sub: false,
          //     expand: false,
          //     path_name: "การส่งเคลม DM/HT",
          //     url: "/ClaimDMHT",
          //     icon: verified,
          // },
          {
               type: 2,
               sub: false,
               expand: false,
               path_name: "รหัสเคลม",
               url: "/ClaimCode",
               icon: token,
          },
          {
               type: 2,
               sub: false,
               expand: false,
               path_name: "ประกันสังคม",
               url: "/Sso",
               icon: token,
          },
          {
               type: 2,
               sub: false,
               expand: false,
               path_name: "ยาสมุนไพร",
               url: "/Herb",
               icon: medication,
          },
          {
               type: 2,
               sub: false,
               expand: false,
               path_name: "ยาคุมกำเนิด",
               url: "/ContraceptivePill",
               icon: medication,
          },
          {
               type: 2,
               sub: false,
               expand: false,
               path_name: "ยาสำหรับหญิงตั้งครรภ์",
               url: "/PregnantDrug",
               icon: medication,
          },
          {
               type: 2,
               sub: false,
               expand: false,
               path_name: "รหัส Token",
               url: "/Kiosk",
               icon: token,
          },
          {
               type: 2,
               sub: false,
               expand: false,
               path_name: "เคลมปิดสิทธิ์",
               url: "/ClaimClose",
               icon: token,
          },
          {
               type: 4,
               sub: false,
               expand: false,
               path_name: "ฐานข้อมูลประชากร",
               url: "/Person",
               icon: person,
          },
          {
               type: 4,
               sub: false,
               expand: false,
               path_name: "สิทธิการรักษา",
               url: "/INSCL",
               icon: vendor,
          },
          {
               type: 2,
               path_name: "การเคลม",
               url: "/Claim",
               icon: dashboard,
               sub: true,
               expand: false,
               submenu: [
                    {
                         path_name: "การส่งเคลม 16 แฟ้ม",
                         url: "/Claim/Claim16",
                         icon: null,
                    },
                    {
                         path_name: "การส่งเคลม 43 แฟ้ม",
                         url: "/Claim/Claim43",
                         icon: null,
                    },
                    {
                         path_name: "การส่งเคลม 13 แฟ้ม",
                         url: "/Claim/Claim13",
                         icon: null,
                    },
                    {
                         path_name: "การส่งเคลมวัคซีน",
                         url: "/Claim/ClaimVaccine",
                         icon: null,
                    },
                    {
                         path_name: "การส่งเคลม DM/HT",
                         url: "/Claim/ClaimDMHT",
                         icon: "fas fa-shield-alt",
                    },
                    {
                         path_name: "ประกันสังคม",
                         url: "/Claim/Sso",
                         icon: "fa fa-address-book",
                    },
                    {
                         path_name: "การส่งเคลมวัคซีนกรุงไทย",
                         url: "/Claim/ClaimVaccineKTB",
                         icon: null,
                    },
                    {
                         path_name: "การส่งเคลม OP/PP",
                         url: "/Claim/ClaimOPPP",
                         icon: null,
                    },
                    {
                         path_name: "การส่งเคลมปิดสิทธิ์",
                         url: "/Claim/ClaimOPPP",
                         icon: null,
                    },
                    {
                         path_name: "รายการ Drug catalog",
                         url: "/Claim/DrugCatalog",
                         icon: null,
                    },
               ],
          },
          // {
          //     type: 4,
          //     sub: false,
          //     expand: false,
          //     path_name: "ตั้งลูกหนี้",
          //     url: "/Debtor",
          //     icon: vendor,
          // },
          {
               type: 5,
               sub: true,
               expand: false,
               path_name: "บัญชีลูกหนี้",
               url: "/Finance",
               icon: user,
               submenu: [
                    {
                         type: 5,
                         sub: false,
                         expand: false,
                         path_name: "ตั้งลูกหนี้",
                         url: "/Finance/FinancialStatement",
                         icon: null,
                    },
                    {
                         type: 5,
                         sub: false,
                         expand: false,
                         path_name: "ตัดลูกหนี้",
                         url: "/Finance/FinancialDeduction",
                         icon: null,
                    },
                    {
                         type: 5,
                         sub: false,
                         expand: false,
                         path_name: "สรุป รายรับ-รายจ่าย รพสต",
                         url: "/Finance/FinancialSum",
                         icon: null,
                    },
                    {
                         type: 5,
                         sub: false,
                         expand: false,
                         path_name: "เลขบัญชี",
                         url: "/Finance/FinacialAccount",
                         icon: null,
                    },
               ],
          },
          {
               type: 6,
               sub: false,
               expand: false,
               path_name: "ผังทรัพยากรบุคคล",
               url: "/HR/HumanResources",
               icon: user,
          },
          {
               type: 6,
               sub: false,
               expand: false,
               path_name: "ข้อมูลทรัพยากรบุคคล",
               url: "/HR/HRTable",
               icon: user,
          },
          {
               type: 3,
               sub: false,
               expand: false,
               path_name: "Monitor การนำเข้าข้อมูล",
               url: "/MonitorInputData",
               icon: user,
          },
          {
               type: 3,
               sub: false,
               expand: false,
               path_name: "Monitor การนำเข้าข้อมูล",
               url: "/Monitor2",
               icon: user,
          },
          {
               type: 3,
               sub: false,
               expand: false,
               path_name: "Monitor HDC",
               url: "/MonitorHDC",
               icon: user,
          },
     ],
     menu_home: [
          {
               sub: false,
               type: 0,
               th: "จัดการผู้ใช้งาน",
               link: "/User",
               icon: user,
          },
          {
               sub: false,
               type: 0,
               th: "จัดการสิทธิ์สำหรับผู้ใช้งาน",
               link: "/Role",
               icon: role,
          },
          {
               sub: false,
               type: 0,
               th: "อุปกรณ์",
               link: "/Device",
               icon: device,
          },
          {
               sub: false,
               type: 0,
               th: "โรงพยาบาลศูนย์",
               link: "/Hospital",
               icon: hospital,
          },
          {
               sub: false,
               type: 0,
               th: "โรงพยาบาลส่วนตำบล",
               link: "/Location",
               icon: location,
          },
          {
               sub: false,
               type: 0,
               th: "สถานะการดึงข้อมูล",
               link: "/HospitalStatus",
               icon: hospital,
          },
          {
               sub: false,
               type: 0,
               th: "ข่าวสาร",
               link: "/News",
               icon: news,
          },
          {
               sub: false,
               type: 0,
               th: "จัดการประเภทเคลม",
               link: "/ManageClaim",
               icon: setting,
          },
          {
               sub: false,
               type: 0,
               th: "จัดการ url",
               link: "/ManageUrl",
               icon: setting,
          },
          {
               sub: false,
               type: 0,
               th: "จัดการ type",
               link: "/ManageType",
               icon: setting,
          },
          {
               sub: false,
               type: 0,
               th: "ตั้งค่า OTP",
               link: "/SettingsOtp",
               icon: setting,
          },
     ],
     menu_stock: [
          {
               type: 1,
               sub: false,
               th: "เวชภัณฑ์คงคลัง",
               link: "/Stock",
               icon: stock,
          },
          {
               type: 1,
               sub: false,
               th: "รายการเวชภัณฑ์",
               link: "/Drug",
               icon: drug,
          },
          {
               type: 1,
               sub: false,
               th: "เบิกเวชภัณฑ์โรงพยาบาลส่วนตำบล",
               link: "/RequisitionLocation",
               icon: transaction,
          },
          {
               type: 1,
               sub: false,
               th: "บริษัทยา",
               link: "/Vendor",
               icon: vendor,
          },
          {
               type: 1,
               sub: false,
               th: "สรุปเวชภัณฑ์ (แยกตามเวชภัณฑ์)",
               link: "/ReportDrug",
               icon: report,
          },
          {
               type: 1,
               sub: false,
               th: "สรุปรายการขอเบิกจ่ายเวชภัณฑ์",
               link: "/ReportApprove",
               icon: report,
          },
     ],
     menu_dashboard: [
          {
               type: 3,
               th: "Dashboard รพ. สต.",
               link: "/DashboardLocation",
               icon: dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "Dashboard อบจ.",
               link: "/DashboardPAO",
               icon: dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "Dashboard รวม",
               link: "/Dashboard",
               icon: dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "Dashboard รวม 43 แฟ้ม",
               link: "/DashboardAll43",
               icon: dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "รายงานการเงิน",
               link: "/Statement",
               icon: statement,
               sub: false,
          },
          {
               type: 3,
               th: "กราฟการเงิน",
               link: "/ChartStatement",
               icon: dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "Dashboard OPD",
               link: "/DashboardOPD",
               icon: dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "Dashboard การเงิน",
               link: "/DashboardStatement",
               icon: dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "Dashboard การเงิน",
               link: "/DashboardStatement2",
               icon: dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "รายงานการเงินวัคซีน Moph Claim",
               link: "/DashboardStatementVaccine",
               icon: dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "รายงานการเงินวัคซีน Krungthai Digital",
               link: "/DashboardStatementDK",
               icon: dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "รายงานผู้เสียชีวิต",
               link: "/ReportDeath",
               icon: report_dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "รายงานการวินิจฉัยโรค",
               link: "/ReportDiagnose",
               icon: report_dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "ตัวชี้วัด",
               link: "/Dashboard",
               icon: dashboard,
               sub: true,
               submenu: [
                    {
                         th: "K103",
                         link: "/Dashboard/K103",
                    },
                    {
                         th: "K111 Hypertension",
                         link: "/Dashboard/K111Hypertension",
                    },
                    {
                         th: "K111 Diabetes",
                         link: "/Dashboard/K111Diabetes",
                    },
                    {
                         th: "K101",
                         link: "/Dashboard/K101",
                    },
                    {
                         th: "K112.2",
                         link: "/Dashboard/K1122",
                    },
                    {
                         th: "K113.2",
                         link: "/Dashboard/K1132",
                    },
               ],
          },
          {
               type: 3,
               th: "รายงานการส่งเคลมไม่ผ่านการตรวจสอบจาก สปสช.",
               link: "/DashboardNotPassClaim",
               icon: dashboard,
               sub: false,
          },
          {
               type: 3,
               th: "แผนที่ GIS",
               link: "/DashboardMapGIS",
               icon: report_dashboard,
               sub: false,
          },
          // {
          //     type: 5,
          //     sub: false,
          //     expand: false,
          //     path_name: "รายรับ-รายจ่าย รพสต",
          //     url: "/Finance/FinancialStatement",
          //     icon: user,
          // },
     ],
     menu_claim: [
          {
               type: 2,
               sub: false,
               th: "การส่งเคลม 16 แฟ้ม",
               link: "/Claim16",
               icon: verified,
          },
          {
               type: 2,
               sub: false,
               th: "การส่งเคลม 43 แฟ้ม",
               link: "/Claim43",
               icon: verified,
          },
          {
               type: 2,
               sub: false,
               th: "การส่งเคลมวัคซีน",
               link: "/ClaimVaccine",
               icon: verified,
          },
          {
               type: 2,
               sub: false,
               th: "การส่งเคลม DM/HT",
               link: "/ClaimDMHT",
               icon: verified,
          },
          {
               type: 2,
               sub: false,
               th: "รหัสเคลม",
               link: "/ClaimCode",
               icon: token,
          },
          {
               type: 2,
               sub: false,
               th: "ประกันสังคม",
               link: "/Sso",
               icon: token,
          },
          {
               type: 2,
               sub: false,
               th: "ยาสมุนไพร",
               link: "/Herb",
               icon: medication,
          },
          {
               type: 2,
               sub: false,
               th: "ยาคุมกำเนิด",
               link: "/ContraceptivePill",
               icon: medication,
          },
          {
               type: 2,
               sub: false,
               th: "ยาสำหรับหญิงตั้งครรภ์",
               link: "/PregnantDrug",
               icon: medication,
          },
          {
               type: 2,
               sub: false,
               th: "รหัส Token",
               link: "/Kiosk",
               icon: token,
          },
          {
               type: 2,
               sub: false,
               th: "เคลมปิดสิทธิ์",
               link: "/ClaimClose",
               icon: token,
          },
     ],
     menu_database: [
          {
               type: 4,
               sub: false,
               th: "ฐานข้อมูลประชากร",
               link: "/Person",
               icon: person,
          },
          {
               type: 4,
               sub: false,
               th: "สิทธิการรักษา",
               link: "/INSCL",
               icon: vendor,
          },
     ],
     menu_hr: [
          {
               type: 6,
               sub: false,
               expand: false,
               path_name: "ผังทรัพยากรบุคคล",
               url: "/HR/HumanResources",
               icon: user,
          },
          {
               type: 6,
               sub: false,
               expand: false,
               path_name: "ข้อมูลทรพยากรบุคคล",
               url: "/HR/HRTable",
               icon: user,
          },
     ],
};

export const select_data = {
     list_type: [
          {
               label: "เลือกประเภทเวชภัณฑ์ยา",
               value: "",
               disabled: true,
          },
          {
               label: "เวชภัณฑ์ยา",
               value: "เวชภัณฑ์ยา",
          },
          {
               label: "เวชภัณฑ์ไม่ใช่ยา",
               value: "เวชภัณฑ์ไม่ใช่ยา",
          },
          {
               label: "สมุนไพร",
               value: "สมุนไพร",
          },
          {
               label: "อื่นๆ",
               value: "อื่นๆ",
          },
     ],
     list_vendor: [
          {
               label: "เลือกบริษัทยา",
               value: "",
               disabled: true,
          },
          {
               label: "โรงพยาบาลศูนย์",
               value: "Hospital",
          },
          {
               label: "โรงพยาบาลส่วนตำบล",
               value: "location",
          },
     ],
     list_lacation: [
          {
               label: "เลือกสถานพยาบาล",
               value: "",
               disabled: true,
          },
          {
               label: "Lacation A",
               value: "LacationA",
          },
          {
               label: "Lacation B",
               value: "LacationB",
          },
          {
               label: "Lacation C",
               value: "LacationC",
          },
          {
               label: "Lacation D",
               value: "LacationD",
          },
     ],
     lacation: [
          {
               label: "เลือกสถานพยาบาล",
               value: "",
               disabled: true,
          },
          {
               label: "โรงพยาบาล",
               value: "1",
          },
     ],
     hospital: [
          {
               label: "เลือกสถานพยาบาล",
               value: "",
               disabled: true,
          },
          {
               label: "โรงพยาบาล",
               value: "1",
          },
     ],
     status_hosp: [
          {
               label: "ดึงข้อมูลไม่สำเร็จ",
               value: 0,
          },
          {
               label: "ดึงข้อมูลสำเร็จ",
               value: 1,
          },
          {
               label: "ทั้งหมด",
               value: 99,
          },
     ],
     sex: [
          {
               label: "ทั้งหมด",
               value: "ทั้งหมด",
          },
          {
               label: "ชาย",
               value: "ชาย",
          },
          {
               label: "หญิง",
               value: "หญิง",
          },
          {
               label: "ไม่ระบุ",
               value: "ไม่ระบุ",
          },
     ],
     isPin: [
          {
               label: "ทั้งหมด",
               value: "ทั้งหมด",
          },
          {
               label: "ปักหมุด",
               value: true,
          },
          {
               label: "ไม่ปักหมุด",
               value: false,
          },
     ],
};

export const transactionStatus = {
     1: { label: "รอดำเนินการ", color: "var(--yellow)" },
     2: { label: "ยอมรับ", color: "var(--dark-teal)" },
     3: { label: "ยกเลิก", color: "var(--red)" },
};

export const roleStatus = {
     0: { label: "ไม่ใช้งาน", color: "var(--red)" },
     1: { label: "ใช้งาน", color: "var(--dark-teal)" },
     // 3: { label: 'ยังไม่ระบุ', color: "var(--yellow)" }
};

export const deviceStatus = {
     0: { label: "รออนุมัติ", color: "var(--yellow)" },
     1: { label: "อนุมัติ", color: "var(--dark-teal)" },
     2: { label: "ปฏิเสธ", color: "var(--red)" },
};

export const hospitalStatus = {
     0: { label: "ไม่สำเร็จ", color: "var(--red)" },
     1: { label: "สำเร็จ", color: "var(--dark-teal)" },
     // 2: { label: 'ปฏิเสธ', color: "var(--red)" }
};

export const hospitalStatusPrev = {
     0: { label: "ไม่สำเร็จ", color: "var(--red)" },
     1: { label: "สำเร็จ", color: "var(--dark-teal)" },
     // 2: { label: 'ปฏิเสธ', color: "var(--red)" }
};

export const DXTYPE_TYPE = {
     1: { label: "Primary Diagnosis" },
     2: { label: "โรคร่วม (Co morbidity)" },
     3: { label: "โรคแทรกซ้อน (Complication)" },
     4: { label: "อื่นๆ (Others)" },
     5: { label: "สาเหตุภายนอก (External Cause)" },
};

// export const level_chart = {
//      1: { label: "5000000", color: "#1a237e" },
//      2: { label: "999999", color: "#0d47a1" },
//      3: { label: "99999", color: "#8bc34a" },
//      4: { label: "9999", color: "#b71c1c" },
//      5: { label: "1", color: "#f44336" },
//      6: { label: "0", color: "#000" },
// };
