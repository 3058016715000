import { Clear, Map } from "@mui/icons-material";
import { Autocomplete, Box, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { headerTable } from "../../../untils/static";
import GridTable from "./GridTable";
import MapChart from "./MapChat";

function GridCard({
    profile,
    loading,
    data,
    setData,
    dataTbl,
    dataLocation,
    setProLocationName,
    selectChronic,
    setChronic,
    chronic,
    setHcode,
    proLocationName,
    subdistrictObj,
    adl_type,
    set_Select_adl_type,
    select_adl_type,
    selectView,
    setSelectView,
    getDataMapZoom,
    totalChronic,
}) {
    const [cleared, setCleared] = useState(false);
    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
    }, [cleared]);

    return (
        <Container maxWidth="xxl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={5}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <Map fontSize="medium" sx={{ color: "#00695C" }} />
                                <Typography className="main-header">แผนที่แสดงจำนวนผู้ป่วยโรคเรื้อรัง</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container columns={12} spacing={3} className="flex-start">
                                <Grid item xs={12} sm={12} md={4}>
                                    <Autocomplete
                                        disabled={profile.role_id !== 1 && profile.role_id !== 2}
                                        getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                                        options={dataLocation}
                                        onChange={(event, value) => {
                                            setProLocationName(value);
                                            if (!value) {
                                                return;
                                            }
                                            if (value) {
                                                setHcode(value.hcode);
                                            }
                                        }}
                                        value={proLocationName}
                                        noOptionsText="No data"
                                        renderInput={(params) => <TextField {...params} label="สถานพยาบาล" InputLabelProps={{ shrink: true }} />}
                                        clearOnEscape
                                        autoHighlight
                                        clearIcon={<Clear />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="adl-type-select">
                                            กลุ่มโรคเรื้อรัง
                                        </InputLabel>
                                        <Select
                                            value={select_adl_type}
                                            onChange={(event) => {
                                                set_Select_adl_type(event.target.value);
                                            }}
                                            label="กลุ่มโรคเรื้อรัง"
                                            inputProps={{
                                                name: "adl-type",
                                                id: "adl-type-select",
                                            }}
                                        >
                                            <MenuItem value="ทั้งหมด">ทั้งหมด</MenuItem>
                                            {Array.isArray(adl_type) && adl_type.length > 0 ? (
                                                adl_type.map((type, index) => (
                                                    <MenuItem key={index} value={type}>
                                                        {type}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>No data</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {(profile.role_id === 3 || profile.role_id === 4) && (
                                    <Grid item xs={12} sm={12} md={2}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="selectView">
                                                เลือกข้อมูลที่แสดง
                                            </InputLabel>
                                            <Select
                                                value={selectView}
                                                onChange={(event) => {
                                                    setSelectView(event.target.value);
                                                }}
                                                label="เลือกข้อมูลที่แสดง"
                                                inputProps={{
                                                    name: "view",
                                                    id: "selectView",
                                                }}
                                            >
                                                <MenuItem value="ตาราง">ตาราง</MenuItem>
                                                <MenuItem value="แผนที่">แผนที่</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {selectView === "ตาราง" && (
                            <Grid item xs={12}>
                                <GridTable
                                    header={headerTable.Chronic}
                                    ChronicDetail={headerTable.ChronicDetail}
                                    data={dataTbl}
                                    selectChronic={selectChronic}
                                    setChronic={setChronic}
                                    chronic={chronic}
                                />
                            </Grid>
                        )}
                        {selectView === "แผนที่" && (
                            <Grid item xs={12} className="flex-start" justifyContent="center">
                                {!loading ? (
                                    <MapChart
                                        data={data}
                                        subdistrictObj={subdistrictObj}
                                        selectChronic={selectChronic}
                                        setChronic={setChronic}
                                        chronic={chronic}
                                        getDataMapZoom={getDataMapZoom}
                                        setData={setData}
                                        totalChronic={totalChronic}
                                    />
                                ) : (
                                    <Box sx={{ display: "flex" }}>
                                        <CircularProgress />
                                    </Box>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

export default GridCard;
