import React from 'react'
import FinancialOutstandingTable from './Financial_OutstandingTable'
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Typography } from '@mui/material'
import PaymentsIcon from '@mui/icons-material/Payments';

function Financial_OutstandingList({ headersso, headerinData, status, setStatus, data, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, cancelData }) {

    return (
        <>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12}>
                        <Grid item xs={12} mt={1} mb={3} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <PaymentsIcon sx={{ color: "var(--teal-A800)" }} fontSize="medium" />
                                <Typography
                                    className="main-header"
                                >
                                    ลูกหนี้คงเหลือ
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <Grid container columns={12} spacing={1} mb={2} className="flex-start">
                            <Grid item xs={12} sm={12} md={2}>
                                <FormControl fullWidth>
                                    <InputLabel>เลือกสถานะ</InputLabel>
                                    <Select
                                        name=""
                                        value={status}
                                        label="เลือกสถานะ"
                                        required
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <MenuItem value="5">ลูกหนี้คงเหลือ</MenuItem>
                                        <MenuItem value="3">ยกเลิกลูกหนี้คงเหลือ</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mb={2}>
                        <FinancialOutstandingTable
                            headersso={headersso()}
                            headerinData={headerinData()}
                            data={data}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            status={status}
                            onCancel={cancelData}
                        />
                    </Grid>
                </Paper>
            </Box>
        </>
    )
}

export default Financial_OutstandingList