import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";

import { StyledTblK103 } from "../../../Theme";
import { tofieds } from "../../../untils/shortcut";

export default function GridTable({ dataByQ, headerByQ = {} }) {
    // console.log("total:", total);
    // console.log("dataByQ:", dataByQ);
    // console.log("headerByQ:", headerByQ);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: "100%" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTblK103 rowSpan={2} style={{ minWidth: 400, textAlign: "center" }}>
                                {headerByQ.display_name}
                            </StyledTblK103>
                            <StyledTblK103 colSpan={3} style={{ minWidth: 100, textAlign: "center" }}>
                                {headerByQ.datas?.header}
                            </StyledTblK103>
                        </TableRow>
                        <TableRow>
                            <StyledTblK103 style={{ minWidth: 100, textAlign: "center" }}>{headerByQ.datas?.B}</StyledTblK103>
                            <StyledTblK103 style={{ minWidth: 100, textAlign: "center" }}>{headerByQ.datas?.A}</StyledTblK103>
                            <StyledTblK103 style={{ minWidth: 100, textAlign: "center" }}>{headerByQ.datas?.percent}</StyledTblK103>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataByQ.length > 0 ? (
                            <>
                                {/* แสดงข้อมูลที่เหลือ (ไม่รวมแถวสุดท้าย) */}
                                {dataByQ
                                    .filter((item) => item.display_name !== "รวม")
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage - 1)
                                    .map((item, index) => {
                                        const isLastItemInPage = index === rowsPerPage; // เงื่อนไขสำหรับแถวสุดท้ายก่อนแถวรวม
                                        return (
                                            <TableRow
                                                style={{
                                                    backgroundColor: isLastItemInPage ? "#eeeeee" : "#FAFAFA",
                                                }}
                                            >
                                                <StyledTblK103 align="left">{item.display_name}</StyledTblK103>
                                                <StyledTblK103 style={{ minWidth: 100, textAlign: "right" }}>
                                                    {tofieds(item.datas.B).replace(/\.00$/, "")}
                                                </StyledTblK103>
                                                <StyledTblK103 style={{ minWidth: 100, textAlign: "right" }}>
                                                    {tofieds(item.datas.A).replace(/\.00$/, "")}
                                                </StyledTblK103>
                                                <StyledTblK103 style={{ minWidth: 100, textAlign: "right" }}>{item.datas.percent}</StyledTblK103>
                                            </TableRow>
                                        );
                                    })}

                                {/* แสดงแถวสุดท้าย (แถวรวม) อยู่ด้านล่างสุดเสมอ */}
                                <TableRow
                                    style={{
                                        backgroundColor: "#eeeeee", // สีพื้นหลังสำหรับแถวรวม
                                    }}
                                >
                                    <StyledTblK103 align="left">{dataByQ[dataByQ.length - 1].display_name}</StyledTblK103>
                                    <StyledTblK103 style={{ minWidth: 100, textAlign: "right" }}>
                                        {tofieds(dataByQ[dataByQ.length - 1].datas.B).replace(/\.00$/, "")}
                                    </StyledTblK103>
                                    <StyledTblK103 style={{ minWidth: 100, textAlign: "right" }}>
                                        {tofieds(dataByQ[dataByQ.length - 1].datas.A).replace(/\.00$/, "")}
                                    </StyledTblK103>
                                    <StyledTblK103 style={{ minWidth: 100, textAlign: "right" }}>
                                        {dataByQ[dataByQ.length - 1].datas.percent}
                                    </StyledTblK103>
                                </TableRow>
                            </>
                        ) : null}
                    </TableBody>
                </Table>
                {dataByQ.length === 0 ? (
                    <Grid container justifyContent="center" my={3}>
                        <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                    </Grid>
                ) : (
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={dataByQ.length - 1}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                    />
                )}
            </TableContainer>
        </Paper>
    );
}
