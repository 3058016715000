import React from "react";
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import FinancialDeductionTable from "./Financial_DeductionTable";
import { headerTable } from "../../../untils/static";
import DialogAdd from "./DialogAdd";
import DialogEdit from "./DialogEdit";

function Financial_DeductionList({
     data,
     status,
     setStatus,
     page,
     rowsPerPage,
     handleChangePage,
     handleChangeRowsPerPage,
     newDataAdd,
     newDataEdit,
     handleOpenDialogAdd,
     handleOpenDialogEdit,
     handleCloseDialogAdd,
     handleCloseDialogEdit,
     handleSaveAdd,
     handleSaveEdit,
     handleCheck,
     openDialogAdd,
     openDialogEdit,
     handleInputChangeAdd,
     handleInputChangeEdit,
     search,
     setSearch,
}) {
     const combinedData = [...(data || [])];
     const uniqueData = Array.from(new Set(combinedData.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item));

     const headersso = () => {
          //   console.log("status", status);
          if (status === "2") {
               return headerTable.FinancialDeductionheader2 || [];
          } else {
               return headerTable.FinancialDeductionheader1 || [];
          }
     };

     return (
          <>
               <Box display="flex" justifyContent="center">
                    <Paper className="paper-bg" elevation={0}>
                         <Grid container columns={12}>
                              <Grid item xs={12} mt={1} mb={3} className="flex-start">
                                   <Stack direction="row" spacing={2}>
                                        <CreditCardOffIcon sx={{ color: "var(--teal-A800)" }} fontSize="medium" />
                                        <Typography className="main-header">ตัดลูกหนี้</Typography>
                                   </Stack>
                              </Grid>
                         </Grid>
                         <Grid item xs={12} sm={12} md={12} my={2}>
                              <Grid container columns={12} spacing={1} mb={2} className="flex-start">
                                   <Grid item xs={12} sm={12} md={2}>
                                        <FormControl fullWidth>
                                             <InputLabel>เลือกสถานะ</InputLabel>
                                             <Select name="" value={status} label="เลือกสถานะ" required onChange={(e) => setStatus(e.target.value)}>
                                                  <MenuItem value="1">รอตัดลูกหนี้</MenuItem>
                                                  <MenuItem value="2">ตัดลูกหนี้สำเร็จ</MenuItem>
                                             </Select>
                                        </FormControl>
                                   </Grid>
                                   <Grid item xs={12} sm={12} md={10} my={2}>
                                        <TextField
                                             fullWidth
                                             id="search"
                                             label="ค้นหารายการ"
                                             type="search"
                                             variant="outlined"
                                             size="medium"
                                             onChange={(e) => setSearch(e.target.value)}
                                             value={search}
                                        />
                                   </Grid>
                              </Grid>
                         </Grid>
                         <Grid item xs={12} sm={12} md={12} mb={2}>
                              <FinancialDeductionTable
                                   headersso={headersso()}
                                   // sortedData={combinedData}
                                   sortedData={uniqueData}
                                   rowsPerPage={rowsPerPage}
                                   page={page}
                                   handleChangePage={handleChangePage}
                                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                                   status={status}
                                   onAdd={handleOpenDialogAdd}
                                   onEdit={handleOpenDialogEdit}
                                   onCheck={handleCheck}
                              />
                              <DialogAdd
                                   openDialogAdd={openDialogAdd}
                                   setStatus={setStatus}
                                   handleCloseDialogAdd={handleCloseDialogAdd}
                                   newDataAdd={newDataAdd}
                                   handleInputChangeAdd={handleInputChangeAdd}
                                   handleSaveAdd={handleSaveAdd}
                              />
                              <DialogEdit
                                   openDialogEdit={openDialogEdit}
                                   setStatus={setStatus}
                                   handleCloseDialogEdit={handleCloseDialogEdit}
                                   newDataEdit={newDataEdit}
                                   handleInputChangeEdit={handleInputChangeEdit}
                                   handleSaveEdit={handleSaveEdit}
                              />
                         </Grid>
                    </Paper>
               </Box>
          </>
     );
}

export default Financial_DeductionList;
