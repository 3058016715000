import React, { useState } from "react";
import { Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import { DATAPINCHRONIC, POST_GIS } from "../../../service";
import Swal from "sweetalert2";

dayjs.extend(buddhistEra);
dayjs.locale("th");

const setIcon = (e) =>
    L.icon({
        iconUrl: require(`../../../assets/images/location-pin (${e}).png`),
        iconSize: [40, 40],
        iconAnchor: [19, 39],
        popupAnchor: [1, -34],
        shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
        shadowSize: [60, 40],
    });

export default function Markerwhatever(props) {
    const [loading, setLoading] = useState(false);
    const [dataPin, setDataPin] = useState([]);
    const getDataPin = async (marker) => {
        try {
            const res = await POST_GIS(DATAPINCHRONIC, {
                title: marker.title,
                position: marker.position,
                chronic: marker.chronic_id,
            });
            if (res.success) {
                setDataPin(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "warning",
                text: error,
                icon: "warning",
                showConfirmButton: true,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <MarkerClusterGroup>
            {props.filteredMarkers.map((marker, index) => {
                let icons = setIcon(marker.chronic_id[0]?.id);
                return (
                    <Marker
                        key={index}
                        position={marker.position}
                        icon={icons}
                        eventHandlers={{
                            click: async () => {
                                setDataPin([]);
                                await getDataPin(marker);
                            },
                        }}
                    >
                        {!dataPin.length ? (
                            <Popup>
                                <div>กำลังโหลดข้อมูล...</div>
                            </Popup>
                        ) : (
                            dataPin.length > 0 &&
                            dataPin.map((item) => {
                                return (
                                    <Popup key={item.id}>
                                        <div>
                                            <strong>{item.title}</strong>
                                            <br />
                                            <strong>อายุ {item.age} ปี</strong>
                                            <br />
                                            <strong>เพศ {item.sex} </strong>
                                            <br />
                                            <strong>โรค {item.chronic} </strong>
                                            <br />
                                        </div>
                                    </Popup>
                                );
                            })
                        )}
                    </Marker>
                );
            })}
        </MarkerClusterGroup>
    );
}
