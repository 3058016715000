import React, { useEffect, useMemo, useState } from "react";

import { Container, debounce } from "@mui/material";

import dayjs from "dayjs";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { GENCSVHOSPITALSTATUS, GETHOSPITALSTATUS, POST, ip } from "../../service";
import ListHospital from "./ListHospital/ListHospital";
import axios from "axios";

function HospitalStatus() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [dateStart, setDateStart] = useState(dayjs());
    const [status, setStatus] = useState(99);
    const token = localStorage.getItem("uuid");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getHospitalStatus = async () => {
        try {
            setLoading(true);
            let res = await POST(GETHOSPITALSTATUS, { search, date: dateStart, status });
            if (res.success) {
                setData(res.result);
                setPage(0);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoading(false);
                }
            });
        }
    };

    // const fetch = useMemo(
    //     () =>
    //         debounce(async (request) => {
    //             try {
    //                 // setLoading(true);
    //                 const payload = {
    //                     search: request,
    //                     date: dateStart,
    //                     status,
    //                 };
    //                 let res = await POST(GETHOSPITALSTATUS, payload);
    //                 if (res.success) {
    //                     setData(res.result);
    //                     setLoading(false);
    //                 } else {
    //                     setLoading(false);
    //                     Swal.fire({
    //                         icon: "warning",
    //                         text: `${res.message}`,
    //                         showConfirmButton: true,
    //                     });
    //                 }
    //             } catch (error) {
    //                 setLoading(false);
    //                 Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
    //             }
    //         }, 300),
    //     []
    // );

    const genExcel = async () => {
        setLoading(true);
        try {
            const res = await axios.post(
                ip + GENCSVHOSPITALSTATUS,
                { search, date: dateStart, status },
                {
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token || "token",
                    },
                }
            );
            const excelBlob = new Blob([res.data], { type: "text/csv; charset=UTF-8" });
            const fileURL = URL.createObjectURL(excelBlob);
            const a = document.createElement("a");
            a.href = fileURL;
            a.download = "สถานะการดึงข้อมูล.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(fileURL);
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: "ไม่สามารถสร้างไฟล์ CSV ได้",
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     if (search.length < 2) {
    //         getHospitalStatus();
    //         return;
    //     } else if (!search) {
    //         getHospitalStatus();
    //         return;
    //     }

    //     fetch(search);
    // }, [search, fetch]);

    useEffect(() => {
        if (dateStart) {
            // console.log({dateFilter,row});
            setData((prev) => prev.filter((e) => dayjs(e.date).isSame(dayjs(dateStart, "day"))));
        }
    }, [dateStart]);

    useEffect(() => {
        getHospitalStatus();
    }, [search, dateStart, status]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ListHospital
                data={data}
                setData={setData}
                search={search}
                setSearch={setSearch}
                dateStart={dateStart}
                setDateStart={setDateStart}
                status={status}
                setStatus={setStatus}
                genExcel={genExcel}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Container>
    );
}

export default HospitalStatus;
