import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import { getToken } from "../../../untils/shortcut.js";
import MarkerJS from "./Marker.js";

const center = window.location.origin.includes("kk")
    ? [16.43192755724492, 102.82856609781523]
    : window.location.origin.includes("mdh")
    ? [16.59738946623057, 104.51104253757008]
    : window.location.origin.includes("spb")
    ? [14.651579065119135, 99.93989999520554]
    : [16.43192755724492, 102.82856609781523];

function MapChart({ data, setData, subdistrictObj, selectChronic, setChronic, chronic, getDataMapZoom, totalChronic }) {
    // console.log("data:", data);
    // console.log("selectChronic:", selectChronic);
    const [map, setMap] = useState(null);
    const [showGeoJSON, setShowGeoJSON] = useState(true);
    const [showGeoJSON2, setShowGeoJSON2] = useState(true);
    const [showMarker, setShowMarker] = useState(false);
    const [onselect, setOnselect] = useState({});
    const profile = getToken(localStorage.getItem("profile"));
    const timer = useRef();
    const tamCode = useRef();

    const popupFeature = (e) => {
        let layer = e.target;
        const { tam_th } = e.target.feature.properties;
        const { dataChronic, tam_code } = e.target.feature.properties;

        if (tam_code) {
            tamCode.current = tam_code;
        }

        if (dataChronic && dataChronic.length > 0) {
            const combinedHname = dataChronic.map((person) => person.hname).join(", ");
            const totalStatement = dataChronic.reduce((sum, person) => sum + parseInt(person.count, 10), 0);

            setOnselect({
                Tumbon: tam_th,
                Statement: totalStatement,
                Hname: combinedHname,
            });

            layer.setStyle({
                weight: 5,
                color: "#666",
                dashArray: "",
                fillOpacity: 0.7,
            });
            layer.bringToFront();
        }

        if (dataChronic.length > 0) {
            const popupContent = dataChronic
                .map(
                    (person) =>
                        `<b>${person.hname} : จำนวนผู้ป่วย ${
                            (person.count_chronic_dm ? person.count_chronic_dm : 0) + (person.count_chronic_ht ? person.count_chronic_ht : 0)
                        }</b>`
                )
                .join("<br>");

            const popupOptions = {
                minWidth: 100,
                maxWidth: 250,
                className: "popup-classname",
            };

            layer.bindTooltip(popupContent, popupOptions).openTooltip();
        }

        const originalStyle = {
            weight: layer.options.weight,
            color: layer.options.color,
            dashArray: layer.options.dashArray,
            fillOpacity: layer.options.fillOpacity,
        };

        layer.options.originalStyle = originalStyle;

        layer.setStyle({
            weight: 3,
            color: "#f00",
            dashArray: "",
            fillOpacity: 0.7,
        });
        layer.bringToFront();
    };

    const popupFeatureOut = (e) => {
        let layer = e.target;
        const originalStyle = layer.options.originalStyle;

        if (originalStyle) {
            layer.setStyle(originalStyle);
        }
        layer.bringToBack();
    };

    const onEachFeature = (feature, layer) => {
        layer.on({
            mouseover: popupFeature,
            mouseout: popupFeatureOut,
        });
    };

    const mapPolygonColorToDensity = (d) => {
        if (d > 10000) return "#800026";
        if (d > 5000) return "#BD0026";
        if (d > 2000) return "#E31A1C";
        if (d > 1000) return "#FC4E2A";
        if (d > 500) return "#FD8D3C";
        if (d > 200) return "#FEB24C";
        if (d > 100) return "#FED976";
        if (d >= 1) return "#FFEDA0";
        return "#000";
    };

    const style = (feature) => {
        let count = feature.properties.dataChronic;

        // ใช้ reduce ในการนับจำนวนผู้ป่วยโรคเรื้อรังทั้งหมด
        let totalChronic =
            count.length > 0
                ? count.reduce((sum, totalCount) => {
                      return sum + (totalCount?.count_chronic_dm || 0) + (totalCount?.count_chronic_ht || 0);
                  }, 0)
                : 0;

        return {
            fillColor: mapPolygonColorToDensity(totalChronic),
            weight: 1,
            opacity: 1,
            color: "white",
            dashArray: "2",
            fillOpacity: 0.5,
            transition: "5s ease-in-out",
            className: "marker marker-fade-in",
        };
    };

    const handleChange = (event) => {
        const { value, checked } = event.target;
        const parsedValue = JSON.parse(value);

        setChronic((prev) => {
            if (checked) {
                return [...prev, parsedValue];
            } else {
                return prev.filter((item) => item.id !== parsedValue.id);
            }
        });
    };

    // const handleChange = (event) => {
    //   const { value, checked } = event.target;
    //   setChronic((prev) =>
    //     checked ? [...prev, value] : prev.filter((e) => e !== value)
    //   );
    // };

    const filteredMarkers = data;
    // const position = data.map((location) => {
    //     return location.position ? location.position : null;
    // });

    const currentAPI = useRef();

    useEffect(() => {
        if (map) {
            timer.current = setTimeout(() => {
                setShowGeoJSON(true);
                setShowGeoJSON2(true);
                map.on("moveend", () => {
                    const currentZoom = map.getZoom();
                    const currentCenter = map.getCenter();
                    const mapBounds = map.getBounds();
                    // console.log("currentZoom:", currentZoom);
                    // console.log("currentCenter:", currentCenter);
                    // console.log("mapBounds:", mapBounds);

                    if (profile.role_id !== 1 && profile.role_id !== 2) {
                        if (currentZoom <= 13) {
                            // setData([]);
                            setShowGeoJSON(true);
                            setShowGeoJSON2(true);
                            setShowMarker(false);
                        }
                        if (currentZoom === 14 && currentAPI.current !== `${currentCenter.lat},${currentCenter.lng}`) {
                            getDataMapZoom([currentAPI.current, mapBounds, currentCenter]);
                            // const visibleData = await fetch(bounds);
                            // setData(visibleData);
                            setData(data);
                            setShowGeoJSON(false);
                            setShowGeoJSON2(false);
                            setShowMarker(true);
                        }
                    }
                    if (currentZoom <= 13) {
                        // setData([]);
                        setShowGeoJSON(true);
                        setShowGeoJSON2(true);
                        setShowMarker(false);
                    }
                    if (currentZoom === 14 && currentAPI.current !== `${currentCenter.lat},${currentCenter.lng}`) {
                        currentAPI.current = tamCode.current;
                        // setTamboncode(currentAPI.current);
                        getDataMapZoom([currentAPI.current, mapBounds, currentCenter]);
                        // const visibleData = await fetch(bounds);
                        // setData(visibleData);
                        setData(data);
                        setShowGeoJSON(false);
                        setShowGeoJSON2(false);
                        setShowMarker(true);
                    }
                });
            }, 300);
        }

        return () => {
            clearTimeout(timer.current);
        };
    }, [map]);

    return (
        <>
            <Grid container columns={12}>
                <Box className="box-info" sx={{ background: "#333333" }} width="100%">
                    <Stack spacing={1} className="flex-center" width="100%">
                        <Typography style={{ margin: 5 }}>แผนที่แสดงจำนวนผู้ป่วยโรคเรื้อรังแบบมาตรฐาน</Typography>
                        {/* {totalChronic ? (
              <Typography>
                จำนวนผู้ป่วยทั้งหมด: {parseInt(totalChronic).toLocaleString()}{" "}
                คน
              </Typography>
            ) : (
              <Typography>ไม่มีข้อมูล</Typography>
            )} */}
                    </Stack>
                </Box>
                <Grid item xs={12} className={showGeoJSON2 ? "n" : ""}>
                    <MapContainer scrollWheelZoom={true} center={center} zoom={9} className="mapContainer" ref={setMap} attributionControl={false}>
                        <TileLayer
                            // ตรวจสอบสถานะ satelliteView
                            maxZoom={22}
                            minZoom={8}
                            maxNativeZoom={19}
                            url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                        />
                        {showGeoJSON && (
                            <GeoJSON
                                data={subdistrictObj}
                                style={(e) => ({
                                    ...style(e),
                                })}
                                onEachFeature={onEachFeature}
                            />
                        )}

                        {showMarker && <MarkerJS filteredMarkers={filteredMarkers} />}
                        {/* <MarkerJS filteredMarkers={filteredMarkers} /> */}
                    </MapContainer>
                </Grid>
                {selectChronic.length > 0 &&
                    selectChronic.map((e) => {
                        return (
                            <Grid item xs={6} md={4} lg={3} xl={2} key={e.id}>
                                <Typography>
                                    <Checkbox
                                        sx={{ padding: "2px 9px 9px 9px" }}
                                        defaultChecked={chronic.some((item) => item.id === e.id)}
                                        checked={chronic.some((item) => item.id === e.id)}
                                        value={JSON.stringify(e)}
                                        onChange={(event) => handleChange(event)}
                                        color="success"
                                    />
                                    <label>
                                        <img
                                            style={{ width: "20px", height: "20px" }}
                                            src={require(`../../../assets/images/location-pin (${e.id}).png`)}
                                            alt="ไม่มีรูป"
                                        />
                                        {e.name}
                                    </label>
                                </Typography>
                            </Grid>
                        );
                    })}
            </Grid>
        </>
    );
}

export default MapChart;
