import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { CloseIcn } from "../../../untils/icons";
import { ButtonTeal } from '../../../Theme';

function DialogEdit({
    openDialogEdit,
    handleCloseDialogEdit,
    newDataEdit,
    handleInputChangeEdit,
    handleSaveEdit,
}) {
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Dialog open={openDialogEdit} onClose={handleCloseDialogEdit}>
                <Box className="bg-gradiant">
                    <DialogActions className="flex-end">
                        <CloseIcn className="close-icn" onClick={handleCloseDialogEdit} />
                    </DialogActions>
                    <DialogTitle className="text-header">เพิ่มข้อมูล</DialogTitle>
                    <DialogContent className="flex-center">
                        <DialogContentText mt={2} className="container-bg" component={"span"}>
                            <Grid container columns={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="เลขบัญชี"
                                        name="ref_doc"
                                        fullWidth
                                        value={newDataEdit.ref_doc || ""}
                                        disabled={newDataEdit.ref_doc ? true : false}
                                        onChange={handleInputChangeEdit}
                                        />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="เอกสารอ้างอิง"
                                        name="documentName"
                                        fullWidth
                                        value={newDataEdit.rep || ""}
                                        disabled={newDataEdit.rep ? true : false}
                                        onChange={handleInputChangeEdit}
                                        />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="ชื่อบัญชี"
                                        name="account_name"
                                        fullWidth
                                        value={newDataEdit.account_name}
                                        disabled={newDataEdit.account_name ? true : false}
                                        onChange={handleInputChangeEdit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="เดบิต"
                                        name="debit"
                                        fullWidth
                                        value={newDataEdit.debit}
                                        onChange={handleInputChangeEdit}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="เครดิต"
                                        name="credit"
                                        fullWidth
                                        value={newDataEdit.credit}
                                        onChange={handleInputChangeEdit}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="flex-center">
                        <ButtonTeal disabled={loading} sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }} onClick={handleSaveEdit}>
                            {loading ? "กำลังบันทึก..." : "บันทึกข้อมูล"}
                        </ButtonTeal>
                    </DialogActions>
                </Box>
            </Dialog >
        </>
    )
}

export default DialogEdit