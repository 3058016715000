import React, { useEffect, useState } from 'react'
import FinancialOutstandingList from './components/Financial_OutstandingList'
import { CANCELDATAOUTSTANDING, GETDATAOUTSTANDING, GETDATECANCELOUTSTANDING, POST } from '../../service';
import Swal from 'sweetalert2';
import { headerTable } from '../../untils/static';

function Financial_Outstanding() {

    const [data, setData] = useState([]);
    const [status, setStatus] = useState('5');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const getData = async () => {
        try {
            setLoading(true);
            const payload = { status: status };

            let res;

            if (status === '5') {
                res = await POST(GETDATAOUTSTANDING, payload);
            } else if (status === '3') {
                res = await POST(GETDATECANCELOUTSTANDING, payload);
            }

            if (res && res.success) {
                setData(res.data);
                setPage(0);
            } else {
                Swal.fire({ icon: "warning", text: `${res.message}`, showConfirmButton: true });
            }
        } catch (error) {
            Swal.fire({ icon: "warning", text: `${error}`, confirmButtonText: "ตกลง" });
        } finally {
            setLoading(false);
        }
    };

    const cancelData = async (item, datacredits, datadebits) => {
        setLoading(true);
        try {
            const result = await Swal.fire({
                icon: "info",
                title: "ยืนยันการยกเลิก",
                text: "ต้องการยกเลิกข้อมูลใช่หรือไม่",
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showConfirmButton: true,
                showCancelButton: true
            });

            if (result.isConfirmed) {
                const datacreditsWithRefDoc = datacredits.map(data => ({
                    ...data,
                    ref_doc: item.ref_doc
                }));

                const datadebitsWithRefDoc = datadebits.map(data => ({
                    ...data,
                    ref_doc: item.ref_doc
                }));

                const alldata = [datacreditsWithRefDoc, datadebitsWithRefDoc].flat();

                const payload = {
                    alldata,
                    documentName: item.documentName,
                    ref_no: item.ref_no,
                    ref_doc: item.ref_doc,
                    status: 3
                };

                // let res
                let res = await POST(CANCELDATAOUTSTANDING, payload);

                if (res && res.success) {
                    Swal.fire({
                        icon: "success",
                        text: "ยกเลิกข้อมูลสำเร็จ",
                        showConfirmButton: true,
                    });
                    getData();
                    setPage(0);
                } else {
                    Swal.fire({
                        icon: "warning",
                        text: res.message || "เกิดข้อผิดพลาด",
                        confirmButtonText: "ตกลง"
                    });
                }
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                text: `เกิดข้อผิดพลาด: ${error.message || error}`,
                confirmButtonText: "ตกลง"
            });
        } finally {
            setLoading(false);
        }
    };

    const headersso = () => {
        if (status === "3") {
            return headerTable.FinancialOutstandingheader2 || [];
        }
        return headerTable.FinancialOutstandingheader1 || [];
    };

    const headerinData = () => {
        return headerTable.FinancialOutstandingheaderinData || [];
    };

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getData();
        setPage(0);
    }, [status]);

    return (
        <>
            <FinancialOutstandingList
                headersso={headersso}
                headerinData={headerinData}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                data={data}
                status={status}
                setStatus={setStatus}
                cancelData={cancelData}
            />
        </>
    )
}

export default Financial_Outstanding