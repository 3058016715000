import {
     Box,
     Dialog,
     DialogActions,
     DialogContent,
     DialogContentText,
     DialogTitle,
     FormControl,
     Grid,
     InputLabel,
     MenuItem,
     Select,
     TextField,
} from "@mui/material";
import React, { useState } from "react";
import { CloseIcn } from "../../../untils/icons";
import { ButtonTeal } from "../../../Theme";

function DialogAdd({ openDialogAdd, handleCloseDialogAdd, newDataAdd, handleInputChangeAdd, handleSaveAdd }) {
     const [loading, setLoading] = useState(false);

     return (
          <>
               <Dialog open={openDialogAdd} onClose={handleCloseDialogAdd}>
                    <Box className="bg-gradiant">
                         <DialogActions className="flex-end">
                              <CloseIcn className="close-icn" onClick={handleCloseDialogAdd} />
                         </DialogActions>
                         <DialogTitle className="text-header">เพิ่มข้อมูล</DialogTitle>
                         <DialogContent className="flex-center">
                              <DialogContentText mt={2} className="container-bg" component={"span"}>
                                   <Grid container columns={12} spacing={2}>
                                        {/* <Grid item xs={12}>
                                    <TextField
                                        label="เลขบัญชี"
                                        name="ref_doc"
                                        fullWidth
                                        value={newDataAdd.ref_doc || ""}
                                        disabled={newDataAdd.ref_doc ? true : false}
                                        onChange={handleInputChangeAdd}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="เอกสารอ้างอิง"
                                        name="documentName"
                                        fullWidth
                                        value={newDataAdd.rep || ""}
                                        disabled={newDataAdd.rep ? true : false}
                                        onChange={handleInputChangeAdd}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="ชื่อบัญชี"
                                        name="account_name_credit"
                                        fullWidth
                                        value={newDataAdd.account_name_credit}
                                        disabled={newDataAdd.account_name_credit ? true : false}
                                        onChange={handleInputChangeAdd}
                                    />
                                </Grid> */}
                                        <Grid item xs={12}>
                                             <TextField
                                                  label="เดบิต"
                                                  name="debit"
                                                  fullWidth
                                                  value={newDataAdd.debit}
                                                  onChange={handleInputChangeAdd}
                                                  type="number"
                                             />
                                        </Grid>
                                        {/* {console.log("newDataAdd", newDataAdd)} */}
                                        <Grid item xs={12}>
                                             <TextField
                                                  disabled
                                                  label="เครดิต"
                                                  name="credit"
                                                  fullWidth
                                                  value={newDataAdd.credit}
                                                  onChange={handleInputChangeAdd}
                                                  InputProps={{
                                                       sx: { textAlign: "right" },
                                                  }}
                                                  inputProps={{ style: { textAlign: "right" } }}
                                                  type="number"
                                             />
                                        </Grid>
                                   </Grid>
                              </DialogContentText>
                         </DialogContent>
                         <DialogActions className="flex-center">
                              <ButtonTeal disabled={loading} sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }} onClick={handleSaveAdd}>
                                   {loading ? "กำลังบันทึก..." : "บันทึกข้อมูล"}
                              </ButtonTeal>
                         </DialogActions>
                    </Box>
               </Dialog>
          </>
     );
}

export default DialogAdd;
