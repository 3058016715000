import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { headerTable } from "../../../untils/static";
import FinalcialTable from "./FinancialTable";
import DialogEdit from "../../Financial_Account/components/DialogEdit";
import { BtnConfirmClaim } from "../../../Theme";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import AddCardIcon from "@mui/icons-material/AddCard";
import { useState } from "react";

dayjs.locale("th");

function FinancialList({
     status,
     setStatus,
     statuswait,
     setStatusWait,
     datestart,
     dateend,
     setDateStart,
     setDateEnd,
     onConfirm,
     onCancel,
     setSelectedCount,
     selectedRows,
     setSelectedRows,
     selectedCount,
     selectAll,
     setSelectAll,
     sortedData,
     page,
     setPage,
     search,
     setSearch,
}) {
     const handleSelectedRowsChange = (newSelectedRows) => {
          setSelectedRows(newSelectedRows);
     };

     const handleSelectAllChange = (newSelectAll) => {
          setSelectAll(newSelectAll);
     };

     const updateSelectedCount = (newSelectedRows) => {
          const selectedCount = Object.values(newSelectedRows).filter((isSelected) => isSelected).length;
          setSelectedCount(selectedCount);
          setSelectedRows(newSelectedRows);
     };

     const maxDateEnd = datestart.add(3, "month").isBefore(dayjs()) ? datestart.add(3, "month") : dayjs();

     const headersso = () => {
          if (JSON.stringify(statuswait) === JSON.stringify([1])) {
               return headerTable.FinancialStatementheader1 || [];
          } else if (JSON.stringify(statuswait) === JSON.stringify([2, 4])) {
               return headerTable.FinancialStatementheader2 || [];
          } else if (JSON.stringify(statuswait) === JSON.stringify([3])) {
               return headerTable.FinancialStatementheader3 || [];
          }
     };

     const headerinsso = (type) => {
          switch (type) {
               case "SSO":
                    return headerTable.FinancialStatementSSO;
               case "OP/PP":
                    return headerTable;
               case "LGO":
                    return headerTable.FinancialStatementLGO;
               case "ECLAIM":
                    return headerTable.FinancialStatementECLAIM;
               case "KTB":
                    return headerTable.FinancialStatementKTB;
               case "MOPH":
                    return headerTable.FinancialStatementMOPH;
               default:
                    return [];
          }
     };

     const handleChange = (e) => {
          const value = JSON.parse(e.target.value);
          setStatusWait(value);
     };

     return (
          <Box display="flex" justifyContent="center">
               <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                         <Grid item xs={12} mt={1} mb={3} className="flex-start">
                              <Stack direction="row" spacing={2}>
                                   <AddCardIcon sx={{ color: "var(--teal-A800)" }} fontSize="medium" />
                                   <Typography className="main-header">ตั้งลูกหนี้</Typography>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} sm={12} md={12} my={2}>
                              <Grid container columns={12} spacing={3} mb={2} className="flex-start">
                                   <Grid item xs={12} sm={12} md={2}>
                                        <FormControl fullWidth>
                                             <InputLabel>เลือกสิทธิ</InputLabel>
                                             <Select
                                                  name=""
                                                  value={status}
                                                  label="เลือกสิทธิ"
                                                  // displayEmpty
                                                  required
                                                  onChange={(e) => setStatus(e.target.value)}
                                             >
                                                  <MenuItem value="0">ทั้งหมด</MenuItem>
                                                  <MenuItem value="1">SSO</MenuItem>
                                                  <MenuItem value="2">OP/PP</MenuItem>
                                                  <MenuItem value="3">LGO</MenuItem>
                                                  <MenuItem value="4">ELCAIM</MenuItem>
                                                  <MenuItem value="5">KTB</MenuItem>
                                                  <MenuItem value="6">MOPH</MenuItem>
                                             </Select>
                                        </FormControl>
                                   </Grid>
                                   <Grid item xs={12} sm={12} md={2}>
                                        <Stack width="100%" direction="row" spacing={1}>
                                             <FormControl fullWidth>
                                                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                                                       <DatePicker
                                                            label="วันเริ่มต้น"
                                                            value={datestart}
                                                            onChange={(newValue) => {
                                                                 setDateStart(newValue);
                                                                 // const newDateEnd = newValue.add(3, 'month');
                                                                 const newDateEnd = newValue.add(1, "month");
                                                                 const endOfYear = dayjs().endOf("year");
                                                                 // setDateEnd(newDateEnd.isAfter(dayjs()) ? dayjs() : newDateEnd);
                                                                 setDateEnd(newDateEnd.isAfter(endOfYear) ? endOfYear : newDateEnd);
                                                            }}
                                                            format="DD MMM YYYY"
                                                            maxDate={dayjs()}
                                                       />
                                                  </LocalizationProvider>
                                             </FormControl>
                                        </Stack>
                                   </Grid>
                                   <Grid item xs={12} sm={12} md={2}>
                                        <Stack width="100%" direction="row" spacing={1}>
                                             <FormControl fullWidth>
                                                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                                                       <DatePicker
                                                            label="วันสิ้นสุด"
                                                            value={dateend}
                                                            onChange={(newValue) => setDateEnd(newValue)}
                                                            format="DD MMM YYYY"
                                                            // maxDate={maxDateEnd}
                                                            maxDate={dayjs().endOf("year")}
                                                            minDate={datestart}
                                                       />
                                                  </LocalizationProvider>
                                             </FormControl>
                                        </Stack>
                                   </Grid>
                                   <Grid item xs={12} sm={12} md={2}>
                                        <FormControl fullWidth>
                                             <InputLabel>เลือกสถานะ</InputLabel>
                                             <Select
                                                  name="status"
                                                  value={JSON.stringify(statuswait)}
                                                  label="เลือกสถานะ"
                                                  required
                                                  onChange={handleChange}
                                             >
                                                  <MenuItem value={JSON.stringify([1])}>รอตั้งลูกหนี้</MenuItem>
                                                  <MenuItem value={JSON.stringify([2, 4])}>ตั้งลูกหนี้สำเร็จ</MenuItem>
                                                  <MenuItem value={JSON.stringify([3])}>ยกเลิกการตั้งลูกหนี้</MenuItem>
                                             </Select>
                                        </FormControl>
                                   </Grid>
                              </Grid>
                              <Grid item xs={12} my={2}>
                                   <TextField
                                        fullWidth
                                        id="search"
                                        label="ค้นหารายการ"
                                        type="search"
                                        variant="outlined"
                                        size="medium"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={search}
                                   />
                              </Grid>
                              <Grid item xs={12} className="flex-end">
                                   <Typography sx={{ cursor: "pointer", mr: 2 }}>ข้อมูล {selectedCount} รายการ</Typography>
                                   <BtnConfirmClaim
                                        // onClick={onCancel}
                                        // disabled={selectedCount === 0}
                                        sx={{ mr: 2 }}
                                   >
                                        <CreditCardOffIcon fontSize="small" />
                                        &nbsp;
                                        <Typography>เพิ่ม</Typography>
                                   </BtnConfirmClaim>
                                   <BtnConfirmClaim
                                        onClick={onCancel}
                                        disabled={selectedCount === 0}
                                        sx={{
                                             opacity: selectedCount === 0 ? 0.5 : 1,
                                             cursor: selectedCount === 0 ? "not-allowed" : "pointer",
                                        }}
                                   >
                                        <CreditCardOffIcon fontSize="small" />
                                        &nbsp;
                                        <Typography>ยกเลิก</Typography>
                                   </BtnConfirmClaim>
                              </Grid>
                         </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mb={2}>
                         <FinalcialTable
                              headersso={headersso()}
                              headerinsso={headerinsso}
                              statuswait={statuswait}
                              onConfirm={onConfirm}
                              onCancel={onCancel}
                              updateSelectedCount={updateSelectedCount}
                              handleSelectedRowsChange={handleSelectedRowsChange}
                              selectedRows={selectedRows}
                              setSelectedRows={setSelectedRows}
                              selectAll={selectAll}
                              setSelectAll={handleSelectAllChange}
                              sortedData={sortedData}
                              page={page}
                              setPage={setPage}
                              search={search}
                         />
                    </Grid>
               </Paper>
          </Box>
     );
}

export default FinancialList;
