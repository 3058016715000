import {
    Box,
    Chip,
    Grid,
    Paper,
    Popover,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";

import { visuallyHidden } from "@mui/utils";
import { StyledTableCell } from "../../../Theme";
import { hospitalStatus, hospitalStatusPrev } from "../../../untils/static";

export default function GridTableList({ header, data, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, searchTerm }) {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("dt_update");
    const [hoverData, setHoverData] = useState("");

    const filteredData =
        data && data.length > 0
            ? data.filter(
                  (hosp) =>
                      (hosp.hcode && hosp.hcode.toLowerCase().includes(searchTerm.toLowerCase())) ||
                      (hosp.hname && hosp.hname.toLowerCase().includes(searchTerm.toLowerCase()))
              )
            : [];

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        // console.log('order',order);
        // console.log('orderBy',orderBy);

        return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        // console.log(array, comparator);
        const stabilizedThis = array.map((el, index) => [el, index]);
        // console.log('stabilizedThis', stabilizedThis);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const visibleRows = useMemo(
        () => stableSort(filteredData, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [order, orderBy, page, rowsPerPage, filteredData]
    );

    const handleRequestSort = (property) => (event) => {
        // console.log('event', event, property);
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event, e) => {
        setAnchorEl(event.currentTarget);
        setHoverData(
            e
                ? e
                      .slice(0, 3)
                      .map((el) => `ครั้งที่ ${el.sort} เวลา ${el.time}`)
                      .join("\n") + (e.length > 3 ? "\n..." : "")
                : "ดึงไม่สำเร็จ"
        );
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center" style={{ minWidth: 100 }} rowSpan={2}>
                                ชื่อสถานพยาบาล
                            </StyledTableCell>
                            {header.map((headCell) => (
                                <StyledTableCell
                                    key={headCell.id}
                                    align={headCell.align}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                    rowSpan={2}
                                >
                                    <TableSortLabel
                                        style={{ color: "#fff" }}
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={handleRequestSort(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === "desc" ? "sorted descending" : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                            <StyledTableCell align="center" style={{ minWidth: 100, top: 0, zIndex: 20 }} colSpan={7}>
                                สถานะย้อนหลัง (7 วัน)
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            {filteredData.length > 0 &&
                                filteredData[0].datas.map((datePrev) => {
                                    return <StyledTableCell style={{ textAlign: "center", zIndex: 20, top: 57 }}>{datePrev.date}</StyledTableCell>;
                                })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow>
                                    <StyledTableCell align="left">
                                        {row.hcode} {row.hname}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.datas[0].date}</StyledTableCell>
                                    {/* ล่าสุด */}
                                    <StyledTableCell align="center">
                                        <Chip
                                            sx={{
                                                width: "130px",
                                                background: `${hospitalStatus[row.datas[0].status].color}`,
                                                color: "var(--white)",
                                            }}
                                            label={`${hospitalStatus[row.datas[0].status].label} ${
                                                row.datas[0].pull_count ? row.datas[0].pull_count : " "
                                            }`}
                                        />
                                    </StyledTableCell>
                                    {/* ย้อนหลัง 7 วัน */}
                                    {row.datas.map((data) => (
                                        <StyledTableCell align="center">
                                            <React.Fragment>
                                                <Chip
                                                    sx={{
                                                        width: "70px",
                                                        background: `${hospitalStatusPrev[data?.status].color}`,
                                                        color: "var(--white)",
                                                        margin: 0.2,
                                                    }}
                                                    label={hospitalStatusPrev[data?.status].label}
                                                    aria-owns={open ? "mouse-over-popover" : undefined}
                                                    aria-haspopup="true"
                                                    onMouseEnter={(e) => handlePopoverOpen(e, data?.pull_data)}
                                                    onMouseLeave={handlePopoverClose}
                                                />
                                            </React.Fragment>
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: "none", whiteSpace: "break-spaces" }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>{hoverData}</Typography>
            </Popover>
            {data.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
