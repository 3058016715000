import dayjs from "dayjs";
import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutMain from "../layout/LayoutMain";
import { GET, LOGOUT } from "../service";

import Homepage from "./Homepage/Homepage";
import Approve from "./Login/Approve";
import Login from "./Login/Login";
import VerifyIdentity from "./Login/VerifyIdentity";

//รายการเวชภัณฑ์คงคลัง
import Stock from "./Stock/Stock";

//เบิกเวชภัณฑ์
import ReciveHospital from "./TransactionHospital/ReciveHospital";
import RequisitionHospital from "./TransactionHospital/RequisitionHospital";

import AddRequisition from "./TransactionLocation/AddRequisition";
import DetailRequisition from "./TransactionLocation/DetailRequisition";
import RequisitionLocation from "./TransactionLocation/RequisitionLocation";

//Approve
import DetailApprove from "./TransactionApprove/DetailApprove";
import TransactionApprove from "./TransactionApprove/TransactionApprove";

//เวชภัณฑ์
import AddDrug from "./Drug/AddDrug";
import Drug from "./Drug/Drug";
import EditDrug from "./Drug/EditDrug";

//ประวัติการเบิก-จ่ายเวชภัณฑ์
import DetailHistory from "./History/DetailHistory";
import History from "./History/History";

//โรงพยาบาลศูนย์
import Hospital from "./Hospital/Hospital";
import HospitalStatus from "./HospitalStatus/HospitalStatus";

//โรงพยาบาลส่วนตำบล
import Location from "./Location/Location";

//อุปกรณ์
import Device from "./Device/Device";

//บริษัทยา
import Vendor from "./Vendor/Vendor";

//จัดการผู้ใช้งาน
import AddUser from "./ManageUser/AddUser";
import EditUser from "./ManageUser/EditUser";
import ManageUser from "./ManageUser/ManageUser";

//จัดการสิทธิ์สำหรับผู้ใช้งาน
import AddRole from "./ManageRole/AddRole";
import EditRole from "./ManageRole/EditRole";
import ManageRole from "./ManageRole/ManageRole";

//Report
import Error401 from "./Error/401";
import DetailReportApprove from "./Report/ReportApprove/DetailReportApprove";
import ReportApprove from "./Report/ReportApprove/ReportApprove";
import ReportDetailDrug from "./Report/ReportDrug/ReportDetailDrug";
import ReportDrug from "./Report/ReportDrug/ReportDrug";
import ReportDetailLocation from "./Report/ReportLocation/ReportDetailLocation";
import ReportDetailOrder from "./Report/ReportLocation/ReportDetailOrder";
import ReportLocation from "./Report/ReportLocation/ReportLocation";

//Claim
import Claim16 from "./Claim/Claim16/Claim16";
import DetailClaim16 from "./Claim/Claim16/DetailClaim16";
import Claim43 from "./Claim/Claim43/Claim43";
import DetailClaim43 from "./Claim/Claim43/DetailClaim43";
import ClaimCode from "./Claim/ClaimCode/ClaimCode";
import ClaimToCloseRights from "./Claim/ClaimToCloseRights/ClaimToCloseRights";
import ContraceptivePill from "./Claim/ContraceptivePill/ContraceptivePill";
import DMHT from "./Claim/DMHT/DMHT";
import Herb from "./Claim/Herb/Herb";
import DetailClaimHistory from "./Claim/History/DetailClaimHistory";
import HistoryClaim from "./Claim/History/HistoryClaim";
import Kiosk from "./Claim/Kiosk/Kiosk";
import Pregnant from "./Claim/Pregnant/Pregnant";
import Sso from "./Claim/Sso/Sso";
import Vaccine from "./Claim/Vaccine/Vaccine";
import ManageClaim from "./ManageClaim/ManageClaim";
import ManageUrl from "./ManageUrl/ManageUrl";

//Dashboard
import Claim13 from "./Claim/Claim13/Claim13";
import DetailClaim13 from "./Claim/Claim13/DetailClaim13";
import DetailClaimOPPP from "./Claim/OPPP/DetailClaimOPPP";
import OPPP from "./Claim/OPPP/OPPP";
import VaccineKTB from "./Claim/VaccineKTB/VaccineKTB";
import ChartStatement from "./Dashboard/ChartStatement";
import Dashboard16 from "./Dashboard/Dashboard16";
import Dashboard43 from "./Dashboard/Dashboard43";
import DashboardLocation from "./Dashboard/DashboardLocation";
import DashboardMapEpidemic from "./Dashboard/DashboardMapEpidemic";
import DashboardMapEpidemic2 from "./Dashboard/DashboardMapEpidemic2";
import DashboardMapGIS from "./Dashboard/DashboardMapGIS";
import DashboardMapGIS2 from "./Dashboard/DashboardMapGIS2";
import MapPopulation from "./Dashboard/DashboardMapPopulation";
import MapPopulation2 from "./Dashboard/DashboardMapPopulation2";
import DashboardNotPassClaim from "./Dashboard/DashboardNotPassClaim";
import DashboardOPD from "./Dashboard/DashboardOPD";
import DashboardPAO from "./Dashboard/DashboardPAO";
import DashboardPayment from "./Dashboard/DashboardPayment";
import DashboardStatement from "./Dashboard/DashboardStatement";
import StatementDetails from "./Dashboard/DashboardStatement/StatementDetails";
import DashboardStatement2 from "./Dashboard/DashboardStatement2";
import DashboardStatementKrungthaiDigital from "./Dashboard/DashboardStatementKrungthaiDigital";
import DashboardStatementVaccine from "./Dashboard/DashboardStatementVaccine";
import K101 from "./Dashboard/K101";
import K103 from "./Dashboard/K103";
import K111Diabetes from "./Dashboard/K111Diabetes";
import K111Hypertension from "./Dashboard/K111Hypertension";
import Debtor from "./Debtor/Debtor";

//OTP
import Otp from "./Login/Otp";
import SettingsOtp from "./SettingsOTP/SettingsOtp";

//News
import DashboardHDA from "./Dashboard/DashboardHDA";
import DashboardHDC from "./Dashboard/DashboardHDC";
import ReportDeath from "./Dashboard/ReportDeath";
import ReportDiagnose from "./Dashboard/ReportDiagnose";
import AddNews from "./News/AddNews";
import News from "./News/News";

//Database

//Other
import DashboardMapChronic from "./Dashboard/DashboardMapChronic";
import DashboardMapChronic2 from "./Dashboard/DashboardMapChronic2";
import FinancialStatement from "./Financial_Statement/Financial_Statement";
import FinancialDeduction from "./Financial_Deduction/Financial_Deduction";
import FinancialOutstanding from "./Financial_Outstanding/Financial_Outstanding";
import FinancialAccount from "./Financial_Account/Financial_Account";
import FinancialSum from "./Financial_sum/FinancialSum";
import HRTable from "./HumanResources/HRTable";
import HumanResources from "./HumanResources/HumanResources";
import ManageType from "./ManageType/ManageType";
import MornitorInputData from "./MonitorInputData/MonitorInputData";
import INSCL from "./Person/INSCL";
import Person from "./Person/Person";
import Monitor2 from "./Monitor_2/Monitor2";
import MonitorHDC from "./MonitorHDC/MonitorHDC";
import DrugCatalog from "./Claim/DrugCatalog/DrugCatalog";
import K1122 from "./Dashboard/K1122";
import K1132 from "./Dashboard/K1132";

function RouterMain() {
    let time_out = localStorage.getItem("timeOut");
    let path = window.location.pathname;
    //  console.log("time_out", time_out, dayjs(new Date()).diff(time_out, "m"));
    // console.log("path", path);
    const logout = async () => {
        try {
            let res = await GET(LOGOUT);
            if (res.success) {
                localStorage.removeItem("menu");
                localStorage.removeItem("system");
                localStorage.removeItem("profile");
                localStorage.removeItem("screen");
                localStorage.removeItem("timeOut");
                window.location.replace("/Login");
            } else {
                console.log(res.result);
            }
        } catch (error) {}
    };
    if (time_out) {
        //if login always have time out
        if (dayjs(new Date()).diff(time_out, "h") >= 8) {
            logout();
        }
    } else {
        if (path !== "/" && path !== "/Login" && path !== "/Approve" && path !== "/Otp" && path !== "/VerifyIdentity") {
            logout();
        }
    }

    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Login" element={<Login />} />
            <Route path="Approve" element={<Approve />} />
            <Route path="VerifyIdentity/:uid" element={<VerifyIdentity />} />
            <Route path="/401" element={<Error401 />} />
            <Route path="Otp" element={<Otp />} />

            <Route path="/" element={<LayoutMain />}>
                <Route path="Homepage" element={<Homepage />} />
                <Route path="Stock" element={<Stock />} />
                <Route path="Drug" element={<Drug />} />
                <Route path="Drug/Add" element={<AddDrug />} />
                <Route path="Drug/Edit" element={<EditDrug />} />

                <Route path="RequisitionHospital" element={<RequisitionHospital />} />
                <Route path="RequisitionHospital/Order" element={<ReciveHospital />} />

                <Route path="ApproveOrder" element={<TransactionApprove />} />
                <Route path="ApproveOrder/Order" element={<DetailApprove />} />

                <Route path="RequisitionLocation" element={<RequisitionLocation />} />
                <Route path="RequisitionLocation/Detail" element={<DetailRequisition />} />
                <Route path="RequisitionLocation/Add" element={<AddRequisition />} />

                <Route path="User" element={<ManageUser />} />
                <Route path="User/Add" element={<AddUser />} />
                <Route path="User/Edit" element={<EditUser />} />

                <Route path="Role" element={<ManageRole />} />
                <Route path="Role/Add" element={<AddRole />} />
                <Route path="Role/Edit" element={<EditRole />} />

                <Route path="History" element={<History />} />
                <Route path="History/Detail/:order" element={<DetailHistory />} />

                <Route path="Hospital" element={<Hospital />} />
                <Route path="HospitalStatus" element={<HospitalStatus />} />

                <Route path="Location" element={<Location />} />
                <Route path="Device" element={<Device />} />
                <Route path="Vendor" element={<Vendor />} />

                <Route path="ReportDrug" element={<ReportDrug />} />
                <Route path="ReportDrug/Detail/:id" element={<ReportDetailDrug />} />

                <Route path="ReportLocation" element={<ReportLocation />} />
                <Route path="ReportLocation/Detail/:id" element={<ReportDetailLocation />} />
                <Route path="ReportLocation/Detail/:id/Order/:id" element={<ReportDetailOrder />} />

                <Route path="ReportApprove" element={<ReportApprove />} />
                <Route path="ReportApprove/Detail/:order" element={<DetailReportApprove />} />

                <Route path="/Claim/Claim16" element={<Claim16 />} />
                {/* <Route path="Claim16/Detail" element={<DetailClaim16 />} /> */}
                <Route path="/Claim/Claim16/Detail/:detail" element={<DetailClaim16 />} />
                <Route path="/Claim/Claim13" element={<Claim13 />} />
                <Route path="/Claim/Claim13/Detail/:detail" element={<DetailClaim13 />} />
                <Route path="/Claim/ClaimVaccineKTB" element={<VaccineKTB />} />
                <Route path="/Claim/Claim43" element={<Claim43 />} />
                <Route path="Claim43/Detail" element={<DetailClaim43 />} />
                <Route path="ClaimCode" element={<ClaimCode />} />
                <Route path="/Claim/ClaimVaccine" element={<Vaccine />} />
                <Route path="HistoryClaim" element={<HistoryClaim />} />
                <Route path="HistoryClaim/Detail" element={<DetailClaimHistory />} />
                <Route path="Herb" element={<Herb />} />
                <Route path="ContraceptivePill" element={<ContraceptivePill />} />
                <Route path="PregnantDrug" element={<Pregnant />} />
                <Route path="/Claim/ClaimDMHT" element={<DMHT />} />
                <Route path="/Claim/Sso" element={<Sso />} />
                <Route path="/Claim/ClaimClose" element={<ClaimToCloseRights />} />
                <Route path="/Claim/ClaimOPPP" element={<OPPP />} />
                <Route path="/Claim/ClaimOPPP/Detail/:detail" element={<DetailClaimOPPP />} />
                <Route path="/Claim/DrugCatalog" element={<DrugCatalog />} />

                <Route path="DashboardPAO" element={<DashboardPAO />} />
                <Route path="DashboardLocation" element={<DashboardLocation />} />
                <Route path="DashboardAll" element={<Dashboard16 />} />
                {/* <Route path="Dashboard2" element={<Dashboard16 />} /> */}
                <Route path="DashboardAll43" element={<Dashboard43 />} />
                <Route path="/Statements/Statement" element={<DashboardStatement />} />
                <Route path="/Statements/ChartStatement" element={<ChartStatement />} />
                <Route path="/Statements/Statement/Details/:detail" element={<StatementDetails />} />
                <Route path="DashboardOPD" element={<DashboardOPD />} />
                <Route path="/Statements/DashboardStatement" element={<DashboardPayment />} />
                {/* <Route path="DashboardPayment" element={<DashboardPayment />} /> */}
                <Route path="ReportDiagnose" element={<ReportDiagnose />} />
                <Route path="ReportDeath" element={<ReportDeath />} />
                <Route path="/Dashboard/DashboardHDA" element={<DashboardHDA />} />
                <Route path="/Dashboard/DashboardHDC" element={<DashboardHDC />} />
                <Route path="/Dashboard/K101" element={<K101 />} />
                <Route path="/Dashboard/K103" element={<K103 />} />
                <Route path="/Dashboard/K111Diabetes" element={<K111Diabetes />} />
                <Route path="/Dashboard/K111Hypertension" element={<K111Hypertension />} />
                <Route path="/Dashboard/K1122" element={<K1122 />} />
                <Route path="/Dashboard/K1132" element={<K1132 />} />
                <Route path="/UcHsub/DashboardStatement2" element={<DashboardStatement2 />} />
                <Route path="/UcHsub/DashboardNotPassClaim" element={<DashboardNotPassClaim />} />

                <Route path="/Map/MapVisithome" element={<DashboardMapGIS />} />
                <Route path="/Map/MapVisithomeSatellite" element={<DashboardMapGIS2 />} />

                <Route path="/Map/MapEpidemic" element={<DashboardMapEpidemic />} />
                <Route path="/Map/MapEpidemicSatellite" element={<DashboardMapEpidemic2 />} />

                <Route path="/Map/MapPopulation" element={<MapPopulation />} />
                <Route path="/Map/MapPopulationSatellite" element={<MapPopulation2 />} />

                <Route path="/Map/Chronic" element={<DashboardMapChronic />} />
                <Route path="/Map/ChronicSatellite" element={<DashboardMapChronic2 />} />

                <Route path="/Listvaccine/DashboardStatementVaccine" element={<DashboardStatementVaccine />} />
                <Route path="/Listvaccine/DashboardStatementDK" element={<DashboardStatementKrungthaiDigital />} />

                <Route path="News" element={<News />} />
                <Route path="AddNews" element={<AddNews />} />

                <Route path="ManageClaim" element={<ManageClaim />} />
                <Route path="ManageUrl" element={<ManageUrl />} />
                <Route path="ManageType" element={<ManageType />} />
                <Route path="SettingsOtp" element={<SettingsOtp />} />
                <Route path="Kiosk" element={<Kiosk />} />
                <Route path="Person" element={<Person />} />
                <Route path="/Person/INSCL" element={<INSCL />} />

                <Route path="/Debtor" element={<Debtor />} />

                <Route path="/Finance/FinancialStatement" element={<FinancialStatement />} />
                <Route path="/Finance/FinancialDeduction" element={<FinancialDeduction />} />
                <Route path="/Finance/FinancialOutstanding" element={<FinancialOutstanding />} />
                <Route path="/Finance/FinacialAccount" element={<FinancialAccount />} />
                <Route path="/Finance/FinancialSum" element={<FinancialSum />} />
                <Route path="/FinancialStatement" element={<FinancialStatement />} />
                <Route path="/FinancialSum" element={<FinancialSum />} />

                <Route path="/HR/HumanResources" element={<HumanResources />} />
                <Route path="/HR/HRTable" element={<HRTable />} />

                <Route path="/MonitorInputData" element={<MornitorInputData />} />
                <Route path="/Monitor2" element={<Monitor2 />} />
                <Route path="/MonitorHDC" element={<MonitorHDC />} />
            </Route>
            <Route path="*" element={<div>404 not found</div>} />
        </Routes>
    );
}

export default RouterMain;
