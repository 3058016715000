import AddCardIcon from "@mui/icons-material/AddCard";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ButtonTeal } from "../../Theme";
import { AddItemIcon } from "../../untils/icons";
import { headerTable } from "../../untils/static";
import FinancialAccountTbl from "../Financial_Account/components/FinancialTable";
import DialogEdit from "./components/DialogEdit";
import { GET, GETDATAFINANCIALACCOUNT, POST, UPDATEDATAFINANCIALACCOUNT } from "../../service";
import Swal from "sweetalert2";
import DialogAdd from "./components/DialogAdd";

function Financial_Account() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dataAccount, setDataAccount] = useState([]);
    const [claim, setClaim] = useState("");
    const [account_no_credit, setAccount_no_credit] = useState("");
    const [account_no_debit, setAccount_no_debit] = useState("");
    const [ref_doc_credit, setRef_doc_credit] = useState("");
    const [ref_doc_debit, setRef_doc_debit] = useState("");
    const [account_name_credit, setAccount_name_credit] = useState("");
    const [account_name_debit, setAccount_name_debit] = useState("");
    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleClickOpenEdit = (row) => {
        setOpenEdit(true);
        setData({
            claim: row.claim,
            account_no_credit: row.account_no_credit,
            account_no_debit: row.account_no_debit,
            account_name: row.account_name,
            account_number: row.account_number,
            ref_doc_credit: row.ref_doc_credit,
            ref_doc_debit: row.ref_doc_debit,
            account_name_credit: row.account_name_credit,
            account_name_debit: row.account_name_debit,
        });
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const getDataAccount = async (row) => {
        try {
            setLoading(true);
            let res = await GET(GETDATAFINANCIALACCOUNT);
            if (res.success) {
                setDataAccount(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleEdit = async () => {
        setLoading(true);
        try {
            const res = await POST(UPDATEDATAFINANCIALACCOUNT, data);
            if (res && res.success) {
                setLoading(false);
                setOpenEdit(false);
                Swal.fire({
                    icon: "success",
                    text: "แก้ไขข้อมูลสำเร็จ",
                    confirmButtonText: "ตกลง",
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: res?.data.message || "Unknown error occurred",
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: error.message || "Something went wrong.",
                confirmButtonText: "ตกลง",
            });
        } finally {
            handleCloseEdit();
            getDataAccount();
            setLoading(false);
        }
    };

    useEffect(() => {
        getDataAccount();
    }, []);

    return (
        <>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={2}>
                        <Grid item xs={12} mb={3} className="flex-start">
                            <AddCardIcon sx={{ color: "var(--teal-A800)" }} fontSize="medium" />
                            <Typography className="main-header">เลขบัญชี</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="flex-end">
                            <ButtonTeal onClick={handleOpenDialog}>
                                <AddItemIcon fontSize="small" />
                                &nbsp;
                                <Typography sx={{ display: { xs: "none", sm: "block" }, cursor: "pointer" }}>เพิ่มเลขบัญชี</Typography>
                            </ButtonTeal>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <FinancialAccountTbl
                                header={headerTable.FinancialAccount}
                                dataAccount={dataAccount || []}
                                handleClickOpenEdit={handleClickOpenEdit}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <DialogAdd open={dialogOpen} getDataAccount={getDataAccount} handleClose={handleCloseDialog} />
            <DialogEdit
                open={openEdit}
                data={data}
                setData={setData}
                handleClose={handleCloseEdit}
                handleEdit={handleEdit}
            />
        </>
    );
}

export default Financial_Account;
