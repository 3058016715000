import React, { useState } from "react";
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Grid,
    Checkbox,
    FormControlLabel,
    FormControl,
    FormGroup,
    Chip,
} from "@mui/material";
import moment from "moment";
import { StyledTableCell } from "../../../../Theme";
import { MoreIcon } from "../../../../untils/icons";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
export default function GridTable({
    header,
    data,
    handleChangeCheckbox,
    checkAll,
    statusSend,
    type,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
}) {
    const lengthData = data.length;
    
    console.log('type: ', type);
    const handleRoute = (path, detail) => {
        // console.log('dateStart', dateStart,dateEnd)
        return () => {
            // navigate(path, { state: { ...detail } });
            // console.log("detail", detail);
            window.open(
                `Claim16/${path}/${JSON.stringify({
                    HN: detail.HN,
                    SEQ: detail.SEQ,
                    DATEOPD: detail.DATEOPD,
                    HCODE: detail.HCODE,
                    TITLE: detail.TITLE,
                    NAMEPAT: detail.NAMEPAT,
                    INSCL: detail.INSCL,
                })}`,
                "_blank"
            );
        };
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">
                                {
                                    /*type < 99 &&*/ statusSend !== 2 && statusSend !== 3 ? (
                                        // {statusSend !== 2 && statusSend !== 3 ? (
                                        data.length > 0 ? (
                                            <FormControlLabel
                                                label="ทั้งหมด"
                                                control={
                                                    <Checkbox
                                                        checked={checkAll === -1 ? true : false}
                                                        name="ทั้งหมด"
                                                        onChange={(e) => handleChangeCheckbox(e)}
                                                    />
                                                }
                                            />
                                        ) : (
                                            <FormControlLabel
                                                label="ทั้งหมด"
                                                control={<Checkbox checked={false} name="ทั้งหมด" onChange={(e) => handleChangeCheckbox(e)} />}
                                            />
                                        )
                                    ) : null
                                }
                                {/* <FormControlLabel
                                             label="ทั้งหมด"
                                             control={
                                                  <Checkbox
                                                       checked={checkAll === -1 ? true : false}
                                                       name="ทั้งหมด"
                                                       onChange={(e) => handleChangeCheckbox(e)}
                                                  />
                                             }
                                        /> */}
                            </StyledTableCell>
                            {header.map((column, i) => (
                                <StyledTableCell key={i + 1} align={column.align} style={{ minWidth: column.minWidth }}>
                                    <Typography fontWeight="bold" fontSize="14px">
                                        {column.label}
                                    </Typography>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                            return (
                                <TableRow key={i + 1}>
                                    <StyledTableCell align="left">
                                        <FormControl component="fieldset" variant="standard">
                                            <FormGroup>
                                                {
                                                    /*type < 99 && */ statusSend !== 2 && statusSend !== 3 && (
                                                        // {statusSend !== 2 && statusSend !== 3 && (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={(e) => handleChangeCheckbox(e)}
                                                                    checked={row.check}
                                                                    value={row.index}
                                                                    name={row.HN}
                                                                />
                                                            }
                                                        />
                                                    )
                                                }
                                            </FormGroup>
                                        </FormControl>
                                        <MoreIcon sx={{ cursor: "pointer", mt: "10px" }} onClick={handleRoute(`Detail`, row)} />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{row.HN ? row.HN : "N/A"}</StyledTableCell>
                                    {/* <StyledTableCell align="left">{row.TITLE}</StyledTableCell> */}
                                    <StyledTableCell align="left">
                                        {row.TITLE} {row.NAMEPAT ? row.NAMEPAT.replace(`,${row.TITLE}`, "") : "N/A"}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{row.DETAIL}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.medicine && row.medicine.length > 0
                                            ? row.medicine.map((el, ind) => (
                                                  <Grid key={ind + 1} style={{ marginBottom: 3, marginTop: 3 }}>
                                                      <Chip
                                                          icon={<MedicalServicesIcon fontSize="small" />}
                                                          label={el.name}
                                                          variant="outlined"
                                                          color="primary"
                                                      />
                                                  </Grid>
                                              ))
                                            : null}
                                    </StyledTableCell>
                                    {/* <StyledTableCell align="center">
                                                  <Chip
                                                       label={row.PERMITNO ? "พบเลขยืนยันตัวตน" : "ไม่พบเลขยืนยันตัวตน"}
                                                       color={row.PERMITNO ? "success" : "error"}
                                                  />
                                             </StyledTableCell> */}
                                    <StyledTableCell align="left">
                                        {row.PERMITNO && row.NAMEPAT && row.pre_audit && row.pre_audit.length === 0 ? (
                                            <Chip label="ผ่าน" color="success" />
                                        ) : (
                                            <>
                                                {row.PERMITNO ? null : (
                                                    <Chip
                                                        sx={{ mb: 0.25 }}
                                                        label={row.PERMITNO ? "พบเลขยืนยันตัวตน" : "ไม่พบเลขยืนยันตัวตน"}
                                                        color={row.PERMITNO ? "success" : "error"}
                                                    />
                                                )}
                                                {row.pre_audit && row.pre_audit.length === 0
                                                    ? null
                                                    : row.pre_audit.map((el, index) => 
                                                        <Chip
                                                    key={index + 1}
                                                    label={el.name}
                                                    sx={{
                                                        backgroundColor: el.name === "ยาที่บันทึกเบิกไม่พบใน Drug Catalog ของหน่วยบริการ"
                                                          ? [98, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].includes(type)
                                                            ? "#FDDE0A" // สีเหลือง
                                                            : "#D32F2F" // สีแดง
                                                          : [99, 16].includes(type)
                                                            ? "#D32F2F" // สีแดง
                                                            : "#D32F2F", // เคสอื่นนอกนี้เป็นสีแดง
                                                        color: el.name === "ยาที่บันทึกเบิกไม่พบใน Drug Catalog ของหน่วยบริการ" &&
                                                          [98, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].includes(type)
                                                          ? "black" // สีตัวอักษรดำเฉพาะเมื่อ background เป็นเหลือง
                                                          : "white" // สีตัวอักษรขาวในกรณีอื่น
                                                      }}
                                                      
                                                />)}
                                                
                                            </>
                                        )}

                                        {/* {row.pre_audit && row.pre_audit.length === 0 ? (
                                                       <Chip label="Pass" color="success" />
                                                  ) : (
                                                       row.pre_audit.map((el, index) => <Chip key={index + 1} label={el.name} color="error" />)
                                                  )} */}
                                        {/* {row.PERMITNO ? (
                                                       row.pre_audit && row.pre_audit.length === 0 ? (
                                                            // <Chip label="Pass" color="success" />
                                                            <></>
                                                       ) : (
                                                            row.pre_audit.map((el, index) => <Chip key={index + 1} label={el.name} color="error" />)
                                                       )
                                                  ) : row.pre_audit && row.pre_audit.length === 0 ? null : (
                                                       row.pre_audit.map((el, index) => <Chip key={index + 1} label={el.name} color="error" />)
                                                  )} */}
                                    </StyledTableCell>
                                    {(statusSend === 3 || statusSend === 99) && (
                                        <StyledTableCell align="left">{row.problem && <Chip label={row.problem} color="error" />}</StyledTableCell>
                                    )}
                                    <StyledTableCell align="center">{row.DATEOPD ? moment(row.DATEOPD).format("DD/MM/YYYY") : "N/A"}</StyledTableCell>
                                    {statusSend !== 3 && (
                                        <StyledTableCell align="center">
                                            {row.status_claim === 1 ? (
                                                <Typography color="green">กำลังตรวจสอบ</Typography>
                                            ) : row.status_claim === 2 ? (
                                                <Typography color="green">ผ่านการตรวจสอบจาก สปสช.</Typography>
                                            ) : row.status_claim === 3 ? (
                                                <Typography color="red">ไม่ผ่านการตรวจสอบจาก สปสช.</Typography>
                                            ) : (
                                                <Typography color="green">รอส่ง</Typography>
                                            )}
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell align="right">{Number(moment().year()) - Number(moment(row.DOB).year())}</StyledTableCell>
                                    {/* <StyledTableCell align="center">{row.HCODE ? row.HCODE : "N/A"}</StyledTableCell> */}
                                    {/* <StyledTableCell align="150">{row.DIAG}</StyledTableCell> */}
                                    {/* <StyledTableCell align="left">{row.odx_name ? row.odx_name : "N/A"}</StyledTableCell> */}
                                    <StyledTableCell align="center">{row.SEQ ? row.SEQ : "N/A"} ({row.HOSPMAIN ? row.HOSPMAIN : "-"})</StyledTableCell>

                                    {/* <StyledTableCell align="left">{row.LABTEST ? row.LABTEST : "N/A"}</StyledTableCell> */}
                                    {/* <StyledTableCell align="center">{row.LABRESULT ? row.LABRESULT : "N/A"}</StyledTableCell> */}
                                    {/* <StyledTableCell align="right">{row.SERVPRICE ? tofieds(row.SERVPRICE) : "0.00"}</StyledTableCell> */}
                                    {/* <StyledTableCell align="left">{row.ins_name ? row.ins_name : "N/A"}</StyledTableCell> */}

                                    {/* <StyledTableCell align="center">
                                                  {row.PERMITNO ? <MoreIcon sx={{ cursor: "pointer" }} onClick={handleRoute(`Detail`, row)} /> : null}
                                             </StyledTableCell> */}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={lengthData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
